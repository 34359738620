import styles from './VNews.module.css'
import VTitle from '../../common/VTitle'
import { useDispatch } from 'react-redux';
import { memo } from 'react'

import arrow_right_small from "../../assets/img/icons/arrow_right_small.svg"
// Helmet
import { setNewThemeColor } from '../../../../features/helmet/helmetSlice';
// Modal
import useScrollBlock from '../../../../customHooks/useScrollBlock';
import VNewsModal from './VNewsModal';
import Modal_1 from '../../common/Modals/Modal_1';
// Truncate
import { truncateText } from '../../../../customHooks/truncateText';
// Media
import { useMedia } from "../../../../customHooks/useMedia"
const db =  [
  {
    date: '01 февраля 2024',
    img_url: 'pic_1.png',
    title: "Повышение цен", 
    content: "ТОО «КОМЕТА» вынуждена поднять цену на продукцию. По причине увеличения стоимости сырья которое необходимо для выпуска бутилированной воды (крышки, этикетки, пэт преформы и прочее). Это связано с тем, что за 2 квартал 2021 года на мировом рынке произошел значительный рост полиэтилена (примерно на 30-35%). Надеемся на понимание и дальнейшее сотрудничество!"
  },
  {
    date: '01 января 2024',
    img_url: 'pic_1.png',
    title: 'Скоро акция',
    content: 'ТОО «КОМЕТА» вынуждена поднять цену на продукцию. По причине увеличения стоимости сырья которое необходимо для выпуска бутилированной воды (крышки, этикетки, пэт преформы и прочее). Это связано с тем, что за 2 квартал 2021 года на мировом рынке произошел значительный рост полиэтилена (примерно на 30-35%). Надеемся на понимание и дальнейшее сотрудничество!',
  },
  {
    date: '01 декабря 2023',
    img_url: 'pic_1.png',
    title: 'Новый дизайн',
    content: 'ТОО «КОМЕТА» вынуждена поднять цену на продукцию. По причине увеличения стоимости сырья которое необходимо для выпуска бутилированной воды (крышки, этикетки, пэт преформы и прочее). Это связано с тем, что за 2 квартал 2021 года на мировом рынке произошел значительный рост полиэтилена (примерно на 30-35%). Надеемся на понимание и дальнейшее сотрудничество!',
  },
  {
    date: '01 декабря 2023',
    img_url: 'pic_1.png',
    title: 'Новый дизайн',
    content: 'ТОО «КОМЕТА» вынуждена поднять цену на продукцию. По причине увеличения стоимости сырья которое необходимо для выпуска бутилированной воды (крышки, этикетки, пэт преформы и прочее). Это связано с тем, что за 2 квартал 2021 года на мировом рынке произошел значительный рост полиэтилена (примерно на 30-35%). Надеемся на понимание и дальнейшее сотрудничество!',
  }
]

const Box = ({ data }) => {
  const isLarge550 = useMedia('(min-width: 550px)');
  const isLarge450 = useMedia('(min-width: 450px)');

  const [blockScroll, allowScroll] = useScrollBlock();
  
  const dispatch = useDispatch();
  const changeThemeColor = (color) => dispatch(setNewThemeColor(color))

  const openNews = () => {
    // setTimeout(function(){}, 150);
    changeThemeColor({color: '#5c5d5e'})
    blockScroll()
    Modal_1.open(VNewsModal, {data});
  }

  return(
          <div className={styles.main_container}>
              <div className={"card__wrapper " + styles.card__wrapper}>
                  <div onClick={openNews} className={"card__content " + styles.card__content}>
                     

                                <div className={styles.header_date_btn_block}>
                                    <p className={styles.date}>{data.date}</p>
                                    <div className={styles.btn_more_container}>
                                        <button className={styles.btn_more}>Подробнее</button>
                                        <img src={arrow_right_small} alt='arrow right' />
                                    </div>
                                </div>
                                <div className={styles.content_container}>
                                    <h3 className={styles.title}>{data.title}</h3>
                                    <div>
                                        <img className={styles.news_icon} src={'assets/img/by_page/news/' + data.img_url} alt='news pic' />
                                        <p className={styles.content}>{isLarge550 ? truncateText(data.content, 115) : isLarge450 ? truncateText(data.content, 100) : truncateText(data.content, 65)}</p>
                                    </div>  
                                </div>

                  </div>
              </div>
          </div>
  )
}

const VNews = () => {
  return (
    <>
      <VTitle title="Новости" subtitle='Будьте в курсе самых последних обновлений Calipso' />
      <div className={styles.wrapper}>
          {db?.map( (item, k) => {
              return <Box key={k} data={item}  />
          })
          }       
      </div>
    </>
  )
}

export default memo(VNews)