import styles from './ALeftMenu.module.css'
import { NavLink } from 'react-router-dom'
// Images
import icon_label_new from "./../../visitor/assets/img/icons/label_new.svg"
import icon_cog_development from "./../../visitor/assets/img/icons/cog_development.svg"

const ALeftMenu = () => {
  return (
    <div className={styles.menu_block}>
        <ul className={styles.menu_list}>
            <li>
                <NavLink 
                    className={({ isActive }) => styles.menu_item + (isActive ? " " + styles.active : "")} 
                    to="/admin/dashboard"
                >Dashboard</NavLink>
            </li>
            <li>
                <NavLink 
                    className={({ isActive }) => styles.menu_item + (isActive ? " " + styles.active : "")} 
                    to="/admin/notifications"
                    onClick={(e)=>e.preventDefault()}
                >Уведомления
                <img className={styles.icon} src={icon_cog_development} alt='under development' />
                </NavLink>
            </li>
            <li>
                <NavLink 
                    className={({ isActive }) => styles.menu_item + (isActive ? " " + styles.active : "")} 
                    to="/admin/users"
                    onClick={(e)=>e.preventDefault()}
                >Пользователи
                <img className={styles.icon} src={icon_cog_development} alt='under development' />
                </NavLink>
            </li>
            <li>
                <NavLink 
                    className={({ isActive }) => styles.menu_item + (isActive ? " " + styles.active : "")} 
                    to="/admin/catalog"
                    onClick={(e)=>e.preventDefault()}
                >Каталог
                <img className={styles.icon} src={icon_cog_development} alt='under development' />
                </NavLink>
            </li>
            <li>
                <NavLink 
                    className={({ isActive }) => styles.menu_item + (isActive ? " " + styles.active : "")} 
                    to="/admin/vacancies"
                    onClick={(e)=>e.preventDefault()}
                >Вакансии
                <img className={styles.icon} src={icon_cog_development} alt='under development' />
                </NavLink>
            </li>
            <li>
                <NavLink 
                    className={({ isActive }) => styles.menu_item + (isActive ? " " + styles.active : "")} 
                    to="/admin/news"
                    onClick={(e)=>e.preventDefault()}
                >Новости
                <img className={styles.icon} src={icon_cog_development} alt='under development' />
                </NavLink>
            </li>
            <li>
                <NavLink 
                    className={({ isActive }) => styles.menu_item + (isActive ? " " + styles.active : "")} 
                    to="/admin/contacts"
                    onClick={(e)=>e.preventDefault()}
                >Контакты
                <img className={styles.icon} src={icon_cog_development} alt='under development' />
                </NavLink>
            </li>
            <li>
                <NavLink 
                    className={({ isActive }) => styles.menu_item + (isActive ? " " + styles.active : "")} 
                    to="/admin/email"
                    onClick={(e)=>e.preventDefault()}
                >Рассылки
                <img className={styles.icon} src={icon_cog_development} alt='under development' />
                </NavLink>
            </li>
            <li>
                <NavLink 
                    className={({ isActive }) => styles.menu_item + (isActive ? " " + styles.active : "")} 
                    to="/admin/report"
                >Отчеты
                <img className={styles.icon} src={icon_label_new} alt='under development' />
                </NavLink>
            </li>
            <li>
                <NavLink 
                    className={({ isActive }) => styles.menu_item + (isActive ? " " + styles.active : "")} 
                    to="/admin/certificates"
                    onClick={(e)=>e.preventDefault()}
                >Сертификаты
                <img className={styles.icon} src={icon_cog_development} alt='under development' />
                </NavLink>
            </li>
            <li>
                <NavLink 
                    className={({ isActive }) => styles.menu_item + (isActive ? " " + styles.active : "")} 
                    to="/admin/files"
                    onClick={(e)=>e.preventDefault()}
                >Файлы
                <img className={styles.icon} src={icon_cog_development} alt='under development' />
                </NavLink>
            </li>
        </ul>
    </div>
  )
}

export default ALeftMenu