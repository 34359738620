import styles from './VMainVacancies.module.css'

import { Link } from "react-router-dom"
import arrowRight from "../../../assets/img/icons/arrow_right.svg"

const VMainVacancies = () => {
  return (
    <>
        <section className={styles.vacancies}>
            <div className={styles.wrapper}>

                <div className={"card__wrapper " + styles.card__wrapper}>
                    <div className={"card__content " + styles.card}>
                        <p className={styles.card__txt_main}>Мы в поиске новых сотрудников</p>   
                        <Link 
                            className={"visitor__title__btn_see_more " + styles.btn_see_more} 
                            to='vacancies'>
                                <span>Смотреть вакансии</span>
                                <img src={arrowRight} alt="arrow right" />
                        </Link>
                        <p className={styles.card__txt_additional}>Присоединяйтесь к нам</p>
                    </div>
                </div>
                             
            </div>
        </section>
    </>
  )
}

export default VMainVacancies