import styles from './VVacancies.module.css'
import VTitle from '../../common/VTitle'
import { useDispatch } from 'react-redux';

// Modal
import Modal_1 from '../../common/Modals/Modal_1';
import VVacanciesModalDetails from './VVacanciesModalDetails';
import VVacanciesModalApply from './VVacanciesModalApply';
import useScrollBlock from '../../../../customHooks/useScrollBlock';
// Helmet
import { setNewThemeColor } from '../../../../features/helmet/helmetSlice';
// Redux
import { useGetVacanciesQuery } from './ApiSliceVacancies';

const Box = ({ data }) => {
  const [blockScroll, allowScroll] = useScrollBlock();

  const dispatch = useDispatch();
  const changeThemeColor = (color) => dispatch(setNewThemeColor(color))

  const openDetails = () => {
    // setTimeout(function(){}, 150);
    changeThemeColor({color: '#5c5d5e'})
    blockScroll()
    Modal_1.open(VVacanciesModalDetails, {data});
  }
  const openApply = () => {
    // setTimeout(function(){}, 150);
    changeThemeColor({color: '#5c5d5e'})
    blockScroll()
    Modal_1.open(VVacanciesModalApply, {data});
  }
  
  return(
            <div className={styles.main_container}>

                <div className={"card__wrapper " + styles.card__wrapper}>
                    <div className={"card__content " + styles.card}>

                        <h3 className={styles.title}>{data.title}</h3>
                        <p className={styles.category}>{data.category}</p>

                        <p className={styles.field}>зарплата: <span>{data.salary}</span></p>
                        <p className={styles.field}>опыт работы: <span>{data.expirience}</span></p>
                        <p className={styles.field}>график: <span>{data.schedule}</span></p>
                        
                        <p className={styles.field}>занятость: <span>{data.types}</span></p>


                        <div className={styles.btn_block}>
                            <button className={styles.btn_blue} onClick={openDetails}>Подробнее</button>
                            <button className={styles.btn_blue} onClick={openApply}>Откликнуться</button>
                        </div>

                    </div>
                </div>

            </div>
  )
}

const VVacancies = () => {
  const { data: db, isLoading, isSuccess, isError, error } = useGetVacanciesQuery()

  let content
  if(isLoading){
    content = "Loading"
  }
  if(isSuccess){
    content = <>
                <VTitle title="Вакансии" subtitle='Будем рады видеть именно Вас в нашей команде' />
                    {db.length
                      ? (
                          <div className={styles.wrapper}>
                            {db.map( (item, k) => <Box key={k} data={item} /> )}
                          </div>
                        )
                      : <div className={styles.no_vacancies_wrapper}>
                          <div className={`card__wrapper ${styles.card__wrapper} ${styles.no_vacancies_card}`}>
                            <div className={"card__content " + styles.card}>
                              <p className={styles.no_vacancies}>В данный момент нет актуальных вакансий</p>
                            </div>
                          </div>
                        </div>
                    }
                
              </>
  }
    
  return content
}

export default VVacancies
