import VMainTitle from "./VMainTitle"
import styles from "./VMain.module.css"

// blocks
import VMainAboutCompany from "./AboutCompany/VMainAboutCompany"
import VMainBenefits from "./Benefits/VMainBenefits"
import VMainCatalog from "./Catalog/VMainCatalog"
import VMainPromotion from "./Promotion/VMainPromotion"
import VMainPartners from "./Partners/VMainPartners"
import VMainCertificates from "./Certificates/VMainCertificates"
import VMainNews from "./News/VMainNews"
import VMainVacancies from "./Vacancies/VMainVacancies"

const VMain = () => {
  return (
    <>
      {/*  */}
      <section className={styles.main__banner}>
         {/* banner content */}
        <div className={"card__wrapper " + styles.banner__content}>
          <div className="card__content">
            <p>Вода натурального происхождения из артезианской скважины, глубиной 500 м</p>
          </div>
        </div>

      </section>

      {/* О компании */}
      <VMainAboutCompany />

      {/* Наши преимущества */}
      <VMainBenefits />

      {/* Ассортимент */}
      <VMainCatalog />

      {/* Акции */}
      {/* <VMainPromotion /> */}

      {/* Компании которые нам доверяют */}
      <VMainPartners />

      {/* Компании которые нам доверяют */}
      <VMainCertificates />

      {/* Новости */}
      <VMainNews />

      {/* Вакансии */}
      <VMainVacancies />
    </>
  )
}

export default VMain