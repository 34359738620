import styles from './PageNotFound404.module.css'
import buttons from './Buttons.module.css'
import { Link } from 'react-router-dom'
// Icons
import image404 from '../assets/img/by_page/404/404.svg'

const PageNotFound404 = () => {
  return (
    <section className={'card__wrapper ' + styles.card__wrapper}>
        <div className={'card__content ' + styles.card__content}>
            <img src={image404} alt='page not found'/>
            <h2 className={styles.text_main}>Страница не найдена</h2>
            <p className={styles.text_sub}>Пожалуйста, проверьте правильность введенного Вами адреса или вернитесь на главную страницу.</p>
            <div className={styles.btn_container}>
                <Link
                    className={buttons.btn_solid_blue}
                    to='/'
                >
                    Главная
                </Link>
            </div>
        </div>
    </section>
  )
}

export default PageNotFound404