import styles from './PartDeliveryDate.module.css'
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
// Radix UI
import * as RadioGroup from '@radix-ui/react-radio-group';
// Icons
import {ReactComponent as IconPlus} from '../../../../../assets/img/icons/plus.svg'
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setDeliveryDate } from '../../../../../../../features/cart/cartSlice';
// Date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from 'date-fns/locale/ru';


const DateBlock = ({arrayDeliveryData}) => {
    const arrayExcludedDates = arrayDeliveryData?.excludeDates.map(dateString => new Date(dateString));
    const arrayExcludedDaysOfWeek = Object.entries(arrayDeliveryData.excludedDaysOfWeek)
                                        .filter(([day, details]) => !details.delivery_active)
                                        .map( (array) => parseInt(array[0]))

    // redux
    const dispatch = useDispatch()
    const saveDeliveryDate = useCallback((date) => dispatch(setDeliveryDate(date)), [dispatch])

    // DATE
    const [isDeliveryPossibleToday, setIsDeliveryPossibleToday] = useState(true)
    const [isDeliveryPossibleTomorrow, setIsDeliveryPossibleTomorrow] = useState(true)

    const [deliveryDay, setDeliveryDay] = useState('')
    const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];


    // Get the current time
    const currentTime = new Date();
    // Get the hours and minutes from the current time
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    // Set the target time to 08:00 AM
    const targetHours = 8;
    const targetMinutes = 0;
    // Check if current time is before 08:00 AM


    /////////////////////////////////////////////////////////////
    /////////////////////////// TODAY ///////////////////////////
    /////////////////////////////////////////////////////////////

    const todayDate = useMemo(() => {
        const date = new Date();
        date.getDate()
        date.setHours(0, 0, 0, 0);
        return date;
    }, []);
    const todayDay = todayDate.getDate();
    const todayMonth = months[todayDate.getMonth()];

    // possible to deliver today
    useEffect( () => {                                                         
        const isPossibleToDeliverToday =    !arrayExcludedDaysOfWeek.includes(currentTime.getDay()) &&
                                            !arrayExcludedDates.some(excludedDay => excludedDay.getTime() === currentTime.getTime()) &&
                                            (currentHours < targetHours || (currentHours === targetHours && currentMinutes < targetMinutes)) 
        setIsDeliveryPossibleToday(isPossibleToDeliverToday)
        if(isPossibleToDeliverToday){
            setDeliveryDay('today')
            saveDeliveryDate(todayDate.getTime())
        } 
    }, [todayDate])
    
    // component
    const BlockToday = () => (
        <div className={`card__content ${styles.card__content}`}>
            <div className={styles.radioGroupBlock}>
                <RadioGroup.Item disabled={!isDeliveryPossibleToday} className={styles.radioGroupItem} value="today" id="r1">
                    <RadioGroup.Indicator className={styles.radioGroupIndicator} />
                </RadioGroup.Item>
                <label className={styles.label} htmlFor="r1">
                    <span className={styles.date}>{todayDay} {todayMonth}</span>
                </label>
            </div>
            <p className={`${styles.deliveryPayment} ${styles.pl30}`}>сегодня</p>
            <p className={`${styles.deliveryPayment} ${styles.pl30}`}>Бесплатно</p>
        </div>
    )

    /////////////////////////////////////////////////////////////
    ////////////////////////// TOMORROW /////////////////////////
    /////////////////////////////////////////////////////////////

    const tomorrowDate = useMemo(() => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        date.setHours(0, 0, 0, 0);
        return date;
    }, []);
    const tomorrowDay = tomorrowDate.getDate();
    const tomorrowMonth = months[tomorrowDate.getMonth()];

    // possible to deliver tomorrow
    useEffect( () => {
        const isPossibleToDeliverTomorrow = 
                                            !arrayExcludedDaysOfWeek.includes(tomorrowDate.getDay()) &&
                                            !arrayExcludedDates.some(excludedDay => excludedDay.getTime() === tomorrowDate.getTime())
        setIsDeliveryPossibleTomorrow(isPossibleToDeliverTomorrow)
       
        if(isPossibleToDeliverTomorrow && !isDeliveryPossibleToday){
            setDeliveryDay('tomorrow')
            saveDeliveryDate(tomorrowDate.getTime())
        }
    }, [isDeliveryPossibleToday, tomorrowDate])

    // component
    const BlockTomorrow = () => (
        <div className={`card__content ${styles.card__content}`}>
            <div className={styles.radioGroupBlock}>
                <RadioGroup.Item disabled={!isDeliveryPossibleTomorrow} className={styles.radioGroupItem} value="tomorrow" id="r2">
                    <RadioGroup.Indicator className={styles.radioGroupIndicator} />
                </RadioGroup.Item>
                <label className={styles.label} htmlFor="r2">
                    <span className={styles.date}>{tomorrowDay} {tomorrowMonth}</span>
                </label>
            </div>
            <p className={`${styles.deliveryPayment} ${styles.pl30}`}>завтра</p>
            <p className={`${styles.deliveryPayment} ${styles.pl30}`}>Бесплатно</p>
        </div>
    )
    
    /////////////////////////////////////////////////////////////
    //////////////////////// CUSTOM DATE ////////////////////////
    /////////////////////////////////////////////////////////////
    const getNextValidDate = () => {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 2); // Start from the day after tomorrow
        currentDate.setHours(0, 0, 0, 0);

        // Check if the current date is Sunday or in the array of excluded days
        while (currentDate.getDay() === 0 || arrayExcludedDates.some(excludedDay => excludedDay.getTime() === currentDate.getTime())) {
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        return currentDate;
    };
    const [selectedDateOnDatePicker, setSelectedDateOnDatePicker] = useState(getNextValidDate);

    const filterOnlyDeliveryDates = (date) => {
        return  date > tomorrowDate && 
                !arrayExcludedDaysOfWeek.includes(date.getDay())
    };

    const CustomDateInput = forwardRef(({ value, onClick }, ref) => {
        const customDay = selectedDateOnDatePicker.getDate();
        const customMonth = months[selectedDateOnDatePicker.getMonth()];
        
        return <button type='button' className={styles.customDateBtn} onClick={onClick} ref={ref}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 48 48"
                        >
                            <mask id="ipSApplication0">
                                <g fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="4">
                                <path strokeLinecap="round" d="M40.04 22v20h-32V22"></path>
                                <path
                                    fill="#fff"
                                    d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                                ></path>
                                </g>
                            </mask>
                            <path
                                // fill="currentColor"
                                fill="#959595"
                                d="M0 0h48v48H0z"
                                mask="url(#ipSApplication0)"
                            ></path>
                        </svg>
                        {customDay} {customMonth}
                    </button>
    });

    // NEXT POSSIBLE DATE
    useEffect( () => {
        if(!isDeliveryPossibleToday && !isDeliveryPossibleTomorrow){
            setDeliveryDay('custom-date')
            saveDeliveryDate(selectedDateOnDatePicker.getTime())   
        }
    },[isDeliveryPossibleToday, isDeliveryPossibleTomorrow])
    
    // component
    const BlockCustomDate = () => (
        <div className={`card__content ${styles.card__content}`}>
            <div className={styles.radioGroupBlock}>
                <RadioGroup.Item className={styles.radioGroupItem} value="custom-date" id="r3">
                    <RadioGroup.Indicator className={styles.radioGroupIndicator} />
                </RadioGroup.Item>
                <label className={styles.label} htmlFor="r3">
                    <span className={styles.date}>Выбрать дату</span>
                </label>
            </div>
            <DatePicker
                        portalId="root-portal"
                        wrapperClassName={styles.datePickerWrapper}
                        selected={selectedDateOnDatePicker}
                        customInput={<CustomDateInput />}
                        onChange={(date) => {
                                            date.setHours(0, 0, 0, 0);
                                            saveDeliveryDate(date.getTime())
                                            setSelectedDateOnDatePicker(date)
                                            // change Radio button to Custom date
                                            setDeliveryDay('custom-date')
                                        }}
                        filterDate={filterOnlyDeliveryDates}
                        excludeDates={arrayExcludedDates}
                        calendarStartDay={1}
                        locale={ru}
                        withPortal
            />
            <p className={`${styles.deliveryPayment} ${styles.pl30}`}>Бесплатно</p>                    
        </div>
    )

    /////////////////////////////////////////////////////////////
    //////////////////////// RADIO GROUP ////////////////////////
    /////////////////////////////////////////////////////////////
    const handleDateChange = (value) => {
        setDeliveryDay(value)

        if(value==='today'){
            saveDeliveryDate(todayDate.getTime())
        }else if(value==='tomorrow'){
            saveDeliveryDate(tomorrowDate.getTime())
        } else{
            saveDeliveryDate(selectedDateOnDatePicker.getTime())
        }
    }

    /////////////////////////////////////////////////////////////
    ////////////////////////// RETURN ///////////////////////////
    /////////////////////////////////////////////////////////////
    return (
            <RadioGroup.Root 
                    className={styles.RadioGroupRoot} 
                    defaultValue="today" 
                    aria-label="select item"
                    onValueChange={handleDateChange}
                    value={deliveryDay}
                    name='delivery_day'
            >
                    
                    {/* TODAY */}
                    <BlockToday />
                    {/* TOMORROW */}
                    <BlockTomorrow />
                    {/* CUSTOM DATE */}
                    <BlockCustomDate />
            </RadioGroup.Root>           
                
    )
}

export default DateBlock