import styles from './VModal_2Common.module.css'
import { v4 as uuidv4 } from 'uuid';

// Icons
import { ReactComponent as IconBin } from "../../../../assets/img/icons/bin.svg"
// Form
import { useForm } from "react-hook-form"

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from '../../../../../../customHooks/useScrollBlock';
import PopupAppearLayout from '../../Layouts/PopupAppearLayout';
// Helmet
import { setDefaultThemeColor } from '../../../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';
// Redux
import { addEditComment } from '../../../../../../features/cart/cartSlice';
import { deleteComment } from '../../../../../../features/cart/cartSlice';

const VModal_2CommentAddEdit = ({editComment}) => {
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();
    
    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())

    const closeModal = () => {
        // setTimeout(function(){allowScroll()}, 150);
        defaultThemeColor()
        allowScroll()
        modal.close()
    }   

    const { register,
            formState: { errors },
            handleSubmit, 
    } = useForm()
    const onSubmit = (data) => {
        const output = {
            ...data,
            uuid: editComment && editComment.uuid ? editComment.uuid : uuidv4(),
        }
        dispatch(addEditComment(output))
        closeModal()
    }

    const deleteSelectedAddress = (data) => {
        dispatch(deleteComment(data))
        closeModal()
    }

    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
                <div className={styles.header_container}>
                    <div className={styles.header_title}>
                        <p>{editComment ? "Редактирование" : "Новый комментарий"}</p>
                    </div>
                    
                    <button
                        className={styles.popup_layout__close}
                        type="button"
                        onClick={closeModal}
                        >
                        &#9587;
                    </button>
                </div>

                                
                {/* SELECT FORM */}
                {
                    editComment
                        ?   <div onClick={ () => deleteSelectedAddress(editComment)} className={styles.deleteBlock}>
                                <IconBin />
                                <p>Удалить комментарий</p>
                            </div>
                        :   null
                }
                
                
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    

                    <label className={styles.addCityLabel + (errors.text ? " " + styles.error : "")} >Комментарий*:</label>
                    <textarea 
                        defaultValue={editComment && editComment.text.trim() !== "" ? editComment.text : ''} 
                        className={styles.addCommentText + (errors.text ? " " + styles.error : "")} 
                        {...register("text", { required: true })} 
                    />

                    {/* BUTTONS */}
                    <div className={styles.btn_block}>
                        <button onClick={closeModal} className={styles.item_btn_blue_border} >
                            Отмена
                        </button>
                        <button type='submit' className={styles.item_btn_blue_filled} >
                            Сохранить
                        </button>
                    </div>
                    
                </form>
                    
                
                
                    

                
        </PopupAppearLayout>
    )
}

export default VModal_2CommentAddEdit