import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE =   {
                            appVersion: 1.00,
                            whatsapp: {
                                status: "",
                                number: ""
                            }
                        }

const detailsSlice = createSlice({
    name: "details",
    initialState: INITIAL_STATE,
    reducers:   {
                    setWhatsAppIfNew: (state, action) => {
                        const whatsapp = {...action.payload};
                        if(whatsapp.status === 'new'){
                            state.whatsapp.status = whatsapp.status
                            state.whatsapp.number = whatsapp.number
                        }
                    },
                    changeStatusWhatsAppToExisting: (state, action) => {
                        state.whatsapp.status = 'existing'
                    },
                    setAppVersion: (state, action) => {
                        if(state.appVersion !== action.payload){
                            state.appVersion = action.payload
                        }
                    }
                },
});

export const { 
                setWhatsAppIfNew, changeStatusWhatsAppToExisting,
                setAppVersion
             } = detailsSlice.actions;

export default detailsSlice.reducer;