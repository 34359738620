import styles from './VVacanciesModalDetails.module.css'
import { useDispatch } from "react-redux";
// icons
import icon_close from '../../assets/img/icons/close.svg'
// Modal
import PopupAppearLayout from "../../common/Modals/Layouts/PopupAppearLayout";
import Modal_1 from '../../common/Modals/Modal_1';
import { useModalWindow } from "react-modal-global";
import useScrollBlock from "../../../../customHooks/useScrollBlock";
import VVacanciesModalApply from './VVacanciesModalApply';
import { setDefaultThemeColor } from "../../../../features/helmet/helmetSlice";
// Helmet
import { setNewThemeColor } from '../../../../features/helmet/helmetSlice';

const VVacanciesModalDetails = ({data}) => {

    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();
    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())

    const closeModal = () => {
      // setTimeout(function(){allowScroll()}, 150);
      defaultThemeColor()
      allowScroll()
      modal.close()
    }   
    
    const changeThemeColor = (color) => dispatch(setNewThemeColor(color))

    const openApply = () => {
        // setTimeout(function(){}, 150);
        changeThemeColor({color: '#5c5d5e'})
        modal.close()
        Modal_1.open(VVacanciesModalApply, {data});
    }
    
    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
            <div className={styles.header_wrapper}>
                <div className={styles.header_container}>
                    <p className={styles.header_title}>{data.title}</p>
                    
                    <button
                        className={styles.popup_layout__close}
                        type="button"
                        onClick={closeModal}
                        >
                        <img src={icon_close} alt="close button" />
                        </button>
                </div>
                <p>{data.category}</p>  
            </div>

            <div className={styles.catalog__container}>
                {/* <p>Для участия в нашей увлекательной "Водной лотерее" клиентам предоставляется шанс выиграть бесплатную доставку воды на целый месяц или другие замечательные призы! Каждая покупка продукции участвует в розыгрыше, при этом чем больше заказов вы размещаете, тем больше шансов выиграть у вас есть.</p>                 */}
            
                <div className={styles.main_details}>
                    <p className={styles.field}>зарплата: <span>{data.salary}</span></p>
                    <p className={styles.field}>опыт работы: <span>{data.expirience}</span></p>
                    <p className={styles.field}>график: <span>{data.schedule}</span></p>

                    <p className={styles.field}>занятость: <span>{data.types}</span></p>

                </div>

                <div className={styles.content_block}>
                    <h3>Обязанности:</h3>
                    <ul>
                        {data.duties.map( duty => <li>{duty}</li>)}
                    </ul>
                </div>
                
                <div className={styles.content_block}>
                    <h3>Требования:</h3>
                    <ul>
                        {data.requirements.map( requirement => <li>{requirement}</li>)}
                    </ul>
                </div>

                <div className={styles.content_block}>
                    <h3>Привилегии и условия работы:</h3>
                    <ul>
                        {data.benefits_and_conditions.map( ben_and_con => <li>{ben_and_con}</li>)}
                    </ul>
                </div>

                <div className={styles.btn_container}>
                    <button
                        className={styles.btn_close}
                        type="button"
                        onClick={closeModal}
                    >
                        Закрыть
                    </button>
                    <button
                        className={styles.btn_apply}
                        type="button"
                        onClick={openApply}
                    >
                        Откликнуться
                    </button>
                </div>
            </div>
        </PopupAppearLayout>
    );
}

export default VVacanciesModalDetails;
