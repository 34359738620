import styles from './VModal_2Common.module.css'
import * as RadioGroup from '@radix-ui/react-radio-group';
import { useEffect, useState } from 'react';
// Icons
import { ReactComponent as IconPlus } from "../../../../assets/img/icons/plus.svg"

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from '../../../../../../customHooks/useScrollBlock';
import PopupAppearLayout from '../../Layouts/PopupAppearLayout';
import Modal_2 from '../../Modal_2';
import VModal_2CommentAddEdit from './VModal_2CommentAddEdit';

// Helmet
import { setDefaultThemeColor } from '../../../../../../features/helmet/helmetSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultContact } from '../../../../../../features/cart/cartSlice';

const VModal_2CommentSelect = () => {
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();

    const [selectedComment, setSelectedComment] = useState()
    const saveSelectedComment = () => {
        dispatch(setDefaultContact(selectedComment))
        closeModal()
    }
    

    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())
    
    const comments = useSelector(state => state.cart?.order?.comments);
    const defaultCommentIndex = comments?.length ? (comments.findIndex( item => item.default === true )).toString() : null

    // fix to reset form to default values on close
    const [modelReset, setModelReset] = useState(false)
    useEffect( ()=> {
        setSelectedComment(defaultCommentIndex)
    },[defaultCommentIndex, modelReset])

    const ListContacts = () => comments?.length ? comments.map( (comment, k) => {
        return(
                <div key={k} className={styles.radioGroupBlock}>
                    <RadioGroup.Item className={styles.radioGroupItem} value={k.toString()} id={`comment_${k}`}>
                        <RadioGroup.Indicator className={styles.radioGroupIndicator} />
                    </RadioGroup.Item>
                    <label className={styles.label} htmlFor={`comment_${k}`}>

                        <p className={styles.content_text}>{comment.text}</p>
                        <div className={styles.btn_change_container}>
                            <button type='button' onClick={() => openAddEditComment({editComment: comment})} className={styles.btn_change_info}>изменить</button>
                        </div>
                    </label>
                </div>                     
        )
    }) : null


    const openAddEditComment = ({editComment}) => {
        // setTimeout(function(){}, 150);
        Modal_2.open(VModal_2CommentAddEdit, {editComment: editComment});
    }

    const closeModal = () => {
        // setTimeout(function(){allowScroll()}, 150);
        defaultThemeColor()
        allowScroll()
        setModelReset(!modelReset)
        modal.close()
    }   

    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
                <div className={styles.header_container}>
                    <div className={styles.header_title}>
                        <p>Выбор комментария</p>
                    </div>
                    
                    <button
                        className={styles.popup_layout__close}
                        type="button"
                        onClick={closeModal}
                        >
                        &#9587;
                    </button>
                </div>

                                
                {/* SELECT FORM */}
                    {comments.length
                        ?   null
                        :   <p className={styles.noSavedData}>У Вас нет сохраненных комментариев. Добавьте новый комментарий</p>
                    }
                    <form>
                        <RadioGroup.Root 
                            className="RadioGroupRoot" 
                            defaultValue={defaultCommentIndex} 
                            aria-label="select item"
                            onValueChange={setSelectedComment}
                            value={selectedComment}
                        >
                            
                            <ListContacts />
                        
                        </RadioGroup.Root>
                    </form>
                
                    {/* BUTTONS */}
                    <div className={styles.btn_container}>
                        <button onClick={() => openAddEditComment({editComment: null})} className={styles.btn_add_address}>
                            <IconPlus className={styles.icon_plus} />
                            Добавить комментарий
                        </button>
                        <div className={styles.btn_block}>
                            <button onClick={closeModal} className={styles.item_btn_blue_border} >
                                Отмена
                            </button>
                            {comments.length
                                ?   <button onClick={saveSelectedComment} className={styles.item_btn_blue_filled} >
                                        Выбрать
                                    </button>
                                :   null
                            }
                            
                        </div>
                    </div>
                    

                
        </PopupAppearLayout>
    )
}

export default VModal_2CommentSelect