import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../../app/api/apiSlice";

const vacanciesAdapter = createEntityAdapter({})

const initialState = vacanciesAdapter.getInitialState()

export const vacanciesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getVacancies: builder.query({
            query: () => '/vacancies',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            providesTags: [{ type: 'Vacancies', id: 'LIST'}]
        }),
        uploadVacancies: builder.mutation({
            query: initialUserData => {
                // let formObject = Object.fromEntries(initialUserData.entries());
                // console.log(formObject);
                return {
                    url: '/vacancies',
                    method: 'POST',
                    body: initialUserData,
                }
            }
        }),
        // checkOrderGetStatus: builder.mutation({
        //     query: initialUserData => ({
        //         url: '/orders/check',
        //         method: 'POST',
        //         body: {
        //             ...initialUserData,
        //         }
        //     })
        // }),
    }),
})


export const {
    useGetVacanciesQuery, useUploadVacanciesMutation
} = vacanciesApiSlice