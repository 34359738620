import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import cartReducer from "../features/cart/cartSlice"
import helmetReducer from "../features/helmet/helmetSlice"
import detailsReducer from "../features/details/detailsSlice"
import authReducer from "../features/auth/authSlice"

// PERSIST
import {    persistStore, 
            persistReducer,  
            FLUSH,
            REHYDRATE,
            PAUSE,
            PERSIST,
            PURGE,
            REGISTER} from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers ({
    [apiSlice.reducerPath]: apiSlice.reducer,
    cart: cartReducer,
    details: detailsReducer,
    helmet: helmetReducer,
    auth: authReducer
})

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    blacklist: [apiSlice.reducerPath, 'helmet', 'auth'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(apiSlice.middleware),
    devTools: true
})

export const persistor = persistStore(store)
export default store
