import styles from './VModal_2AddSub18_9.module.css'
import React, { useState } from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';
// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from '../../../../../../customHooks/useScrollBlock';
import PopupAppearLayout from '../../Layouts/PopupAppearLayout';
import Modal_3 from '../../Modal_3';
import VModal_3Rules18_9 from '../Rules18_9/VModal_3Rules18_9';
// Icons
import {ReactComponent as IconSublevel} from "../../../../assets/img/icons/sublevel.svg"
// Helmet
import { setDefaultThemeColor } from '../../../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';
// CartComponents
import CartItem from '../CartComponents/CartItem';
// Redux
import { addToCart } from '../../../../../../features/cart/cartSlice';
import ItemToAdd from './ItemToAdd';


const VModal_2AddItem = ({selectedItem, subProduct}) => {
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();
    const defaultValue =    {
                                ...selectedItem,
                                quantity: selectedItem.details.min_order,
                                addedSubProduct: {
                                    ...subProduct,
                                    // quantity: subProduct.details.min_order
                                    quantity: selectedItem.details.min_order,
                                }
                            }
    const [itemToSave, setItemToSave] = useState(defaultValue);

    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())

    const addItemToCart = () => {
        dispatch(addToCart(itemToSave))
        // reset product sub products values to default min order
        setItemToSave(defaultValue)
        // reset radio buttons to 'need a tare'
        setNeedToAddSubProduct(true)
        closeModal()
    }

    const increaseItemQnt = (item) => {
        const updatedSelectedItem = { ...itemToSave };
        if(item.sub_product_code){ // if has then it is main product (not sub product)
            updatedSelectedItem.quantity++ 
        } else{ // Manually create a new object for the nested addedSubProduct
            updatedSelectedItem.addedSubProduct = { ...itemToSave.addedSubProduct };
            updatedSelectedItem.addedSubProduct.quantity++
        }
        setItemToSave(updatedSelectedItem)
    };

    const decreaseItemQnt = (item) => {
        const updatedSelectedItem = { ...itemToSave };
        // if has then it is main product (not sub product)
        if(item.sub_product_code){
            if(updatedSelectedItem.quantity > updatedSelectedItem.details.min_order){
                updatedSelectedItem.quantity--
            }
        } else{
            if(updatedSelectedItem.addedSubProduct.quantity > updatedSelectedItem.addedSubProduct.details.min_order){
                updatedSelectedItem.addedSubProduct.quantity--
            }
        }
        setItemToSave(updatedSelectedItem)
    };

    const closeModal = () => {
      // setTimeout(function(){allowScroll()}, 150);
      defaultThemeColor()
      allowScroll()
      modal.close()
    }   

    const [needToAddSubProduct, setNeedToAddSubProduct] = useState(true)
    const onRelatedProductHandle = (str_boolean) => {
        str_boolean = JSON.parse(str_boolean)
        setNeedToAddSubProduct(str_boolean);
        
        const updatedSelectedItem = { ...itemToSave };

        if(str_boolean){ // add related product
            updatedSelectedItem.addedSubProduct =   {
                                                        ...subProduct,
                                                        quantity: updatedSelectedItem.quantity
                                                    }
        }else{ // do not add related product
            delete updatedSelectedItem.addedSubProduct
        }
        setItemToSave(updatedSelectedItem)
    }

    const openRules18_9 = () => {
            // setTimeout(function(){}, 150);
            // changeThemeColor({color: '#5c5d5e'})
            blockScroll()
            Modal_3.open(VModal_3Rules18_9);
        }
    // local calculation functions
                 
    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
            <div className={styles.header_container}>
                <div className={styles.header_title}>
                    <p>Сопутствующий товар</p>
                </div>
                
                <button
                    className={styles.popup_layout__close}
                    type="button"
                    onClick={closeModal}
                    >
                    &#9587;
                </button>
            </div>

            <div className={styles.addRelatedProduct__container}>
                
                <h3 className={styles.title}>Для доставки воды 18.9 л необходима пустая оборотная бутыль-тара на обмен</h3>
                <button onClick={openRules18_9} className={styles.btn_rules_modal}>Правила приема тары</button>
                {/* SELECT FORM */}
                <div className={styles.radioGroupSelectorContainer}>
                    <p className={styles.message}>Пожалуйста, укажите ниже</p>
                    <form>
                        <RadioGroup.Root 
                            className="RadioGroupRoot" 
                            defaultValue="true" 
                            aria-label="select item"
                            onValueChange={onRelatedProductHandle}
                            value={`${needToAddSubProduct}`}
                        >
                            
                            <div className={styles.radioGroupBlock}>
                                <RadioGroup.Item className={styles.radioGroupItem} value="false" id="r1">
                                    <RadioGroup.Indicator className={styles.radioGroupIndicator} />
                                </RadioGroup.Item>
                                <label className={styles.label} htmlFor="r1">
                                    У меня есть пустая тара для обмена
                                </label>
                            </div>

                            <div className={styles.radioGroupBlock}>
                                <RadioGroup.Item className={styles.radioGroupItem} value='true' id="r2">
                                    <RadioGroup.Indicator className={styles.radioGroupIndicator} />
                                </RadioGroup.Item>
                                <label className={styles.label} htmlFor="r2">
                                    Мне нужно приобрести тару
                                </label>
                            </div>
                        
                        </RadioGroup.Root>
                    </form>
                    
                </div>
                

                {/* ITEMS */}
                <div className={styles.itemsContainer}>
                    <ItemToAdd 
                            item={itemToSave} 
                            increaseItemQnt={increaseItemQnt} 
                            decreaseItemQnt={decreaseItemQnt}
                    />
                </div>
                        
                <div className={`${styles.sublevel_container}${needToAddSubProduct ? " " + styles.active : ''}`}>
                    <IconSublevel className={styles.sublevel_svg} />
                    <div className={styles.itemsContainer}>
                        <ItemToAdd 
                            // add sub product just to make window having the same size so when it is rendered there is no flickering
                            item={itemToSave.addedSubProduct ? itemToSave.addedSubProduct : subProduct}
                            increaseItemQnt={increaseItemQnt} 
                            decreaseItemQnt={decreaseItemQnt}
                        />
                    </div>
                </div>
           


                {/* BUTTONS */}
                <div className={styles.btn_block}>
                    <button className={styles.item_btn_blue_border} onClick={closeModal} >
                        Отмена
                    </button>
                    <button className={styles.item_btn_blue_filled} onClick={addItemToCart} >
                        Добавить
                    </button>
                </div>

            </div>
        </PopupAppearLayout>
    );         
}

export default VModal_2AddItem;
