import styles from './PartHeader.module.css'
// Icons
import iconDelivery from "../../../assets/img/icons/delivery.svg"
import icon_close from "../../../assets/img/icons/close.svg"
import icon_arrow from "../../../assets/img/icons/arrow_left_small.svg"
// Modal
import { useModalWindow } from "react-modal-global";
import Modal_2 from '../Modal_2'
import VModal_DeliverySchedule from '../DeliverySchedule/VModal_DeliverySchedule';
import useScrollBlock from "../../../../../customHooks/useScrollBlock";
// Helmet
import { setDefaultThemeColor, setNewThemeColor } from '../../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';

const PartHeader = ({
                        cartShown, 
                        orderDetailsShown, 
                        isLarge850,

                        showCartHideOrderDetails,
                        hideCartHideOrderDetails
                    }) => {

    // Modal
    const modal = useModalWindow();
    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())
    const changeThemeColor = (color) => dispatch(setNewThemeColor(color))

    const [blockScroll, allowScroll] = useScrollBlock();

    const openDeliverySchedule = () => {
        // setTimeout(function(){}, 150);
        changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_2.open(VModal_DeliverySchedule);
    }

    const closeModal = () => {
      // setTimeout(function(){allowScroll()}, 150);
      allowScroll()
      defaultThemeColor()
      modal.close()
    }  

    return (
        <div className={styles.header}>
            {/* catalog */}
            {
                (isLarge850 && !orderDetailsShown) || (!isLarge850 && !cartShown && !orderDetailsShown)
                ?   <div className={styles.msg_delivery}>
                        <img src={iconDelivery} alt="delivery icon" />
                        <div>
                            <p>г.Алматы c ПН по СБ</p>
                            <p>Пригород по графику: <button className={styles.btnDeliverySchedule} onClick={openDeliverySchedule}>посмотреть график</button></p>
                            
                        </div>
                        
                    </div>
                : null
                
            }
            
            {/* cart */}
            {
                cartShown
                ?   <button className={styles.btn_go_back} onClick={hideCartHideOrderDetails}>
                        <img src={icon_arrow} alt='close button' />
                        Каталог
                    </button>
                :   null
            }
            
            {/* order details */}
            {
                orderDetailsShown
                ?   <button className={styles.btn_go_back} onClick={isLarge850 ? hideCartHideOrderDetails : showCartHideOrderDetails}>
                        <img src={icon_arrow} alt='close button' />
                        Корзина
                    </button>
                :   null
            }

            
            
            <button
                className={"selectDisable " + styles.popup_layout__close}
                type="button"
                onClick={closeModal}
                >
                <img src={icon_close} alt='close button' />
            </button>
        </div>
    )
}

export default PartHeader