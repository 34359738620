import styles from './VnewsModal.module.css'

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from '../../../../customHooks/useScrollBlock';
import PopupAppearLayout from '../../common/Modals/Layouts/PopupAppearLayout';

const VNewsModal = ({data}) => {
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();

    const closeModal = () => {
      // setTimeout(function(){allowScroll()}, 150);
      allowScroll()
      modal.close()
    }   
    
                 
    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
            <div className={styles.header_container}>
                <p className={styles.header_date}>{data.date}</p>
                
                <div className={styles.title_container}>
                    <h3 className={styles.header_title}>{data.title}</h3>
                    <button
                        className={styles.popup_layout__close}
                        type="button"
                        onClick={closeModal}
                        >
                        &#9587;
                    </button>
                </div>
            </div>

            <div className={styles.login__container}>
                
                <img className={styles.img} src={'assets/img/by_page/news/' + data.img_url} alt='news' />
                <p className={styles.content}>{data.content}</p>
                <div className={styles.btn_container}>
                    <button
                        className={styles.btn_close}
                        type="button"
                        onClick={closeModal}
                    >
                        Закрыть
                    </button>
                </div>
            </div>
        </PopupAppearLayout>
    );         
}

export default VNewsModal;
