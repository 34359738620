import styles from './VMainCertificates.module.css'
import VMainTitle from "../VMainTitle"

// use Media
import { useMedia } from '../../../../../customHooks/useMedia';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

// IMAGES
import cert_01 from '../../../assets/img/by_page/main/certificates/certificate_01.png'
import cert_02 from '../../../assets/img/by_page/main/certificates/certificate_02.png'
import cert_03 from '../../../assets/img/by_page/main/certificates/certificate_03.png'
import cert_04 from '../../../assets/img/by_page/main/certificates/certificate_04.png'
import cert_05 from '../../../assets/img/by_page/main/certificates/certificate_05.png'
import cert_06 from '../../../assets/img/by_page/main/certificates/certificate_06.png'
import cert_07 from '../../../assets/img/by_page/main/certificates/certificate_07.png'

const VMainCertificates = () => {
    const isMoreThan1300 = useMedia('(min-width: 1300px)');
    const isMoreThan768 = useMedia('(min-width: 768px)');
    const isMoreThan550 = useMedia('(min-width: 550px)');
    return (
        <>
            <section className={styles.certificates}>
            <VMainTitle title='Сертификаты и анализы' subtitle='Вода “Calipso” соответсует всем нормам и требованиям' url='certificates' />
                <div className={styles.wrapper}>

                <Swiper
                    initialSlide={1}
                    loop={true}
                    slidesPerView={isMoreThan1300 ? 5 : isMoreThan768 ? 3 : isMoreThan550 ? 2 : 1}
                    spaceBetween={0}
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    className="mySwiper"
                    autoplay={{
                            delay: 4000,
                            reverseDirection: true
                        }}
                >
                    {/* SLIDE 1 */}
                    <SwiperSlide>
                        <img src={cert_01} alt="Certificate" />   
                    </SwiperSlide>

                    {/* SLIDE 2 */}
                    <SwiperSlide>
                        <img src={cert_02} alt="Certificate" />   
                    </SwiperSlide>

                    {/* SLIDE 3 */}
                    <SwiperSlide>
                        <img src={cert_03} alt="Certificate" />   
                    </SwiperSlide>

                    {/* SLIDE 4 */}
                    <SwiperSlide>
                        <img src={cert_04} alt="Certificate" />   
                    </SwiperSlide>
                    
                    {/* SLIDE 5 */}
                    <SwiperSlide>
                        <img src={cert_05} alt="Certificate" />   
                    </SwiperSlide>
                    
                    {/* SLIDE 6 */}
                    <SwiperSlide>
                        <img src={cert_06} alt="Certificate" />   
                    </SwiperSlide>

                    {/* SLIDE 7 */}
                    <SwiperSlide>
                        <img src={cert_07} alt="Certificate" />   
                    </SwiperSlide>

                </Swiper>
                    
                </div>
            </section>
        </>
    )
}

export default VMainCertificates