import styles from './AReport.module.css'
import buttons from '../../visitor/common/Buttons.module.css'
import { forwardRef, useEffect, useState } from 'react';
// Date picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from 'date-fns/locale/ru';
// Redux
import { useDownloadOrdersMutation } from '../../../features/orders/ordersApiSlice';

const AReport = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [finalDate, setFinalDate] = useState('')
  
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const CustomDateInput = forwardRef(({ value, onClick }, ref) => {
        function formatDate(dateString) {
            const [month, day, year] = dateString.split('/');
            return `${day}.${month}.${year}`;
        }
          
        const [startDate, endDate] = value.split(' - ');
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        useEffect( () => {
            if(!endDate || formattedStartDate===formattedEndDate){
                setFinalDate(formattedStartDate)
            }else{
                setFinalDate(`${formattedStartDate} - ${formattedEndDate}`)
            }
        },[formattedStartDate, formattedEndDate, endDate])
        
        return  <button type='button' className={styles.customDateBtn} onClick={onClick} ref={ref}>
                    {finalDate}
                    <span className={styles.icon_calendar}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                            <path d="M1 9H17" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1 5V17C1 18.1046 1.89543 19 3 19H15C16.1046 19 17 18.1046 17 17V9V5C17 3.89543 16.1046 3 15 3H3C1.89543 3 1 3.89543 1 5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12 1V5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6 1V5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                    
                </button>
    });

    const [downloadOrders, { isLoading }] = useDownloadOrdersMutation();

    const handleDownload = async () => {
        const data = { start: startDate.getTime(), end: (endDate ? endDate.getTime() : startDate.getTime()) }; // Add your parameters here
        const result = await downloadOrders(data);

        if (result.data && result.data.blob) {
            const url = window.URL.createObjectURL(result.data.blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = "report.xlsx"; // Provide the filename to download
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        }
    }

    return (<>
                <h2 className={styles.title}>Отчет по продажам</h2>
                <div className={styles.report_block}>
                    <div className={styles.part_left}>
                        <p className={styles.date_label}>Дата:</p>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            customInput={<CustomDateInput />}
                            calendarStartDay={1}
                            locale={ru}
                        />
                    </div>
                    <div className={styles.part_right}>
                        <button 
                            onClick={handleDownload}
                            className={buttons.btn_solid_blue}
                            disabled={isLoading}
                        >
                            
                            {isLoading ? 'Ожидайте...' : 'скачать'}
                        </button>
                    </div>
                </div>
            </>
        
    )
}

export default AReport