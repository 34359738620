import styles from './VNav.module.css'
import { NavLink } from 'react-router-dom'
// IMAGES
import menuItem from '../assets/img/icons/menuItem.png'
import Logo from '../assets/img/logo/logo.svg'
import {ReactComponent as ShoppingCart} from '../assets/img/icons/shopping_cart.svg';
import {ReactComponent as Mobile} from '../assets/img/icons/mobile.svg';

// MODALS
import Modal_1 from './Modals/Modal_1';
import VModalCatalog from './Modals/Catalog/VModalCatalog';
import VModalLogin from './Modals/Login/VModalLogin';
import useScrollBlock from '../../../customHooks/useScrollBlock';
// Hooks
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Helmet
import { setNewThemeColor } from '../../../features/helmet/helmetSlice';
import VModalContacts from './Modals/Contacts/VModalContacts';

const VNav = () => {
    const numberOfItemsInShoppingCart = useSelector(state => state.cart.totalQuantity);
    const dispatch = useDispatch();
    const changeThemeColor = (color) => dispatch(setNewThemeColor(color))

    const [menuOpen, setMenuOpen] = useState(false)

    const [blockScroll, allowScroll] = useScrollBlock();
    // Login Modal
    const openLogin = () => {
        // setTimeout(function(){}, 150);
        changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(VModalLogin);
    }
    // Contacts
    const openContacts = () => {
        // setTimeout(function(){}, 150);
        changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(VModalContacts);
    }
    // Catalog Modal SAME Modal
    const openCatalog = () => {
        // setTimeout(function(){}, 150);
        changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(VModalCatalog, { 
                                        id: "modalCatalog", 
                                        closable: false, 
                                        layer: 0, 
                                        defaultCartOpen: false,
                                        defaultOrderDetailsOpen: false});
    }
    // Cart Modal SAME Modal
    const openCart = () => {
        // setTimeout(function(){}, 150);
        changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_1.open(VModalCatalog, { 
                                        id: "modalCatalog", 
                                        closable: false, 
                                        layer: 0, 
                                        defaultCartOpen: true,
                                        defaultOrderDetailsOpen: false
                                    });
    }

    // Burger Menu
    const handleMenu = () => {
        setMenuOpen(!menuOpen)
    }
    const closeMenu = () =>{
        setMenuOpen(false)
    }

  return (
    <>
    
    <nav className={styles.nav}>
        <div className={styles.nav__left}>
            <NavLink to="/" className="selectDisable">
                <img className="selectDisable" src={Logo} alt="Company Logo" />
            </NavLink>
            
        </div>

        <div className={styles.nav__right}>
            
            <div className={styles.loginCatalogCart_block}>
                {/* Login */}
                <button className={styles.nav__btn + " " + styles.nav__btn_click} onClick={openLogin} >Вход</button>
                {/* Catalog */}
                <button className={styles.nav__btn + " " + styles.nav__btn_click} onClick={openCatalog}>Каталог</button>
                {/* Shoppint Cart */}
                <button className={styles.nav__shopping_cart__btn + " " + styles.nav__btn_click} onClick={openCart}>
                    <ShoppingCart className="no" />
                    <span className={styles.nav__shopping_cart__label}>{numberOfItemsInShoppingCart}</span>
                </button>
            </div>
            {/* Contacts */}
            <button onClick={openContacts} className={styles.nav_btn_mobile}>
                <Mobile />
            </button>

            {/* burger */}
            <div id={styles.menuToggle}>
                <input type="checkbox" onChange={handleMenu} checked={menuOpen} />
                <span></span>
                <span></span>
                <span></span>
                <div>
                    <div onClick={closeMenu} className={styles.backdrop}></div>
                    <ul id={styles.menu}>
                        <img className={`selectDisable ${styles.menu_open_logo}`} src={Logo} alt="Company Logo" />
                        {/* Главная */}
                        <li>
                            <NavLink 
                                className={({ isActive }) => styles.nav__item__wrapper + (isActive ? " " + styles.active : "")} 
                                to="/"
                                onClick={closeMenu}
                            >
                                <img className={"selectDisable " + styles.nav__item__icon} src={menuItem} alt="Company Logo" />
                                Главная
                            </NavLink>
                        </li>
                        {/* Новости */}
                        <li>
                            <NavLink 
                                className={({ isActive }) => styles.nav__item__wrapper + (isActive ? " " + styles.active : "")} 
                                to="news"
                                onClick={closeMenu}
                            >
                            <img className={"selectDisable " + styles.nav__item__icon} src={menuItem} alt="Company Logo" />
                            Новости
                            </NavLink>
                        </li>


                        {/* Вакансии */}
                        <li>
                            <NavLink 
                                className={({ isActive }) => styles.nav__item__wrapper + (isActive ? " " + styles.active : "")} 
                                to="vacancies"
                                onClick={closeMenu}
                            >
                            <img className={"selectDisable " + styles.nav__item__icon} src={menuItem} alt="Company Logo" />
                            Вакансии
                            </NavLink>
                        </li>
                        {/* Услуги */}
                        <li>
                            <NavLink 
                                className={({ isActive }) => styles.nav__item__wrapper + (isActive ? " " + styles.active : "")} 
                                to="services"
                                onClick={closeMenu}
                            >
                            <img className={"selectDisable " + styles.nav__item__icon} src={menuItem} alt="Company Logo" />
                            Услуги
                            </NavLink>
                        </li>
                        {/* Co-packing */}
                        <li>
                            <NavLink 
                                className={({ isActive }) => styles.nav__item__wrapper + (isActive ? " " + styles.active : "")} 
                                to="co-packing"
                                onClick={closeMenu}
                            >
                            <img className={"selectDisable " + styles.nav__item__icon} src={menuItem} alt="Company Logo" />
                            Co-packing
                            </NavLink>
                        </li>

                        {/* О компании */}
                        <li>
                            <NavLink 
                                className={({ isActive }) => styles.nav__item__wrapper + (isActive ? " " + styles.active : "")} 
                                to="about-company"
                                onClick={closeMenu}
                            >
                            <img className={"selectDisable " + styles.nav__item__icon} src={menuItem} alt="Company Logo" />
                            О компании
                            </NavLink>
                        </li>
                        {/* О воде */}
                        <li>
                            <NavLink 
                                className={({ isActive }) => styles.nav__item__wrapper + (isActive ? " " + styles.active : "")} 
                                to="about-water"
                                onClick={closeMenu}
                            >
                            <img className={"selectDisable " + styles.nav__item__icon} src={menuItem} alt="Company Logo" />
                            О воде
                            </NavLink>
                        </li>
                        {/* Производство */}
                        <li>
                            <NavLink 
                                className={({ isActive }) => styles.nav__item__wrapper + (isActive ? " " + styles.active : "")} 
                                to="production"
                                onClick={closeMenu}
                            >
                            <img className={"selectDisable " + styles.nav__item__icon} src={menuItem} alt="Company Logo" />
                            Производство
                            </NavLink>
                        </li>
                        {/* Сертификаты */}
                        <li>
                            <NavLink 
                                className={({ isActive }) => styles.nav__item__wrapper + (isActive ? " " + styles.active : "")} 
                                to="certificates"
                                onClick={closeMenu}
                            >
                            <img className={"selectDisable " + styles.nav__item__icon} src={menuItem} alt="Company Logo" />
                            Сертификаты
                            </NavLink>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>
    </nav>
    </>
  )
}

export default VNav