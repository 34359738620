import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserById } from "./usersApiSlice";
import { ROLES } from "../../config/roles"

const User = ({ userId }) => {
    const user = useSelector(state => selectUserById(state, userId))
    console.log(user)
    const navigate = useNavigate()

    if(user){
        const handleEdit = () => navigate(`/dash/users/${userId}`)
        const arrayWithNamesConvertedFromNumbers = Object.values(user.roles).map( roleId => {
            if (roleId in ROLES) {
                return ROLES[roleId]
            } else return "Ошибка"
        })

        const userRolesString = arrayWithNamesConvertedFromNumbers.toString().replaceAll(',', ', ')
        const cellStatus = user.active ? '' : 'table__cell--inactive'

        return (
            <tr className="table__row user">
                <td className={`table__cell ${cellStatus}`}>{user.username}</td>
                <td className={`table__cell ${cellStatus}`}>{userRolesString}</td>
                <td className={`table__cell ${cellStatus}`}>
                    <button className="table__button" onClick={handleEdit}>
                        edit
                    </button>
                </td>

            </tr>
        )
    } else return null
}

export default User