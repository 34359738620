import { ModalController } from 'react-modal-global';


// Catalog
import VModal_2AddSub18_9 from './Catalog/OrderDetails/VModal_2AddSub18_9';
import VModal_2AddressSelect from './Catalog/OrderDetails/VModal_2AddressSelect';
import VModal_2AddressAddEdit from './Catalog/OrderDetails/VModal_2AddressAddEdit';

import VModal_2ContactsSelect from './Catalog/OrderDetails/VModal_2ContactsSelect';
import VModal_2ContactsAddEdit from './Catalog/OrderDetails/VModal_2ContactsAddEdit';

import VModal_2CommentSelect from './Catalog/OrderDetails/VModal_2CommentSelect';
import VModal_2CommentAddEdit from './Catalog/OrderDetails/VModal_2CommentAddEdit';

import Modal_Loading from './Catalog/Notifications/Modal_Loading';

const Modal_2 = new ModalController({
  defaultParams: {},
  components: {   
                VModal_2AddSub18_9,

                VModal_2AddressSelect,
                VModal_2AddressAddEdit,

                VModal_2ContactsSelect,
                VModal_2ContactsAddEdit,

                VModal_2CommentSelect,
                VModal_2CommentAddEdit,

                Modal_Loading
              },
});

export default Modal_2;
