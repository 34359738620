import styles from './VCoPacking.module.css'
import VTitle from '../../common/VTitle'

// Images
import pic_1 from '../../assets/img/by_page/co-packing/pic_1.svg'
import pic_2 from '../../assets/img/by_page/co-packing/pic_2.svg'
import pic_3 from '../../assets/img/by_page/co-packing/pic_3.svg'
import image_1 from '../../assets/img/by_page/co-packing/image_1.png'

const Box = ({ img, title, content }) => {

  return(
              
      <div className={"card__content " + styles.card}>
          <div className={styles.header}>
              <img src={img} alt = {title} />
              <h3 className={styles.title}>{title}</h3>
          </div>
          <p className={styles.content}>{content}</p>
      </div>
              
  )
}

const VCoPacking = () => {
  return (
    <>
      <VTitle title="Co-packing" subtitle="Мы можем Вам предложить сотрудничество по производству Вашей экологически чистой питьевой брендированной воды." />
      <div className={styles.wrapper}>
        <div className={"card__wrapper " + styles.card__wrapper}>
          <VTitle title="Ваш бренд - наша вода" subtitle="Кому подойдет?" />
          <div className={styles.content_block}>
              <Box img={pic_1} title="Для производственных компаний" content="Не имеющих достаточно собственных мощностей, либо желающих расширить ассортимент своей продукции." />
              <Box img={pic_2} title="Для частных компаний и фирм" content="С собственной торговой маркой и логотипом." />
              <Box img={pic_3} title="Для специальных мероприятий" content="Проведение специальных мероприятий позволяет лучше продвигать личный бренд" />
          </div>
          



        </div>
      </div>

      <div className={styles.wrapper_2}>
          <div className={"card__wrapper " + styles.card__wrapper_2}>
              <div className={"card__content " + styles.card_2}>
                  <div className={styles.left}>
                    <p className={styles.we_offer}>Что мы предлагаем?</p>
                    <ul className={styles.list}>
                      <li>Розлив в бутылки</li>
                      <li>Упаковка готовой продукции</li>
                      <li>Оформление готовой продукции этикеткой (изготовление и наклейка этикеток)</li>
                      <li>Доставка Вашей продукции до Вашего Покупателя</li>
                    </ul>
                    <p className={styles.design}>Так же мы предлагаем разработку дизайна этикеток</p>
                  </div>
                  <div className={styles.right}>
                      <img className={styles.offer_img} src={image_1} alt='co-packing bottles' />
                  </div>
              </div>
          </div>
      </div>
    </>
  )
}

export default VCoPacking
