import styles from './PartCategory.module.css'
import { useSelector } from "react-redux";

const PartCategory = ({dbSortedData, category, setCategory}) => {
    const quantityByCategoryInCart = useSelector(state => state.cart.quantityByCategory);

    function selectCategory(e){
        const selectedCategory = e.currentTarget.getAttribute('data-category');
        setCategory(selectedCategory);
    }
    
    const CATEGORIES = Object.entries(dbSortedData).map( ([k,item]) => {
        return(
        <button 
            key={k} 
            data-category={k} 
            className={styles.catalog__category + (category === k ? " " + styles.active : '') } 
            onClick={selectCategory}>
            {k}
            {quantityByCategoryInCart[k] ? <span className={styles.category_label_quantity}>{quantityByCategoryInCart[k]}</span> : ""}
        </button>
        )
    });

    return CATEGORIES

}

export default PartCategory