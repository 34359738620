import styles from './Preloader.module.css'

const Preloader = () => {
  return (
    <div className={styles.wrapper}>

        <div className={styles.loader_5}>
            <span></span>
        </div>

    </div>
  )
}

export default Preloader