import styles from './VCertificates.module.css'
import VTitle from '../../common/VTitle'
// use Media
import { useMedia } from '../../../../customHooks/useMedia';
import CertificatesTabs from './FilesTabs';
import CertificatesAccordion from './FilesAccordion';
import FilesTabs from './FilesTabs';
import FilesAccordion from './FilesAccordion';

// JSON
const dbCertificates =  [
                          {
                            title: 'ЕЭС декларация о соответствии',
                            kaz:  {
                                    url_file: "/certificates/ЕЭС_Декларация_о_Соответствии_каз.pdf",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/ЕЭС_Декларация_о_Соответствии_каз.png?v=1.00"
                                  },
                            rus:  {
                                    url_file: "/certificates/ЕЭС_Декларация_о_Соответствии_рус.pdf",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/ЕЭС_Декларация_о_Соответствии_рус.png?v=1.00"
                                  },
                          },
                          {
                            title: 'Свидетельство о гос. регистрации - детская вода',
                            kaz:  {
                                    url_file: "/certificates/Свидетельтво_о_Гос_регистрации_Детская_вода_каз.pdf",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/Свидетельтво_о_Гос_регистрации_Детская_вода_каз.png"
                                  },
                            rus:  {
                                    url_file: "/certificates/Свидетельтво_о_Гос_регистрации_Детская_вода_рус.pdf",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/Свидетельтво_о_Гос_регистрации_Детская_вода_рус.png"
                                  },
                          },
                          {
                            title: 'Свидетельство о гос. регистрации - столовая вода',
                            kaz:  {
                                    url_file: "/certificates/Свидетельтво_о_Гос_регистрации_Столовая_вода_каз.pdf",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/Свидетельтво_о_Гос_регистрации_Столовая_вода_каз.png"
                                  },
                            rus:  {
                                    url_file: "/certificates/Свидетельтво_о_Гос_регистрации_Столовая_вода_рус.pdf",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/Свидетельтво_о_Гос_регистрации_Столовая_вода_рус.png"
                                  },
                          },
                          {
                            title: 'Свидетельство о гос. регистрации - пищевой продукт',
                            kaz:  {
                                    url_file: "/certificates/Свидетельтво_о_Гос_регистрации_Пищевой_продукт_каз.pdf",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/Свидетельтво_о_Гос_регистрации_Пищевой_продукт_каз.png"
                                  },
                            rus:  {
                                    url_file: "/certificates/Свидетельтво_о_Гос_регистрации_Пищевой_продукт_рус.pdf",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/Свидетельтво_о_Гос_регистрации_Пищевой_продукт_рус.png"
                                  },
                          },
                          {
                            title: 'СТ КЗ Calipso 1л 1,5л негаз, 0,5л и 1л газ',
                            url_file: "/certificates/СТ_КЗ_Calipso_1л_15л_негаз_05л_и_1л_газ.pdf",
                            url_qr: "",
                            thumbnail: "/certificates/thumbnails/СТ_КЗ_Calipso_1л_15л_негаз_05л_и_1л_газ.png"
                          },
                          {
                            title: 'СТ КЗ Calipso 0,5л 18,9л негаз',
                            url_file: "/certificates/СТ_КЗ_Calipso_05л_189л_негаз.pdf",
                            url_qr: "",
                            thumbnail: "/certificates/thumbnails/СТ_КЗ_Calipso_05л_189л_негаз.png"
                          },
                          {
                            title: 'СТ КЗ Calipso 1,5л газ, 5л негаз, Калипсик 0,5л и 1,5л негаз',
                            url_file: "/certificates/СТ_КЗ_Calipso_15л_газ_5л_негаз_Калипсик_05л_и_15л_негаз.pdf",
                            url_qr: "",
                            thumbnail: "/certificates/thumbnails/СТ_КЗ_Calipso_15л_газ_5л_негаз_Калипсик_05л_и_15л_негаз.png"
                          },
                          {
                            title: 'СТ КЗ Calipso 1л Карловарская, Калипсик 5л негаз',
                            url_file: "/certificates/СТ_КЗ_Calipso_1л_карловарская_Калипсик_5л_негаз.pdf",
                            url_qr: "",
                            thumbnail: "/certificates/thumbnails/СТ_КЗ_Calipso_1л_карловарская_Калипсик_5л_негаз.png"
                          },
                          {
                            title: 'СТ РК ISO 9001-2016 менеджмент качества продукции',
                            kaz:  {
                                    url_file: "/certificates/СТ_РК_ISO_9001_2016_менеджмент_качества_продукции_каз.pdf?v=1.00",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/СТ_РК_ISO_9001_2016_менеджмент_качества_продукции_каз.png?v=1.00"
                                  },
                            rus:  {
                                    url_file: "/certificates/СТ_РК_ISO_9001_2016_менеджмент_качества_продукции_рус.pdf?v=1.00",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/СТ_РК_ISO_9001_2016_менеджмент_качества_продукции_рус.png?v=1.00"
                                  },
                          },
                          {
                            title: 'СТ РК ISO 22000:2018 Системы менеджмента безопасности пищевой продукции',
                            kaz:  {
                                    url_file: "/certificates/СТ_РК_ISO_220002018_Системы_менеджмента_безопасности_пищевой_продукции_каз.pdf?v=1.00",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/СТ_РК_ISO_220002018_Системы_менеджмента_безопасности_пищевой_продукции_каз.png?v=1.00"
                                  },
                            rus:  {
                                    url_file: "/certificates/СТ_РК_ISO_220002018_Системы_менеджмента_безопасности_пищевой продукции_рус.pdf?v=1.00",
                                    url_qr: "",
                                    thumbnail: "/certificates/thumbnails/СТ_РК_ISO_220002018_Системы_менеджмента_безопасности_пищевой продукции_рус.png?v=1.00"
                                  },
                          },
                    ]
const dbAnalyses =  [
                      {
                        title: "Вирусология (Calips'ик)",
                        url_file: "/analyses/Вирусология_Калипсик.pdf",
                        url_qr: "",
                        thumbnail: "/analyses/thumbnails/Вирусология_Калипсик.png"
                      },
                      {
                        title: 'Вирусология (Calipso)',
                        url_file: "/analyses/Вирусология_Калипсо.pdf",
                        url_qr: "",
                        thumbnail: "/analyses/thumbnails/Вирусология_Калипсо.png"
                      },
                      {
                        title: 'Микробиология',
                        url_file: "/analyses/Микробиология.pdf",
                        url_qr: "",
                        thumbnail: "/analyses/thumbnails/Микробиология.png"
                      },
                      {
                        title: 'Пестициды',
                        url_file: "/analyses/Пестициды.pdf",
                        url_qr: "",
                        thumbnail: "/analyses/thumbnails/Пестициды.png"
                      },
                      {
                        title: "Радиоактивность воды (Calips'ик)",
                        url_file: "/analyses/Радиоактивность_воды_Калипсик.pdf",
                        url_qr: "",
                        thumbnail: "/analyses/thumbnails/Радиоактивность_воды_Калипсик.png"
                      },

                      {
                        title: 'Радиоактивность воды (Calipso)',
                        url_file: "/analyses/Радиоактивность_воды_Калипсо.pdf",
                        url_qr: "",
                        thumbnail: "/analyses/thumbnails/Радиоактивность_воды_Калипсо.png"
                      },
                      {
                        title: 'Санитарно-паразитологический',
                        url_file: "/analyses/Санитарно-паразитологический.pdf",
                        url_qr: "",
                        thumbnail: "/analyses/thumbnails/Санитарно-паразитологический.png"
                      },
                      {
                        title: "Физико-химический (Calips'ик)",
                        url_file: "/analyses/Физико-химический_Калипсик.pdf",
                        url_qr: "",
                        thumbnail: "/analyses/thumbnails/Физико-химический_Калипсик.png"
                      },
                      {
                        title: 'Физико-химический (Calipso)',
                        url_file: "/analyses/Физико-химический_Калипсо.pdf",
                        url_qr: "",
                        thumbnail: "/analyses/thumbnails/Физико-химический_Калипсо.png"
                      },
                      {
                        title: 'Физико-химический (скважина)',
                        url_file: "/analyses/Физико-химический_скважина.pdf",
                        url_qr: "",
                        thumbnail: "/analyses/thumbnails/Физико-химический_скважина.png"
                      },
                    ]




const VCertificates = () => {
  const isLargeScreen = useMedia('(min-width: 768px)');

  return (
    <>
      <VTitle title="Сертификаты и анализы" subtitle='Вода “Calipso” соответсует всем нормам и требованиям' />
      <div className={styles.wrapper}>
          {/* Certificates */}
          <div className={"card__wrapper " + styles.card__wrapper}>
                <div className={styles.content_block}>
                    <div className={"card__content " + styles.card}>
                      <h3 className={styles.title}>Сертификаты</h3>
                      <p>Сертификаты играют ключевую роль в подтверждении качества нашей воды. Они представляют собой официальные документы, выданные авторитетными организациями, подтверждающие соответствие нашей продукции строгим стандартам качества и безопасности. Эти сертификаты не только удостоверяют нашу приверженность высоким стандартам производства, но и придают доверия нашим клиентам, обеспечивая им уверенность в качестве воды, которую они выбирают у нас.</p>
                    </div>
                </div>
                {isLargeScreen ? <FilesTabs dbArray={dbCertificates} /> : <FilesAccordion dbArray={dbCertificates} />}
          </div>

          {/* Analyses */}
          <div className={"card__wrapper " + styles.card__wrapper}>
                <div className={styles.content_block}>
                    <div className={"card__content " + styles.card}>
                      <h3 className={styles.title}>Анализы</h3>
                      <p>Анализы нашей воды являются неотъемлемой частью нашего производственного процесса. Мы регулярно проводим тщательные исследования и анализы, чтобы гарантировать ее безупречное качество и чистоту. Эти анализы включают в себя широкий спектр тестирований, в том числе на наличие вредных веществ, минеральный состав, pH-баланс и многое другое. Результаты анализов помогают нам не только удостовериться в безопасности нашей воды, но и постоянно совершенствовать процесс производства, чтобы предложить нашим клиентам лучшее качество и сервис.</p>
                    </div>
                </div>
                {isLargeScreen ? <FilesTabs  dbArray={dbAnalyses} /> : <FilesAccordion dbArray={dbAnalyses} />}
          </div>
      </div>
     
      
    </>
  )
}

export default VCertificates