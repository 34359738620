import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE =   {
                            version: 6,
                            cart:   [],
                            quantityByCategory: {},
                            totalQuantity: 0,
                            totalPrice: 0,
                            order: {
                                paymentType: null,
                                deliveryDate: null,
                                comments:   [
                                                // {
                                                //     text: 'Просьба позвонить за час',
                                                //     uuid: "123",
                                                //     default: true
                                                // }
                                            ]
                            },
                            addresses:  [
                                        // {
                                        //     city: 'Алматы',
                                        //     street: 'Сейфуллина',
                                        //     building: '597В',
                                        //     floor: '7',
                                        //     entrance: '3',
                                        //     apt_office: '23',
                                        //     comment: 'Вход с двора, последний подъезд',
                                        //     default: true,
                                        //     uuid: '123'
                                        // },
                                        ],
                            contacts:   [
                                        // {
                                        //     first_name: 'John',
                                        //     last_name: 'Doe',
                                        //     mobile: '+7(702)123-45-67',
                                        //     default: true,
                                        //     uuid: '123'
                                        // }
                                        ],
                        }

const cartSlice = createSlice({
    name: "cart",
    initialState: INITIAL_STATE,
    reducers:   {
                    resetCartToInitial: (state, action) => {
                        const localVersion = action.payload
                        if(
                            localVersion < INITIAL_STATE.version ||
                            localVersion === undefined
                        ){
                            return INITIAL_STATE
                        }
                    },
                    addToCart: (state, action) => {
                        const newProduct = {...action.payload};
                        
                        // is it subproduct?
                        const itemIndexWithSubProductItemCode = state.cart.findIndex( (item) => 
                                                // check if product in cart has sub products (related) but it could potentially be not still yet added though
                                                item.sub_product_code === newProduct.item_code
                                            );
                        
                        // count by category
                        // add the number of items it has (quantity) no matter whether it is a subproduct or main product
                        if(state.quantityByCategory[newProduct.category]){
                            state.quantityByCategory[newProduct.category] += newProduct.quantity ? newProduct.quantity : newProduct.details.min_order
                            // if it has added sub products  then it is means the item is main product  now we can add sub product
                            if(newProduct.addedSubProduct){
                                state.quantityByCategory[newProduct.addedSubProduct.category] += newProduct.addedSubProduct.quantity
                            }
                        } else{ // does not exist create
                            state.quantityByCategory[newProduct.category] = newProduct.quantity ? newProduct.quantity : newProduct.details.min_order
                            if(newProduct.addedSubProduct){
                                state.quantityByCategory[newProduct.addedSubProduct.category] += newProduct.addedSubProduct.quantity
                            }
                        }
                        // end of count by category

                        if(itemIndexWithSubProductItemCode !==-1 && !state.cart[itemIndexWithSubProductItemCode].addedSubProduct){ // add item as a subproduct
                            // add sub product quantity same as main product
                            if(!newProduct.quantity){
                                newProduct.quantity = state.cart[itemIndexWithSubProductItemCode].quantity
                            }
                            state.cart[itemIndexWithSubProductItemCode].addedSubProduct = newProduct    
                            // do calculations only for subproduct as it belongs to main product which was already added
                            // therefore only calulcate for the new sub product
                            state.totalQuantity += newProduct.quantity
                            state.totalPrice += newProduct.quantity * newProduct.details.price // add to total
                        }else{ // add item as a main product
                            // add quantity as min order as it is a main product with no sub products code
                            if(!newProduct.quantity){
                                newProduct.quantity = newProduct.details.min_order
                            }
                            // if it is a main product then itemIndexWithSubProductItemCode will be -1 since mains products
                            // will not be found as an item with subproduct  so we can add the new item to the cart as main product
                            state.cart = [newProduct, ...state.cart];
                            // do calculations for main product first
                            state.totalQuantity+= newProduct.quantity
                            state.totalPrice += newProduct.quantity * newProduct.details.price // add to total
                            //however it the main product had already added sub products then we can to calculate sub product too
                            // if sub product is already added then it MUST have quantity parameter
                            if(newProduct.addedSubProduct){
                                // if main product WITH subproduct then do calculations for subproduct
                                state.totalQuantity+= newProduct.addedSubProduct.quantity
                                state.totalPrice += newProduct.addedSubProduct.quantity * newProduct.addedSubProduct.details.price // add to total
                            }
                        }
                    },
                    removeFromCart: (state, action) => {
                        const product = {...action.payload};
                        // is it subproduct?
                        const itemIndexInMainProdcutOfSubProduct = state.cart.findIndex( (item) => 
                                                // check if product in cart has AddedSubProducts
                                                item.addedSubProduct && (item.addedSubProduct.item_code === product.item_code)
                                            );
                        const mainProduct = state.cart.find((item) => item.item_code === product.item_code);
                        // decrease sub as it is subtracted in both cases
                        var subTemp
                        if(itemIndexInMainProdcutOfSubProduct !== -1){
                            subTemp = state.cart[itemIndexInMainProdcutOfSubProduct].addedSubProduct
                        }else{
                            subTemp = mainProduct
                        }

                        // decrease total and quantity
                        state.totalQuantity -= subTemp.quantity
                        state.totalPrice -= subTemp.quantity * subTemp.details.price // add to total
                        if(subTemp.addedSubProduct){ // sub product
                            state.totalQuantity -= subTemp.addedSubProduct.quantity
                            state.totalPrice -= subTemp.addedSubProduct.quantity * subTemp.addedSubProduct.details.price // add to total
                        }
                        // end of decrease total and quantity
                        

                        // calculate category total
                        state.quantityByCategory[subTemp.category] -= subTemp.quantity
                        // if it has added sub products  then it is means the item is main product  now we can add sub product
                        if(subTemp.addedSubProduct){
                            state.quantityByCategory[subTemp.addedSubProduct.category] -= subTemp.addedSubProduct.quantity
                        }
                        if(state.quantityByCategory[subTemp.category] === 0){
                            state.quantityByCategory[subTemp.category] = null
                        }
                        // end of calculate category total
                        

                        // DELETE
                        if(itemIndexInMainProdcutOfSubProduct !== -1){ 
                            // remove sub product from main product
                            delete state.cart[itemIndexInMainProdcutOfSubProduct].addedSubProduct
                        } else{ // remove main product that has no sub products
                            state.cart = state.cart.filter((item) =>item.item_code !== mainProduct.item_code);
                        }
                        
                        
                    },
                    increaseQuantity: (state, action) => {
                        const product = {...action.payload};
                        // is it subproduct?
                        const itemIndexInMainProdcutOfSubProduct = state.cart.findIndex( (item) => 
                                                // check if product in cart has AddedSubProducts
                                                item.addedSubProduct && (item.addedSubProduct.item_code === product.item_code)
                                            );
                        const mainProduct = state.cart.find((item) => item.item_code === product.item_code);
                        // increase sub as it is subtracted in both cases
                        var subTemp
                        if(itemIndexInMainProdcutOfSubProduct !== -1){
                            subTemp = state.cart[itemIndexInMainProdcutOfSubProduct].addedSubProduct
                        }else{
                            subTemp = mainProduct
                        }

                        state.totalPrice += subTemp.details.price
                        subTemp.quantity++
                        state.quantityByCategory[subTemp.category]++
                        state.totalQuantity ++   
                        // end of increase total and quantity
                                             
                    },
                    decreaseQuantity: (state, action) => {
                        const product = {...action.payload};

                        // is it subproduct?
                        const itemIndexInMainProdcutOfSubProduct = state.cart.findIndex( (item) => 
                                                // check if product in cart has AddedSubProducts
                                                item.addedSubProduct && (item.addedSubProduct.item_code === product.item_code)
                                            );
                        const mainProduct = state.cart.find((item) => item.item_code === product.item_code);
                        // decrease sub as it is subtracted in both cases
                        var subTemp
                        if(itemIndexInMainProdcutOfSubProduct !== -1){
                            subTemp = state.cart[itemIndexInMainProdcutOfSubProduct].addedSubProduct
                        }else{
                            subTemp = mainProduct
                        }
                        
                        if (subTemp.quantity > subTemp.details.min_order) {
                            // subtract only if the quantity is more that min order 
                            // because below when quantity is equal to min order the the subtraction
                            // is done by remove slice above
                            state.totalPrice -= subTemp.details.price
                            state.quantityByCategory[subTemp.category]--
                            state.totalQuantity--
                            subTemp.quantity--
                        } else if(subTemp.quantity <= subTemp.details.min_order){
                            // do not do subtraction calculation as it is done in remove slice
                            cartSlice.caseReducers.removeFromCart(state, action);
                        }
                        // end of decrease total and quantit   
                    },
                    emptyCart: (state, action) => {
                        return {
                            ...state,
                            cart: [],
                            quantityByCategory: {},
                            totalQuantity: 0,
                            totalPrice: 0
                        }
                    },

                    // DELIVERY
                    setDeliveryDate(state, action) {
                            state.order.deliveryDate = action.payload;
                    },

                    // ADDRESS
                    setDefaultAddress(state, action) {
                        state.addresses.map((address, index) => {
                            if (index === Number(action.payload)) {
                                return state.addresses[index].default = true;
                            } else {
                                return state.addresses[index].default = false;
                            }
                          });
                    },
                    addEditAddress(state, action) {
                        const { city, street, building, floor, entrance, apt_office, uuid, comment } = action.payload
                        // reset all addresses to false
                        state.addresses.map((address, index) => address.default = false )
                        // add new default
                        const address = {
                            city: city,
                            street: street,
                            building: building,
                            floor: floor,
                            entrance: entrance,
                            apt_office: apt_office,
                            comment: comment,
                            uuid: uuid,
                            default: true,
                        }
                        const addressToBeEdited = state.addresses.findIndex( (item) => item.uuid === uuid );
                        if(addressToBeEdited !== -1){                            
                            state.addresses[addressToBeEdited] = address
                        } else{
                            state.addresses.push(address)
                        }
                        
                    },
                    deleteAddress(state, action) {
                        const { uuid } = action.payload
                        state.addresses = state.addresses.filter((item) =>item.uuid !== uuid);
                        if(state.addresses.length){
                            state.addresses[0].default = true
                        }
                    },

                    // CONTACTS
                    setDefaultContact(state, action) {
                        state.contacts.map((contact, index) => {
                            if (index === Number(action.payload)) {
                                return state.contacts[index].default = true;
                            } else {
                                return state.contacts[index].default = false;
                            }
                          });
                    },
                    addEditContact(state, action) {
                        const { first_name, last_name, mobile, uuid } = action.payload
                        // reset all addresses to false
                        state.contacts.map((contact, index) => contact.default = false )
                        // add new default
                        const contact = {
                            first_name: first_name,
                            last_name: last_name,
                            mobile: mobile,
                            uuid: uuid,
                            default: true,
                        }
                        const contactToBeEdited = state.contacts.findIndex( (item) => item.uuid === uuid );
                        if(contactToBeEdited !== -1){                            
                            state.contacts[contactToBeEdited] = contact
                        } else{
                            state.contacts.push(contact)
                        }
                    },
                    deleteContact(state, action) {
                        const { uuid } = action.payload
                        state.contacts = state.contacts.filter((item) =>item.uuid !== uuid);
                        if(state.contacts.length){
                            state.contacts[0].default = true
                        }
                    },
                    // COMMENTS
                    setDefaultComment(state, action) {
                        state.order.comments.map((comment, index) => {
                            if (index === Number(action.payload)) {
                                return state.order.comments[index].default = true;
                            } else {
                                return state.order.comments[index].default = false;
                            }
                          });
                    },
                    addEditComment(state, action) {
                        const { text, uuid } = action.payload
                        // reset all comments to false
                        state.order.comments.map((comment, index) => comment.default = false )
                        // add new default
                        const comment = {
                            text: text,
                            uuid: uuid,
                            default: true,
                        }
                        const commentToBeEdited = state.order.comments.findIndex( (item) => item.uuid === uuid );
                        if(commentToBeEdited !== -1){                            
                            state.order.comments[commentToBeEdited] = comment
                        } else{
                            state.order.comments.push(comment)
                        }
                    },
                    deleteComment(state, action) {
                        const { uuid } = action.payload
                        state.order.comments = state.order.comments.filter((item) =>item.uuid !== uuid);
                        if(state.order.comments.length){
                            state.order.comments[0].default = true
                        }
                    },
                    // PAYMENT
                    setPaymentMethod(state, action) {
                        const paymentMethod = action.payload
                        state.order.paymentType = paymentMethod
                    },
                    
                    
                },
});

export const { 
                resetCartToInitial,
                addToCart, removeFromCart, increaseQuantity, decreaseQuantity, emptyCart, 
                setDeliveryDate,
                setDefaultAddress, addEditAddress, deleteAddress,
                setDefaultContact, addEditContact, deleteContact,
                setDefaultComment, addEditComment, deleteComment,
                setPaymentMethod
             } = cartSlice.actions;

export default cartSlice.reducer;