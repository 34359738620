import styles from './AFooter.module.css'
import Logo from '../../visitor/assets/img/logo/logo.svg'

const AFooter = () => {
  return (
    <div className={styles.footer}>
        <img className="selectDisable" src={Logo} alt="Company Logo" />
        <p className={styles.allRights + ' mb-5'}>Все права защищены 1997-{new Date().getFullYear()}</p>
    </div>
  )
}

export default AFooter