import styles from './ANav.module.css'
import { NavLink, useNavigate } from 'react-router-dom'
// IMAGES
import Logo from '../../visitor/assets/img/logo/logo.svg'
import menuItem from '../../visitor/assets/img/icons/menuItem.png'
// JWT
import { jwtDecode } from 'jwt-decode'
import { ROLES } from '../../../config/roles'
// MODALS
import Modal_1 from '../../visitor/common/Modals/Modal_1';
import useScrollBlock from '../../../customHooks/useScrollBlock';
// Hooks
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Helmet
import { setNewThemeColor } from '../../../features/helmet/helmetSlice';
// Redux
import { selectCurrentToken } from '../../../features/auth/authSlice';
import { apiSlice } from '../../../app/api/apiSlice';
import { useSendLogoutMutation } from '../../../features/auth/authApiSlice';

const ANav = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const token = useSelector(selectCurrentToken)
    const [userInfo, setUserInfo] = useState({});
    const [role, setRole] = useState('');

    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            setUserInfo(decoded.userInfo);
            setRole(ROLES[decoded.userInfo.role]);
        }
    }, [token])
    // const changeThemeColor = (color) => dispatch(setNewThemeColor(color))

    // const [menuOpen, setMenuOpen] = useState(false)

    // const [blockScroll, allowScroll] = useScrollBlock();
    // Login Modal
    // const openLogin = () => {
    //     // setTimeout(function(){}, 150);
    //     changeThemeColor({color: '#5c5d5e'})
    //     blockScroll()
    //     Modal_1.open(VModalLogin);
    // }

    // Burger Menu
    // const handleMenu = () => {
    //     setMenuOpen(!menuOpen)
    // }
    // const closeMenu = () =>{
    //     setMenuOpen(false)
    // }

    const [sendLogout, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    const logout= async (e) => {
        e.preventDefault();
        try {
            const result =  await sendLogout().unwrap();
            navigate('/admin')
            dispatch(apiSlice.util.resetApiState())
            // do something with it
        } catch (error) {
            // do something with it
            console.log(error)
        }
    }

    return (<>
                <nav className={styles.nav}>
                    <div className={styles.nav__left}>
                        <NavLink to="/" className="selectDisable">
                            <img className="selectDisable" src={Logo} alt="Company Logo" />
                        </NavLink>
                        
                    </div>

                    <div className={styles.nav__right}>
                        



                        {/* burger */}
                        {/* <div id={styles.menuToggle}>
                            <input type="checkbox" onChange={handleMenu} checked={menuOpen} />
                            <span></span>
                            <span></span>
                            <span></span>
                            <div>
                                <div onClick={closeMenu} className={styles.backdrop}></div>
                                <ul id={styles.menu}>
                                    <img className={`selectDisable ${styles.menu_open_logo}`} src={Logo} alt="Company Logo" /> */}
                                    {/* Главная */}
                                    {/* <li>
                                        <NavLink 
                                            className={({ isActive }) => styles.nav__item__wrapper + (isActive ? " " + styles.active : "")} 
                                            to="/admin/dashboard"
                                            onClick={closeMenu}
                                        >
                                            <img className={"selectDisable " + styles.nav__item__icon} src={menuItem} alt="Company Logo" />
                                            Dashboard
                                        </NavLink>
                                    </li>
                                   
                                </ul>
                            </div> 
                        </div>*/}
                        <div className={styles.user_info_block}>
                            <p className={styles.user_info_fullName}>{userInfo.first_name} {userInfo.last_name}</p>
                            <p className={styles.user_info_role}>{role}</p>
                        </div>
                        <button 
                                className={styles.logout} 
                                onClick={logout}
                            >
                            Выйти
                        </button>
                    </div>
                </nav>
            </>
  )
}

export default ANav