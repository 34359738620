import { Link } from "react-router-dom"
import arrowRight from "../assets/img/icons/arrow_right.svg"
import styles from "./VTitle.module.css"

const VTitle = ({ title, subtitle, url }) => {
  return (
      <div className="visitor__title_container">
        <div className="visitor__title_wrapper">
            <h2 className="visitor__title__h2">{title}</h2>
            {url 
              ? <Link 
                  className="visitor__title__btn_see_more}
                  to={url">
                    <span>Смотреть все</span>
                    <img src={arrowRight} alt="arrow right" />
                </Link> 
              : ''}
        </div>
        {subtitle ? <p className="visitor__subtitle">{subtitle}</p> : ''} 
      </div>
  )
}

export default VTitle