import styles from './PartCart.module.css'

// Redux
import { useDispatch, useSelector } from "react-redux";
import { emptyCart } from "../../../../../features/cart/cartSlice";

import PayButtonToServer from './PayButtonToServer';
import EmptyCart from './CartComponents/EmptyCart';
import CartWithItems from './CartComponents/CartWithItems';
import CartTotal from './CartComponents/CartTotal';


const PartCart = ({orderDetailsShown, setOrderDetailsShown, isLarge850}) => {
    
    
    // REDUX
    const dispatch = useDispatch()

    const itemsInShoppingCart = useSelector(state => state.cart.cart);
    const totalPriceShoppingCart = useSelector(state => state.cart.totalPrice);
    
    const clearCart = () => dispatch(emptyCart())

    
    // ORDER DETAILS
    const showOrderDetails = () => {setOrderDetailsShown(true)}
        
    return(<>
                <div className={styles.cart_title_btn}>
                    <h5>Корзина</h5>
                    <button className={styles.btn_reset} onClick={clearCart}>Очистить</button>
                </div>
                {itemsInShoppingCart.length ? <CartWithItems itemsInShoppingCart={itemsInShoppingCart} isForCart={true} /> : <EmptyCart />}
                <div className={styles.cart__make_order_section}>
                    
                    <CartTotal totalPriceShoppingCart={totalPriceShoppingCart}/>
                    {/* Cart */}
                    {
                        isLarge850 && !orderDetailsShown && itemsInShoppingCart.length
                            ?   <>
                                    <button 
                                        className={styles.item_btn_blue}
                                        disabled={ itemsInShoppingCart.length ? false : true}
                                        onClick={showOrderDetails}
                                    >
                                        Оформить
                                    </button>
                                </>
                            : null
                    }
                    {/* OrderDetails */}
                    {
                        isLarge850 && orderDetailsShown && itemsInShoppingCart.length
                        ?   <PayButtonToServer />
                        :   null
                    }
                </div>
    </>)

}

export default PartCart