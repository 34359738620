export function createNestedObject(jsonData) {
    var result = {};

    // Iterate over each JSON item
    jsonData.forEach(function(item) {
        // Create category if not exists
        if (!result[item.category]) {
            result[item.category] = {};
        }

        // Create subcategory if not exists
        if (!result[item.category][item.subcategory]) {
            result[item.category][item.subcategory] = [];
        }

        // Add item to subcategory
        result[item.category][item.subcategory].push(item);
    });

    return result;
}

