import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import { jwtDecode } from 'jwt-decode'
import { ROLES } from '../config/roles'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let roleFlags = {
        isCustomer: false,
        isEmployee: false,
        isManager: false,
        isAdmin: false,
        isSuperAdmin: false,
        isOwner: false
    };
    
    let status = "Пользователь"
    if (token) {
        const decoded = jwtDecode(token)
        const { email, role } = decoded.userInfo

        Object.keys(ROLES).forEach(key => {
            if (role === key) {
                roleFlags['is' + key] = true; // Assumes each key matches a flag in roleFlags
                status = ROLES[key]; // Set status using the display value from ROLES
            }
        });

        return { email, role: status,  ...roleFlags }
    }

    return { email: '', role: '', ...roleFlags }
}
export default useAuth