import styles from './VModal_2Common.module.css'
import * as RadioGroup from '@radix-ui/react-radio-group';
import { useEffect, useState } from 'react';
// Icons
import { ReactComponent as IconPlus } from "../../../../assets/img/icons/plus.svg"

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from '../../../../../../customHooks/useScrollBlock';
import PopupAppearLayout from '../../Layouts/PopupAppearLayout';
import VModal_2AddressAddEdit from './VModal_2AddressAddEdit';
import Modal_2 from '../../Modal_2';

// Helmet
import { setDefaultThemeColor } from '../../../../../../features/helmet/helmetSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultAddress } from '../../../../../../features/cart/cartSlice';

const VModal_2AddressSelect = () => {
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();

    const [selectedAddress, setSelectedAddress] = useState()
    const saveSelectedAddress = () => {
        dispatch(setDefaultAddress(selectedAddress))
        closeModal()
    }
    

    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())
    
    const addresses = useSelector(state => state.cart?.addresses);
    const defaultAddressIndex = addresses?.length ? (addresses.findIndex( item => item.default === true )).toString() : null

    // fix to reset form to default values on close
    const [modelReset, setModelReset] = useState(false)
    useEffect( ()=> {
        setSelectedAddress(defaultAddressIndex)
    },[defaultAddressIndex, modelReset])

    const ListAddresses = () => addresses?.length ? addresses.map( (address, k) => {
        return(
                <div key={k} className={styles.radioGroupBlock}>
                    <RadioGroup.Item className={styles.radioGroupItem} value={k.toString()} id={`address_${k}`}>
                        <RadioGroup.Indicator className={styles.radioGroupIndicator} />
                    </RadioGroup.Item>
                    <label className={styles.label} htmlFor={`address_${k}`}>

                            <p className={styles.content_text}>{address.city}, {address.street} {address.building}</p>
                            <p className={styles.content_text}>
                                {address.apt_office.trim() !== '' ? `кв/офис ${address.apt_office} `: ""} 
                                {address.entrance.trim() !== '' || address.floor.trim() !== '' ? "(" : ""}
                                    {address.entrance.trim() !== '' ? `подъезд ${address.entrance}`: ""} 
                                {address.entrance.trim() !== '' && address.floor.trim() !== '' ? ", " : ""}
                                    {address.floor.trim() !== '' ? `этаж ${address.floor} `: ""}
                                {address.entrance.trim() !== '' || address.floor.trim() !== '' ? ")" : ""}
                            </p>   
                            <p className={`${styles.content_text} ${styles.comment_text}`}>{address.comment}</p>                                             
                            <div className={styles.btn_change_container}>
                                <button onClick={() => openAddEditAddress({editAddress: address})} type='button' className={styles.btn_change_info}>изменить</button>
                            </div>                        

                    </label>
                </div>                     
        )
    }) : null


    const openAddEditAddress = ({editAddress}) => {
        // setTimeout(function(){}, 150);
        Modal_2.open(VModal_2AddressAddEdit, {editAddress: editAddress});
    }

    const closeModal = () => {
        // setTimeout(function(){allowScroll()}, 150);
        defaultThemeColor()
        allowScroll()
        setModelReset(!modelReset)
        modal.close()
    }   

    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
                <div className={styles.header_container}>
                    <div className={styles.header_title}>
                        <p>Выбор адреса</p>
                    </div>
                    
                    <button
                        className={styles.popup_layout__close}
                        type="button"
                        onClick={closeModal}
                        >
                        &#9587;
                    </button>
                </div>

                                
                {/* SELECT FORM */}
                    {addresses.length
                        ?   null
                        :   <p className={styles.noSavedData}>У Вас нет сохраненных адресов. Добавьте новый адрес</p>
                    }
                    <form>
                        <RadioGroup.Root 
                            className="RadioGroupRoot" 
                            defaultValue={defaultAddressIndex} 
                            aria-label="select item"
                            onValueChange={setSelectedAddress}
                            value={selectedAddress}
                        >
                            
                            <ListAddresses />
                        
                        </RadioGroup.Root>
                    </form>
                
                    {/* BUTTONS */}
                    <div className={styles.btn_container}>
                        <button onClick={() => openAddEditAddress({editAddress: null})} className={styles.btn_add_address}>
                            <IconPlus className={styles.icon_plus} />
                            Добавить адрес
                        </button>
                        <div className={styles.btn_block}>
                            <button onClick={closeModal} className={styles.item_btn_blue_border} >
                                Отмена
                            </button>
                            {addresses.length
                                ?   <button onClick={saveSelectedAddress} className={styles.item_btn_blue_filled} >
                                        Выбрать
                                    </button>
                                :   null
                            }
                            
                        </div>
                    </div>
                    

                
        </PopupAppearLayout>
    )
}

export default VModal_2AddressSelect