import styles from './ItemToAdd.module.css'
// functions
import { formatPrice } from '../../../../../../customHooks/functions';

const ItemToAdd = ({item, increaseItemQnt, decreaseItemQnt}) => {
    if(!item.item_code){
        return
    }
    return  ( 
                <section key={item.item_code} className={styles.cart_block}>
                    {/* CART LEFT */}
                    <div className={styles.cart_img_title_code_block}>
                        <div className={styles.cart__left}>
                            <img src={`/assets/img/catalog/${item.image_url}?v=1.01`} alt="item pic"></img>
                        </div>

                        {/* CART RIGHT */}
                        <div className={styles.cart__right}>
                            <p className={styles.cart__item_name}>{item.name}</p>
                            <p className={styles.cart__item_code}>Артикул: {item.item_code}</p>
                            {/* buttons */}
                            <div className={styles.cart__details}>
                                <div className={styles.cart__btn_block}>
                                    
                                    <button 
                                        onClick={() => decreaseItemQnt(item)} 
                                        disabled={item.details.min_order === item.quantity}
                                        className={styles.cart__btn_quantity}
                                    >-</button>
                                    <span className={styles.cart_quantity_label}>{item.quantity} {item.details.type}</span>
                                    <button 
                                        onClick={() => increaseItemQnt(item)} 
                                        className={styles.cart__btn_quantity}
                                    >+</button>
                                
                                </div>
                                <p className={styles.cart__item_price}>{formatPrice(item.details.price * item.quantity, ' ')}
                                        <span> тг</span>
                                </p>
                            </div>
                        </div>
                    </div>
                            
                </section>
            )
}

export default ItemToAdd