import styles from '../../Catalog/OrderDetails/VModal_2Common.module.css'

// Icons
import { ReactComponent as IconBin } from "../../../../assets/img/icons/bin.svg"
// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from '../../../../../../customHooks/useScrollBlock';
import PopupAppearLayout from '../../Layouts/PopupAppearLayout';
// Helmet
import { setDefaultThemeColor } from '../../../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';


const Modal_Loading = () => {
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();
    
    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())

    const closeModal = () => {
        // setTimeout(function(){allowScroll()}, 150);
        defaultThemeColor()
        modal.close()
    }   

    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
                <div className={styles.header_container}>
                    <div className={styles.header_title}>
                        <p>Загрузка...</p>
                    </div>
                    
                    <button
                        className={styles.popup_layout__close}
                        type="button"
                        onClick={closeModal}
                        >
                        &#9587;
                    </button>
                </div>

               
                
                
               <div>
                Загрузка
               </div>
                    
                
                
                    

                
        </PopupAppearLayout>
    )
}

export default Modal_Loading