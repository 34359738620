// separate price by space
export const formatPrice = (price, delimeter) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimeter)
}

export const formatDataCartForDbSave = (itemsInShoppingCart, defaultContact, defaultAddress, deliveryDate, paymentType, defaultComment) => {
    const itemsArray = itemsInShoppingCart.map( (item) => {
        const tempObject =  {
                                "item_code": item.item_code,
                                "quantity": item.quantity,
        }
        if(item.addedSubProduct){
            tempObject.addedSubProduct =    {
                                                "item_code": item.addedSubProduct.item_code,
                                                "quantity": item.addedSubProduct.quantity
                                            }
        }
        return tempObject
    })
    return  {
                "billing": {
                    "first_name": defaultContact.first_name,
                    "last_name": defaultContact.last_name,
                    "address_1": `г. ${defaultAddress.city} ул. ${defaultAddress.street} дом ${defaultAddress.building}, кв/офис ${defaultAddress.apt_office} этаж ${defaultAddress.floor} подъезд ${defaultAddress.entrance}`,
                    "phone": defaultContact.mobile,
                    "address_2": ""
                },
                "line_items": itemsArray,
                "order": {
                    "payment_type": paymentType,
                    "date_delivery": deliveryDate,
                    "customer_note": defaultComment ? defaultComment : ""
                }
            }
}