import styles from './VServicesModalDetails.module.css'

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from "../../../../customHooks/useScrollBlock";
import PopupAppearLayout from "../../common/Modals/Layouts/PopupAppearLayout";

// Icons
import iconCog from "../../assets/img/icons/cog.svg"

const VServicesModalDetails = () => {

    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();
    const closeModal = () => {
      // setTimeout(function(){allowScroll()}, 150);
      allowScroll()
      modal.close()
    } 
    
    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
            <div className={styles.header_container}>
                <div className={styles.header_title}>
                    <p>Услуги подробнее</p>
                </div>
                
                <button
                    className={styles.popup_layout__close}
                    type="button"
                    onClick={closeModal}
                    >
                    &#9587;
                </button>
            </div>

            <div className={styles.login__container}>
                
                <img className={styles.img_under_dev} src={iconCog} alt='under development' />
                <h3 className={styles.title}>Данный раздел находится в разработке</h3>
                <p className={styles.message}>Следите за новостями компании и обновлениями сайта</p>
                <button
                    className={styles.btn_return}
                    type="button"
                    onClick={closeModal}
                    >
                    Закрыть
                </button>
            </div>
        </PopupAppearLayout>
    );   
}

export default VServicesModalDetails;
