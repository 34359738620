import styles from './FilesAccordion.module.css'
import React from 'react'

// ICONS
import download from '../../assets/img/icons/download.svg'
import share from '../../assets/img/icons/share.svg'
// Accordion
import * as Accordion from '@radix-ui/react-accordion';
import {ReactComponent as ArrowRightSmall}  from '../../assets/img/icons/arrow_right_small.svg'

const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className={styles.accordionHeader}>
      <Accordion.Trigger
        className={styles.accordionTrigger}
        {...props}
        ref={forwardedRef}
      >
        <span className={styles.accordionTriggerName}>{children}</span>
        <ArrowRightSmall className={styles.accordionChevron} aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  ));
  
  const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={className}
      {...props}
      ref={forwardedRef}
    >
      <div className={styles.accordionContentText}>{children}</div>
    </Accordion.Content>
  ));

const FilesAccordion = ({ dbArray }) => {
    let i = 0
    return (
            <>
                <Accordion.Root className={styles.accordionRoot} type="single" collapsible="true">
                {Object.entries(dbArray).map( ([index, file]) => {
                    i++
                    return(
                        <Accordion.Item key={file.title} className={styles.accordionItem} value={`item-${i}`}>
                            <AccordionTrigger>{file.title}</AccordionTrigger>
                            <AccordionContent className={styles.accordionContent}>
                            { file.rus && 
                              file.kaz && 
                              file.rus.url_file.trim() !== '' && 
                              file.kaz.url_file.trim() !== ''
                                  ?     <>
                                              {/* kazakh */}
                                              <div className={styles.accordionContentDoc}>
                                                  <div className={styles.accordionContentLeft}>
                                                      <img src={`/assets/files/${file.kaz.thumbnail}`} alt={file.title} />
                                                  </div>
                                                  <div className={styles.accordionContentRight}>
                                                      <p className={styles.language}>Қазақ тілі</p>
                                                      <button className={styles.btn_blue}><img src={download} alt='download icon' />скачать</button>
                                                      <button className={styles.btn_blue}><img src={share} alt='share icon' />копировать ссылку</button>
                                                  </div>
                                              </div>
                                              {/* russian */}
                                              <div className={styles.accordionContentDoc}>
                                                  <div className={styles.accordionContentLeft}>
                                                      <img src={`/assets/files/${file.rus.thumbnail}`} alt={file.title} />
                                                  </div>
                                                  <div className={styles.accordionContentRight}>
                                                      <p className={styles.language}>Русский язык</p>
                                                      <button className={styles.btn_blue}><img src={download} alt='download icon' />скачать</button>
                                                      {/* <button className={styles.btn_blue}><img src={share} alt='share icon' />поделиться</button> */}
                                                  </div>
                                              </div>
                                        </>
                                  :     <div className={styles.accordionContentDoc}>
                                            <div className={styles.accordionContentLeft}>
                                                <img src={`/assets/files/${file.thumbnail}`} alt={file.title} />
                                            </div>
                                            <div className={styles.accordionContentRight}>
                                                <p className={styles.language}>Русский язык / Қазақ тілі</p>
                                                <button className={styles.btn_blue}><img src={download} alt='download icon' />скачать</button>
                                                {/* <button className={styles.btn_blue}><img src={share} alt='share icon' />копировать ссылку</button> */}
                                            </div>
                                        </div>
                            }
                                
                            </AccordionContent>
                        </Accordion.Item>
                    )
                    }) 
                }
                </Accordion.Root>
            </>
  )
}

export default FilesAccordion








