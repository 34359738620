import styles from './VMainTitle.module.css'

import { Link } from "react-router-dom"
import arrowRight from "../../assets/img/icons/arrow_right.svg"

const VMainTitle = ({ title, subtitle, url, fnc }) => {
  return (
      <div className={styles.visitor__title_container}>
        <div className={styles.visitor__title_wrapper}>
            <h2 className={styles.visitor__title__h2}>{title}</h2>
            {url  ? (
                      <Link className={styles.visitor__title_btn_see_more} to={url}>
                        <span>Смотреть все</span>
                        <img src={arrowRight} alt="arrow right" />
                      </Link>) 
                  : fnc ? ( <button className={styles.visitor__title_btn_see_more} onClick={fnc}>
                              <span>Смотреть все</span>
                              <img src={arrowRight} alt="arrow right" />
                            </button>) 
                        : ('')
            }
        </div>
        {subtitle ? <p className={styles.visitor__subtitle}>{subtitle}</p> : ''} 
        
      </div>
  )
}

export default VMainTitle