import styles from './PartAddressContacts.module.css'
// Redux
import { useSelector, useDispatch } from 'react-redux'
// Modal
import Modal_2 from '../../Modal_2';
import VModal_2AddressSelect from './VModal_2AddressSelect';
import VModal_2AddressAddEdit from './VModal_2AddressAddEdit';

import VModal_2ContactsSelect from './VModal_2ContactsSelect';
import VModal_2ContactsAddEdit from './VModal_2ContactsAddEdit';

import useScrollBlock from '../../../../../../customHooks/useScrollBlock';
// Icons
import {ReactComponent as IconPlus} from '../../../../assets/img/icons/plus.svg'
// Helmet
import { setNewThemeColor } from '../../../../../../features/helmet/helmetSlice';

const PartAddressContacts = () => {
    const [blockScroll, allowScroll] = useScrollBlock();
    const dispatch = useDispatch()
    const changeThemeColor = (color) => dispatch(setNewThemeColor(color))

    // REDUX
    const addresses = useSelector(state => state.cart?.addresses);
    const contacts = useSelector(state => state.cart?.contacts);
    const defaultAddress = addresses?.length ? addresses.find( item => item.default === true ) : null
    const defaultContact = contacts?.length ? contacts.find( item => item.default === true ) : null

    // Contacts
    const openSelectContact = () => {
        // setTimeout(function(){}, 150);
        changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_2.open(VModal_2ContactsSelect);
    }

    const openAddContact = () => {
        // setTimeout(function(){}, 150);
        Modal_2.open(VModal_2ContactsAddEdit);
    }

    // Address
    const openSelectAddress = () => {
        // setTimeout(function(){}, 150);
        changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_2.open(VModal_2AddressSelect);
    }

    const openAddAddress = () => {
        // setTimeout(function(){}, 150);
        Modal_2.open(VModal_2AddressAddEdit);
    }

    return (
        <div className={styles.main_container}>
            

            <div className={styles.address_contacts_delivery_container}>
                    <div className={"card__wrapper " + styles.card_wrapper}>
                        <section className={styles.section_block}>
                            <div className={styles.section_part}>
                                <h4 className={styles.title_block}>Контактное лицо</h4>
                                <div className={`card__content ${styles.card__content}`}>
                                    {
                                        defaultContact
                                            ?   <>
                                                    <p className={styles.content_text}>{defaultContact.first_name} {defaultContact.last_name}</p>
                                                    <p className={styles.content_text}>{defaultContact.mobile}</p>
                                                    <div className={styles.btn_change_container}>
                                                        <button type='button' onClick={openSelectContact} className={styles.btn_change_info}>изменить данные</button>
                                                    </div>
                                                </>
                                            :   <button type='button' onClick={openAddContact} className={styles.btn_add_address}>
                                                    <IconPlus className={styles.icon_plus} />
                                                    Добавить контакт
                                                </button>
                                    }
                                    
                                </div>
                            </div>
                        </section>
                    </div>


                    <div className={"card__wrapper " + styles.card_wrapper}>
                        <section className={styles.section_block}>
                            <div className={styles.section_part}>
                                <h4 className={styles.title_block}>Адрес</h4>
                                <div className={`card__content ${styles.card__content}`}>
                                    {
                                        defaultAddress
                                            ?   <>
                                                    <p className={styles.content_text}>{defaultAddress.city}, {defaultAddress.street} {defaultAddress.building}</p>
                                                    <p className={styles.content_text}>
                                                        {defaultAddress.apt_office ? `кв/офис ${defaultAddress.apt_office} `: ""} 
                                                        {defaultAddress.entrance || defaultAddress.floor ? "(" : ""}
                                                            {defaultAddress.entrance ? `подъезд ${defaultAddress.entrance}`: ""} 
                                                        {defaultAddress.entrance && defaultAddress.floor ? ", " : ""}
                                                            {defaultAddress.floor ? `этаж ${defaultAddress.floor} `: ""}
                                                        {defaultAddress.entrance || defaultAddress.floor ? ")" : ""}
                                                    </p>   
                                                    <p className={`${styles.content_text} ${styles.comment_text}`}>{defaultAddress.comment}</p>                                             
                                                    <div className={styles.btn_change_container}>
                                                        <button type='button' onClick={openSelectAddress} className={styles.btn_change_info}>изменить адрес</button>
                                                    </div>
                                                </>
                                            :   <button type='button' onClick={openAddAddress} className={styles.btn_add_address}>
                                                    <IconPlus className={styles.icon_plus} />
                                                    Добавить адрес
                                                </button>
                                    }
                                </div>
                            </div>
                                
                        </section>
                    </div>
            </div>


        </div>
    )
}

export default PartAddressContacts