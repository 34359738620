import styles from './VModal_DeliverySchedule.module.css'
import buttons from '../../Buttons.module.css'
import { useState } from 'react';

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from '../../../../../customHooks/useScrollBlock';
import PopupAppearLayout from '../Layouts/PopupAppearLayout';
// Helmet
import { setDefaultThemeColor } from '../../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';
// Icons
import icon_close from "../../../assets/img/icons/close.svg"

const VModal_DeliverySchedule = () => {
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();
    
    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())

    const closeModal = () => {
        // setTimeout(function(){allowScroll()}, 150);
        defaultThemeColor()
        allowScroll()
        modal.close()
    }

    const deliverySchedule = {
        "Понедельник": ["п. Алатау", "с. Бесагаш", "с. Белбулак", "с. Гульдала", "с. Панфилов", "г. Талгар", "с. Туздыбастау", "с. Талдыбулак"],
        "Вторник": ["г. Акжар", "с. Даму", "с. Жетыген", "к.г. Тау - самал"],
        "Среда": ["г. Каскелен", "с. Коксай", "г. Акжар", "к.г. Тау - самал", "с. Жанатурмыс", "с. Кыргауылды"],
        "Четверг": ["с. Даму", "г. Жетыген", "с. Заречный", "г. Кунаев", "к.г. Тау - самал", "с. Акжар", "с. Даулет"],
        "Пятница": ["п. Водник", "п. Комсомол", "с. Ашибулак", "71 переезд", "с. Кошмамбет", "с. Мерей", "п. Боралдай", "к.г. Тау - самал", "г. Акжар"],
        "Суббота": ["г. Акжар", "к.г. Тау - самал", "с. Жанатурмыс"]
    };
    
    const BlocksByDay = () => {
        return Object.entries(deliverySchedule).map(([day, locations]) => {
            const isFoundLocation = locations.find( (location) => location.toLowerCase().includes(searchQuery.toLowerCase()) && searchQuery!=='' ? location : false);
            return  <div 
                        key={day} 
                        className=  {`${styles.day_block} ${isFoundLocation 
                                                            ? styles.active 
                                                            :   (searchQuery !== '' 
                                                                    ? styles.inactive
                                                                    : ''
                                                                )
                                                        }`
                                    }
                    >
                        <h4 className={styles.day_title}>{day}</h4>
                        <ul className={styles.list_block}>
                            {
                                locations.map( (location) => (
                                    <li 
                                        key={location} 
                                        className={`${styles.list_item} ${isFoundLocation === location
                                                                            ? styles.active
                                                                            : styles.inactive
                                                                        }`}
                                    >
                                        {location}
                                    </li>   
                                ))
                            }
                        </ul>
                    </div>
        });
    }

    const [searchQuery, setSearchQuery] = useState('');
  
    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
            <div className={styles.container}>
                <div className={styles.nav}>
                    <h3 className={styles.title}>График доставки</h3>
                    <input 
                        type="text" 
                        placeholder="Поиск..." 
                        value={searchQuery} 
                        onChange={handleInputChange}
                        className={styles.search}
                    />
                    <button
                        className={"selectDisable " + styles.popup_layout__close}
                        type="button"
                        onClick={closeModal}
                        >
                        <img src={icon_close} alt='close button' />
                    </button>
                </div>

                <div className={styles.deliveryContainer}>
                    <BlocksByDay />
                </div>

                <div className={styles.btn_container}>
                    <button className={buttons.btn_solid_blue} onClick={closeModal}>закрыть</button>
                </div>
            </div>
        </PopupAppearLayout>

    )
}

export default VModal_DeliverySchedule