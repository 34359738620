import { useState, useEffect, useMemo } from 'react';

export const useMedia = (query) => {
  const matchesInitial = useMemo(() => window.matchMedia(query).matches, [query]);
  const [matches, setMatches] = useState(matchesInitial);

  useEffect(() => {
    const media = window.matchMedia(query);
    const handleChange = (event) => {
      setMatches(event.matches);
    };

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    media.addEventListener('change', handleChange);

    return () => {
      media.removeEventListener('change', handleChange);
    };
  }, [query, matches]);

  return matches;
};