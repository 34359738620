import styles from './VMainNews.module.css'
import VMainTitle from "../VMainTitle"
// Modal
import useScrollBlock from '../../../../../customHooks/useScrollBlock';
import VNewsModal from '../../news/VNewsModal';
import Modal_1 from '../../../common/Modals/Modal_1';

//Truncate
import { truncateText } from '../../../../../customHooks/truncateText';

// use Media
import { useMedia } from '../../../../../customHooks/useMedia';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

const db =  [
    {
      date: '01 февраля 2024',
      img_url: 'pic_1.png',
      title: "Повышение цен", 
      content: "ТОО «КОМЕТА» вынуждена поднять цену на продукцию. По причине увеличения стоимости сырья которое необходимо для выпуска бутилированной воды (крышки, этикетки, пэт преформы и прочее). Это связано с тем, что за 2 квартал 2021 года на мировом рынке произошел значительный рост полиэтилена (примерно на 30-35%). Надеемся на понимание и дальнейшее сотрудничество!"
    },
    {
      date: '01 января 2024',
      img_url: 'pic_1.png',
      title: 'Скоро акция',
      content: 'ТОО «КОМЕТА» вынуждена поднять цену на продукцию. По причине увеличения стоимости сырья которое необходимо для выпуска бутилированной воды (крышки, этикетки, пэт преформы и прочее). Это связано с тем, что за 2 квартал 2021 года на мировом рынке произошел значительный рост полиэтилена (примерно на 30-35%). Надеемся на понимание и дальнейшее сотрудничество!',
    },
    {
      date: '01 декабря 2023',
      img_url: 'pic_1.png',
      title: 'Новый дизайн',
      content: 'ТОО «КОМЕТА» вынуждена поднять цену на продукцию. По причине увеличения стоимости сырья которое необходимо для выпуска бутилированной воды (крышки, этикетки, пэт преформы и прочее). Это связано с тем, что за 2 квартал 2021 года на мировом рынке произошел значительный рост полиэтилена (примерно на 30-35%). Надеемся на понимание и дальнейшее сотрудничество!',
    },
    {
      date: '01 декабря 2023',
      img_url: 'pic_1.png',
      title: 'Новый дизайн',
      content: 'ТОО «КОМЕТА» вынуждена поднять цену на продукцию. По причине увеличения стоимости сырья которое необходимо для выпуска бутилированной воды (крышки, этикетки, пэт преформы и прочее). Это связано с тем, что за 2 квартал 2021 года на мировом рынке произошел значительный рост полиэтилена (примерно на 30-35%). Надеемся на понимание и дальнейшее сотрудничество!',
    }
  ]

const Box = ({ data }) => {
    const [blockScroll, allowScroll] = useScrollBlock();
  
    const openNews = () => {
        // setTimeout(function(){}, 150);
        blockScroll()
        Modal_1.open(VNewsModal, {data});
    }

    return(
                <div onClick={openNews} className={"card__wrapper " + styles.card__wrapper}>
                    <div className={"card__content " + styles.card__content}>
                        <div className={styles.news_header}>
                            <p>{data.date}</p>
                            <div className={styles.see_more_container}>
                                <button className={styles.see_more_btn} >Подробнее</button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="8" viewBox="0 0 5 8" fill="none">
                                    <path d="M1.28027 7.13574L4.20027 4.21582L1.28027 1.2959" stroke="#1F85C5" strokeWidth="0.729984" strokeLinecap="round"/>
                                </svg>
                            </div>
                            
                        </div>
                        <h3 className={styles.news_title}>{data.title}</h3>
                        <p>{truncateText(data.content, 130)}</p>
                    </div>
                    <div className={styles.news_img_container}>
                        <img src={'assets/img/by_page/news/' + data.img_url} alt={data.title} />
                    </div>
                    
                </div>
    )
}

const VMainNews = () => {
  const isMoreThan1100 = useMedia('(min-width: 1100px)');
  const isMoreThan768 = useMedia('(min-width: 768px)');

  return (
    <>
        <section className={styles.news}>
        <VMainTitle title='Новости' subtitle='Узнайте последние новости о нашей компании' url='news' />
            <div className={styles.wrapper}>
            
            <Swiper
                    loop={true}
                    initialSlide={1}
                    slidesPerView={isMoreThan1100 ? 3 : isMoreThan768 ? 2 : 1}
                    centeredSlides={true}
                    allowTouchMove={true}
                    pagination={{
                        clickable: true
                      }  
                    }
                    modules={[Pagination, Autoplay]}
                    className="newsSwiper"
                    autoplay={{
                      delay: 4000
                    }}
                >
                  
                    {db?.map( (item, k) => {
                            return (
                              <SwiperSlide key={k} >
                                  <Box data={item}  />
                              </SwiperSlide>                              
                            )
                        })
                    }                      
                </Swiper>
            
              
            </div>
        </section>
    </>
  )
}

export default VMainNews