import styles from './CartTotal.module.css'
// functions
import { formatPrice } from '../../../../../../customHooks/functions';

const CartTotal = ({totalPriceShoppingCart}) => {
  return (
    <div className={styles.orderDetails_cartTotal_container}>
        <p className={styles.orderDetails_cartTotal_title}>Итого:</p>
        <div className={styles.orderDetails_cartTotal_block}>
            <p className={styles.orderDetails_cartTotal_text}>Стоимость товара:</p>
            <p className={styles.orderDetails_cartTotal_text}>{formatPrice(totalPriceShoppingCart, ' ')} тг</p>
        </div>
        
    </div>
  )
}

export default CartTotal