import styles from './VAboutWater.module.css'
import VTitle from '../../common/VTitle'

import pic_1 from "../../assets/img/by_page/about_water/pic_1.png"

const VAboutWater = () => {
  return (
    <>
      <VTitle title="О воде" subtitle='Что такое озонированная вода?' />
      <div className={`${styles.wrapper} ${styles.left}`}>
              <div className={"card__wrapper " + styles.card__wrapper}>
                  <div className={"card__content " + styles.card__content_1}>
                      <h3 className="card__title">Качество</h3>
                      <p>Вода декларирована в ТОО “ҒЗО «Алматы-Стандарт» при Академии питания Республики Казахстан. Качество поставляемой воды «Calipso» соответствует требованиям Технического регламента Таможенного Союза, Единым санитарно-эпидемиологическим нормам Таможенного Союза (Свидетельство о государственной регистрации № KZ.16.01.96.006.Е.003134.02.11 от 23.02.2011 г.), требованиям отраслевого стандарта СТ 27083-1910-ТОО-02-2011.</p>
                          
                  </div>
              
                  <img className={styles.about_water_img} src={pic_1} alt='Quality' />
                  
                  <div className={"card__content " + styles.card__content_2}>
                      <h3 className="card__title">Озонирование</h3>
                      <p>Однако реальной альтернативой применению хлора может служить озонирование. Микрофолокуляция озоном широко практикуется в Европе, в США и др. странах. Хлорирование воды там запрещено, так как соединения хлора вредны для здоровья людей.</p>
                      <p>Во многоступенчатой системе очистки Компания «Комета» использует метод озонирования, что позволяет добиваться полной бактериальной очистки без применения хлора. Озон, озонированная жидкость играет биологическое и физиологическое действие на развитие и жизнедеятельность всего живого, имеющих способность активизировать и стимулировать биологические клетки и их системы, а также ингибировать вирусы, бактерии, споровые образования с задержанием их течения физиологических и физико-химических процессов с подавлением их инфекционной активности.</p>
                  </div>
              </div>
              
        </div>
    </>
  )
}

export default VAboutWater
