import styles from './VServices.module.css'
import VTitle from '../../common/VTitle'
// Modal
import Modal_1 from '../../common/Modals/Modal_1';
import VServicesModalDetails from './VServicesModalDetails';
import VServicesModalOrder from './VServicesModalOrder';
import useScrollBlock from '../../../../customHooks/useScrollBlock';

// Pictures
import pic_delivery from '../../assets/img/by_page/services/delivery.png'
import pic_repair from '../../assets/img/by_page/services/repair.png'
import pic_cleaning from '../../assets/img/by_page/services/cleaning.png'
import pic_rent from '../../assets/img/by_page/services/rent.png'
import pic_return from '../../assets/img/by_page/services/return.png'
import pic_recycle from '../../assets/img/by_page/services/recycle.png'



const Box = ({ title, img }) => {
  const [blockScroll, allowScroll] = useScrollBlock();

  const openDetails = () => {
    // setTimeout(function(){}, 150);
    blockScroll()
    Modal_1.open(VServicesModalDetails);
  }
  const openOrder = () => {
    // setTimeout(function(){}, 150);
    blockScroll()
    Modal_1.open(VServicesModalOrder);
  }
  return(
              <div className={"card__wrapper " + styles.card__wrapper}>
                  <div className={"card__content " + styles.card}>
                      <h3 className={styles.title}>{title}</h3>
                      <img className={styles.service_img} src={img} alt = {title} />

                      <div className={styles.btn_block}>
                          <button onClick={openDetails} className={styles.btn_blue}>Подробнее</button>
                          <button onClick={openOrder} className={styles.btn_blue}>Заказать</button>
                      </div>

                  </div>
              </div>
  )
}

const VServices = () => {
  return (
    <>
      <VTitle title="Услуги" subtitle="мы с готовностью предоставляем  спектр качественных услуг для удовлетворения ваших потребностей" />

      <div className={styles.wrapper}>

          <Box title="Служба доставки"  img={pic_delivery} />
          <Box title="Ремонт питьевых аппаратов"  img={pic_repair} />
          <Box title="Санитарная обработка и промывка аппаратов"  img={pic_cleaning} />
          <Box title="Аренда складских помещений"  img={pic_rent} />
          <Box title="Возврат тары"  img={pic_return} />
          <Box title="Реализация вторичного сырья"  img={pic_recycle} />
          
      </div>
    </>
  )
}

export default VServices
