import styles from './UnderDevelopment.module.css'
// Icons
import iconCog from "../visitor/assets/img/icons/cog.svg"

const UnderDevelopment = () => {
  return (
    <>
        <img className={styles.img_under_dev} src={iconCog} alt='under development' />
        <h3 className={styles.title}>Данный раздел находится в разработке</h3>
    </>
  )
}

export default UnderDevelopment