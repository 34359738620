import styles from './VPayment.module.css'
import buttons from '../../common/Buttons.module.css'

import { Link, useSearchParams } from 'react-router-dom'
import { useCheckOrderGetStatusMutation } from '../../../../features/cart/cartApiSlice'
import { useEffect, memo } from 'react'

import { formatPrice } from '../../../../customHooks/functions'


const VPayment = (type) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const InvId = searchParams.get('InvId');
  const OutSum = searchParams.get('OutSum');
  
    return  <div className={styles.mainContainer}>

                  <h3 className={styles.header}>Информация о заказе</h3>
                  <div className={styles.block}>
             

                      {/* ORDER NUMBER */}
                      <div className={styles.orderNumberBlock}>
                            <p className={styles.orderNumberTitle}>{InvId}</p>
                            
                              <p className={styles.orderStatusPending}>Заказ отменен</p>
                              {/* { data.statusOrder === 'completed' ? <p className={styles.orderStatusCompleted}>Оплачено</p> : '' } */}
                            
                      </div>

                      {/*  */}
                      <div className={styles.row}>
                          <p className={`${styles.text_left} ${styles.text_default}`}>Тип оплаты</p>
                          <p className={`${styles.text_right} ${styles.text_default}`}>RoboKassa</p>
                      </div>
                      <div className={styles.row}>
                          <p className={`${styles.text_left} ${styles.text_default}`}>Итого</p>
                          <p className={`${styles.text_right} ${styles.text_default}`}>{Math.round(parseFloat(OutSum))} ₸</p>
                      </div>
                  </div>

                  <div className={buttons.btn_container}>
                    <Link
                        className={buttons.btn_solid_blue}
                        to='/'
                    >
                        Главная
                    </Link>
                </div>
                  
                  
              </div>  
}
export default memo(VPayment)