import styles from './PartComment.module.css'
// Redux
import { useDispatch, useSelector } from 'react-redux'
// Modal
import useScrollBlock from '../../../../../../customHooks/useScrollBlock';
import Modal_2 from '../../Modal_2';
import VModal_2CommentSelect from './VModal_2CommentSelect';
import VModal_2CommentAddEdit from './VModal_2CommentAddEdit';
// Icons
import {ReactComponent as IconPlus} from '../../../../assets/img/icons/plus.svg'
// Helmet
import { setNewThemeColor } from '../../../../../../features/helmet/helmetSlice';

const PartComment = () => {
    const comments = useSelector(state => state.cart?.order?.comments);
    const defaultComment = comments?.length ? comments.find( item => item.default === true ) : null

    const [blockScroll, allowScroll] = useScrollBlock();
    const dispatch = useDispatch()
    const changeThemeColor = (color) => dispatch(setNewThemeColor(color))

     // Comments
     const openSelectComment = () => {
        // setTimeout(function(){}, 150);
        changeThemeColor({color: '#5c5d5e'})
        blockScroll()
        Modal_2.open(VModal_2CommentSelect);
    }
    const openAddEditComment = () => {
        // setTimeout(function(){}, 150);
        Modal_2.open(VModal_2CommentAddEdit);
    }

    return (
            <div className={"card__wrapper " + styles.card_wrapper}>
                <section className={styles.section_block}>
                    <div className={styles.section_part}>
                        <h4 className={styles.title_block}>Комментарий</h4>
                        <div className={`card__content ${styles.card__content}`}>
                            {
                                defaultComment
                                    ?   <>                                           
                                            <p className={`${styles.content_text} ${styles.comment_text}`}>{defaultComment.text}</p>
                                            <div className={styles.btn_change_container}>
                                                <button type='button' onClick={openSelectComment} className={styles.btn_change_info}>изменить</button>
                                            </div>
                                        </>
                                    :   <button type='button' onClick={openAddEditComment} className={styles.btn_add_comment}>
                                            <IconPlus className={styles.icon_plus} />
                                            Добавить комментарий
                                        </button>
                            }
                        </div>
                    </div>
                        
                </section>
            </div>
  )
}

export default PartComment