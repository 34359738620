import { useDispatch, useSelector } from 'react-redux';
import styles from './PartCatalog.module.css'
// Icons
import iconCheck from "../../../assets/img/icons/check.svg"
import {ReactComponent as IconMin} from "../../../assets/img/icons/min.svg"
import {ReactComponent as IconBottle} from "../../../assets/img/icons/bottle.svg"
// Redux
import { addToCart, removeFromCart} from "../../../../../features/cart/cartSlice";

// use Media
import { useMedia } from '../../../../../customHooks/useMedia'
// functions
import { formatPrice } from '../../../../../customHooks/functions';
// Modal
import Modal_2 from '../Modal_2';
import VModal_2AddSub18_9 from './OrderDetails/VModal_2AddSub18_9';

const PartCatalog = ({dbUnsortedData, dbSortedData, category}) => {
    const dispatch = useDispatch()
    const isLarge1200 = useMedia('(min-width: 1200px)');

    const itemsInShoppingCart = useSelector(state => state.cart.cart);
    const addItem = (selectedItem) =>   { 
                                            const subProduct = selectedItem?.sub_product_code 
                                                                        ? dbUnsortedData.find(db_item => db_item.item_code === selectedItem.sub_product_code)
                                                                        : false
                                            if(subProduct){
                                                Modal_2.open(VModal_2AddSub18_9, {selectedItem: selectedItem, subProduct: subProduct});
                                            }else{
                                                dispatch(addToCart(selectedItem))
                                            }
                                            
                                        }
    const removeItem = (selectedItem) =>{ 
                                    dispatch(removeFromCart(selectedItem)) 
                                }
    
    const ItemCode  = ({item}) => <p className={styles.item__item_code}>Артикул: {item.item_code}</p>
    const ItemTitle = ({item}) => <h6 className={styles.item_name}>{item.name}</h6>
    const ItemTags  = ({item}) => ( <div className={styles.item_order_tags}>
                                        <div className={`${styles.order_detail} ${styles.min_order}`}>
                                            <IconMin />
                                            <p>мин заказ {item.details.min_order} {item.details.type}</p>
                                        </div>
                                        <div className={`${styles.order_detail} ${styles.items_in_package} ${item.details.type === 'шт' ? styles.hide : ''}`}>
                                            <IconBottle />
                                            <p>{item.details.number_of_items_in_package}шт/{item.details.type}</p>
                                        </div>
                                    </div>)
    const ItemImg   = ({item}) => ( <div className={styles.item_order_img_block}>
                                        <img src={`/assets/img/catalog/${item.image_url}?v=1.01`} alt="item pic"></img>
                                    </div>)
    const ItemPrice = ({item}) => ( <p className={styles.item_price}>{formatPrice(item.details.price, ' ')}
                                        <span> тг/{item.details.type}</span>
                                    </p> )
    const ItemAddBtn = ({item}) => {
                                    const itemIsAlradyAdded = isItemInShoppingCart(item)
                                    return itemIsAlradyAdded
                                            ? <button onClick={() => removeItem(item)} className={styles.item_btn_green}>
                                                <img className={styles.iconCheck} src={iconCheck} alt='item checked' />
                                                Добавлено
                                                </button>
                                            : <button onClick={() => addItem(item)} className={styles.item_btn_blue}>+ Добавить</button> }
                 
    const isItemInShoppingCart = (item) =>  itemsInShoppingCart.find(
                                                item_in_cart => 
                                                    (item.item_code === item_in_cart.item_code)
                                                    ||
                                                    (item_in_cart && item_in_cart.addedSubProduct && item_in_cart.addedSubProduct.item_code === item.item_code)
                                            )
    
    const ItemDesktop = ({item}) => {
                            return  <div className={`${styles.item__block_many} ${item.outOfStock && item.outOfStock === true ? styles.outOfStock : ''}`}>
                                        <ItemCode item={item} />
                                        <ItemTitle item={item} />

                                        <div className={styles.details_img_block}>
                                            <ItemTags item={item} />
                                            <ItemImg item={item} />
                                        </div>

                                        <ItemPrice item={item} />
                                        <ItemAddBtn item={item} />
                                    </div>}
    const ItemMobile = ({item}) => {
                                                return  <div className={`${styles.item__block_one} ${item.outOfStock && item.outOfStock === true ? styles.outOfStock : ''}`}>
                                                            <div className={styles.item_img_container}>
                                                                <ItemImg item={item} />
                                                            </div>
                                                            <div className={styles.item_content_container}>
                                                                <div>
                                                                    <ItemTitle item={item} />
                                                                    <ItemCode item={item} />
                                                                </div>
                                                                <div className={styles.price_add_btn_block}>
                                                                    <ItemPrice item={item} />
                                                                    <ItemAddBtn item={item} />
                                                                </div>
                                                                <ItemTags item={item} />
                                                            </div>
                                                        </div>}
    
    const filtered_items = Object.entries(dbSortedData[category]).map( ([k,item]) => {
        return (
            <section key={k} className={styles.category__block}>
                <h5 className={styles.category__title}>{k}</h5>
                <div className={styles.items__wrapper}>
                    
                    {
                        item.map( (item) => {
                        
                        return  <div key={item.item_code} className={styles.item__container}>        
                                    {isLarge1200
                                        ?   <ItemDesktop item={item} />
                                        :   <ItemMobile item={item}  />   
                                    }
                                    
                                </div>
                        }) 
                    }




                </div>
            </section>
        )
    })
    return filtered_items
}

export default PartCatalog