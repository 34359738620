import { useModalWindow } from "react-modal-global";
import styles from "./PopupAppearLayout.module.css";

import useScrollBlock from "../../../../../customHooks/useScrollBlock";
// Helmet
import { setDefaultThemeColor, setNewThemeColor } from '../../../../../features/helmet/helmetSlice';
import { useDispatch } from "react-redux";

function PopupAppearLayout(props) {
  const modal = useModalWindow();
  const [blockScroll, allowScroll] = useScrollBlock();

  const dispatch = useDispatch();

  const defaultThemeColor = () => dispatch(setDefaultThemeColor())

  const closeModal = () => {
    allowScroll()
    defaultThemeColor()
    modal.close()
  }

  return (
    <>
      <div className={styles.wrapper}>

            <div className={styles.backdrop} onClick={closeModal}></div>
            <div className={styles.main_container + ' ' + props.classNameLayout}>
              <div className={styles.popup_layout__container}>
                {/* {modal.params.closable && (
                  <button
                    className={styles.popup_layout__close}
                    type="button"
                    onClick={closeModal}
                  >
                    &#9587;
                  </button>
                )} */}

                <div className={styles.popup_layout__inner}>{props.children}</div>
              </div>
            </div>

      </div>
    </>
  );
}

export default PopupAppearLayout;
