import styles from './PartPaymentMethod.module.css'
import { useCallback, useEffect, useState } from 'react';

// Radix UI
import * as RadioGroup from '@radix-ui/react-radio-group';
// redux
import { useDispatch } from 'react-redux';
import { setPaymentMethod } from '../../../../../../features/cart/cartSlice';

const PartPaymentMethod = () => {
    const [paymentType, setPaymentType] = useState('RoboKassa')

    // redux
    const dispatch = useDispatch()
    const savePaymentType = useCallback((paymentType) => dispatch(setPaymentMethod(paymentType)), [dispatch])
    
    useEffect( () => {
        savePaymentType(paymentType)
}, [savePaymentType, paymentType])

    return (
        <div className={"card__wrapper " + styles.card_wrapper}>
            <section className={styles.section_block}>
                        <h4 className={styles.title_block}>Способ оплаты</h4>
                        <RadioGroup.Root 
                                className={styles.RadioGroupRoot} 
                                // defaultValue="RoboKassa" 
                                aria-label="select item"
                                onValueChange={setPaymentType}
                                value={paymentType}
                                name='delivery_type'
                            >
                                
                                {/* RoboKassa */}
                                <div className={`card__content ${styles.card__content}`}>
                                    <div className={styles.radioGroupBlock}>
                                        <RadioGroup.Item className={styles.radioGroupItem} value="RoboKassa" id="paymentRobokassa">
                                            <RadioGroup.Indicator className={styles.radioGroupIndicator} />
                                        </RadioGroup.Item>
                                        <label className={styles.label} htmlFor="paymentRobokassa">
                                            <span className={styles.date}>RoboKassa</span>
                                        </label>
                                    </div>
                                </div>
                                
                                {/* Kaspi */}
                                {/* <div className={`card__content ${styles.card__content}`}>
                                    <div className={styles.radioGroupBlock}>
                                        <RadioGroup.Item className={styles.radioGroupItem} value="Kaspi" id="paymentKaspi">
                                            <RadioGroup.Indicator className={styles.radioGroupIndicator} />
                                        </RadioGroup.Item>
                                        <label className={styles.label} htmlFor="paymentKaspi">
                                            <span className={styles.date}>Kaspi</span>
                                        </label>
                                    </div>
                                </div> */}
                            
                                {/* Cash */}
                                {/* <div className={`card__content ${styles.card__content}`}>
                                    <div className={styles.radioGroupBlock}>
                                        <RadioGroup.Item className={styles.radioGroupItem} value="Наличные" id="paymentCash">
                                            <RadioGroup.Indicator className={styles.radioGroupIndicator} />
                                        </RadioGroup.Item>
                                        <label className={styles.label} htmlFor="paymentCash">
                                            <span className={styles.date}>Наличные</span>
                                        </label>
                                    </div>
                                </div> */}
                            
                        </RadioGroup.Root>

            </section>
        </div>
  )
}

export default PartPaymentMethod