import styles from './VModalContacts.module.css'
// Icons
import {ReactComponent as IconClose} from '../../../assets/img/icons/close.svg';
import {ReactComponent as IconLogo} from '../../../assets/img/logo/logo.svg';
import {ReactComponent as MapImage} from '../../../assets/img/modals/contacts/map.svg'

import {ReactComponent as IconPhone} from '../../../assets/img/icons/phone.svg'
import {ReactComponent as IconEmail} from '../../../assets/img/icons/email.svg'

import iconTelegram from '../../../assets/img/icons/companies/telegram.svg'
import iconWhatsApp from '../../../assets/img/icons/companies/whatsapp.svg'

import mapYandex from '../../../assets/img/icons/companies/yandex.svg'
import map2gis from '../../../assets/img/icons/companies/2gis.svg'
import mapGoogle from '../../../assets/img/icons/companies/google.svg'

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from "../../../../../customHooks/useScrollBlock";
import PopupAppearLayout from "../Layouts/PopupAppearLayout";

// Helmet
import { setDefaultThemeColor } from '../../../../../features/helmet/helmetSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// Redux
import { changeStatusWhatsAppToExisting } from '../../../../../features/details/detailsSlice';
import { useUpdateWhatsAppCountMutation } from '../../../../../features/details/detailsApiSlice';

const VModalContacts = () => {
        const modal = useModalWindow();
        const [blockScroll, allowScroll] = useScrollBlock();

        const dispatch = useDispatch()
        const defaultThemeColor = () => dispatch(setDefaultThemeColor())

        const closeModal = () => {
            // setTimeout(function(){allowScroll()}, 150);
            defaultThemeColor()
            allowScroll()
            modal.close()
        }   

        const savedWhatsApp = useSelector(state => state.details.whatsapp);

        const [updatekWhatsAppCount, {
            data,
            isLoading, 
            isSuccess, 
            isError, 
            error
        }] = useUpdateWhatsAppCountMutation()
        
        const handleWhatsApp = (e) => {
            // send whatsapp
            if(savedWhatsApp.status === 'new'){
                dispatch(changeStatusWhatsAppToExisting())
                updatekWhatsAppCount({currentWhatsapp: savedWhatsApp.number})
            }
        }
        
                    
        return (
            <PopupAppearLayout classNameLayout={styles.popup__layout}>
                <div className={"card__wrapper " + styles.card__wrapper}>

                        <div className={styles.header_container}>
                            <IconLogo className={styles.logo} />
                            
                            <button
                                className={styles.popup_layout__close}
                                type="button"
                                onClick={closeModal}
                                >
                                <IconClose />
                            </button>
                        </div>

                        <div className={styles.contacts__container}>
                            {/* Left */}
                            <div className={styles.left_part}>
                                {/* ГРАФИК РАБОТЫ */}
                                <div className={styles.schedule_social_container}>
                                    <div className={styles.schedule_container}>
                                        <h4 className={styles.schedule_title}>График работы</h4>
                                        <p><span className={styles.day_of_the_week_label}>пн-сб:</span> <span className={styles.day_of_the_week_text}>9:00 - 18:00</span></p>
                                        <p><span className={styles.day_of_the_week_label}>вс:</span> <span className={styles.day_of_the_week_text}>выходной</span></p>
                                    </div>
                                    <div className={styles.social_container}>
                                        
                                        <Link to='' onClick={ e => e.preventDefault()} className={styles.btn_telegram + " disabled"}>
                                            <img src={iconTelegram} alt='telegram' />
                                            Telegram
                                        </Link>
                                        <Link to={"https://wa.me/" + savedWhatsApp.number} onClick={handleWhatsApp} className={styles.btn_whatsapp}>
                                            <img className={styles.logo__btn} src={iconWhatsApp} alt='logo instagram' />
                                            WhatsApp
                                        </Link>

                                    </div>
                                </div>
                                {/* Для заказа */}
                                <div  className={styles.phone_container}>
                                    <h3 className={styles.phone_title}>Для заказа</h3>
                                    <Link to='tel:+77719292288,,1' className={styles.contact_container}>
                                        <p className={styles.contact_label}>Новый</p>
                                        <div className={styles.contact_field}>
                                            {/* <IconPhone className={styles.contact_svg_phone}/> */}
                                            <p className={styles.contact_mobile}>+7 (771) 929-22-88 (вн.1)</p>
                                        </div>
                                    </Link>
                                    <Link to='tel:+77719292288,,2' className={styles.contact_container}>
                                        <p className={styles.contact_label}>Повторно</p>
                                        <div className={styles.contact_field}>
                                            {/* <IconPhone className={styles.contact_svg_phone}/> */}
                                            <p className={styles.contact_mobile}>+7 (771) 929-22-88 (вн.2)</p>
                                        </div>
                                    </Link>
                                    
                                </div>
                                {/* ТЕЛЕФОН */}
                                <div  className={styles.phone_container}>
                                    <h3 className={styles.phone_title}>Телефон</h3>
                                    <Link to='tel:+77719292288' className={styles.contact_container}>
                                        <p className={styles.contact_label}>Call-центр</p>
                                        <div className={styles.contact_field}>
                                            {/* <IconPhone className={styles.contact_svg_phone}/> */}
                                            <p className={styles.contact_mobile}>+7 (771) 929-22-88</p>
                                        </div>
                                    </Link>
                                    <Link to='tel:+77719292288,,5' className={styles.contact_container}>
                                        <p className={styles.contact_label}>Бухгалтерия</p>
                                        <div className={styles.contact_field}>
                                            {/* <IconPhone className={styles.contact_svg_phone}/> */}
                                            <p className={styles.contact_mobile}>+7 (771) 929-22-88 (вн.5)</p>
                                        </div>
                                    </Link>
                                    
                                </div>
                                {/* ПОЧТА */}
                                <div className={styles.email_container}>
                                    <h3 className={styles.email_title}>Почта</h3>

                                    <div className={styles.contact_container}>
                                        <p className={styles.contact_label}>Отдел продаж</p>
                                        <div className={styles.contact_field}>
                                            {/* <IconEmail className={styles.contact_svg_email}/> */}
                                            <p className={styles.contact_email}>sales@calipso.kz</p>
                                        </div>
                                    </div>

                                    <div className={styles.contact_container}>
                                        <p className={styles.contact_label}>Офис</p>
                                        <div className={styles.contact_field}>
                                            {/* <IconEmail className={styles.contact_svg_email}/> */}
                                            <p className={styles.contact_email}>office@calipso.kz</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Right */}
                            <div className={styles.right_part}>
                                <div className={styles.map_container}>
                                    <MapImage className={styles.map} />
                                </div>
                                <div className={styles.address_container}>
                                    <h3 className={styles.address_title}>Построить маршрут</h3>
                                    <p className={styles.address_address}>Алматы, ул. Намаганская, д43</p>
                                    <div className={styles.address_btn_block}>
                             
                                        <Link to='https://2gis.kz/almaty/geo/9429940000796982' target='_blank' className={styles.address_btn}>
                                            <img src={map2gis} alt='map' />
                                            2gis
                                        </Link>
                                        <Link to='https://yandex.ru/maps/?whatshere%5Bzoom%5D=18&whatshere%5Bpoint%5D=76.984536,43.344389' target='_blank' className={styles.address_btn}>
                                            <img src={mapYandex} alt='map' />
                                            Yandex
                                        </Link>
                                        <Link to='https://maps.app.goo.gl/xs79mVqP8DAv7Wrr8?g_st=iw' target='_blank' className={styles.address_btn}>
                                            <img src={mapGoogle} alt='map' />
                                            Google
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>  
                
                </div>
            </PopupAppearLayout>
        );         
}

export default VModalContacts