import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const catalogAdapter = createEntityAdapter({})

const initialState = catalogAdapter.getInitialState()

export const catalogApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // getCatalog: builder.query({
        //     query: () => '/catalog',
        //     validateStatus: (response, result) => {
        //         return response.status === 200 && !result.isError
        //     },
        //     keepUnusedDataFor: 5,
        //     providesTags: [{ type: 'Catalog', id: 'LIST'}]
        // }),
        getCatalog: builder.mutation({
            query: initialOrder => ({
                url: '/catalog',
                method: 'POST',
                body: {
                    ...initialOrder,
                },
            }),
        }),
    }),
})


export const {
    useGetCatalogMutation
} = catalogApiSlice