import styles from './PayButtonToServer.module.css'
import { useCallback, useEffect, useState } from 'react';

// RTK Query
import { formatDataCartForDbSave } from '../../../../../customHooks/functions';
import { useCreateOrderMutation } from '../../../../../features/cart/cartApiSlice';
// Modal
import Modal_2 from '../Modal_2';
import Modal_Loading from './Notifications/Modal_Loading';
import useScrollBlock from '../../../../../customHooks/useScrollBlock';
// Helmet
import { setNewThemeColor } from '../../../../../features/helmet/helmetSlice';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { emptyCart } from '../../../../../features/cart/cartSlice';

const PayButtonToServer = () => {
    const [addNewOrder, {
        data,
        isLoading, 
        isSuccess, 
        isError, 
        error
    }] = useCreateOrderMutation()
    
    const [blockScroll, allowScroll] = useScrollBlock();
    const dispatch = useDispatch()
    const changeThemeColor = useCallback((color) => {
        dispatch(setNewThemeColor(color));
    }, [dispatch]);

    useEffect(() => {
        if (isLoading) {
            changeThemeColor({color: '#5c5d5e'});
            Modal_2.open(Modal_Loading);
        }
    }, [isLoading]);

    useEffect(() => {
        if (isSuccess) {
            const { url } = data
            changeThemeColor({color: '#5c5d5e'});
            Modal_2.closeByComponent(Modal_Loading)
            // reset cart
            dispatch(emptyCart())
            // navigate
            window.location.href = url
        }
    }, [isSuccess]);

    // Button agreement
    const [agreementAccepted, setAgreementAccepted] = useState(false);

    const handleChangeAgreement = (event) => {
        setAgreementAccepted(event.target.checked);
    }

    // Redux
    const contacts = useSelector(state => state.cart.contacts);
    const addresses = useSelector(state => state.cart.addresses);
    const comments = useSelector(state => state.cart.order.comments);
    
    const defaultContact = contacts?.length ? contacts.find( item => item.default === true ) : null
    const defaultAddress = addresses?.length ? addresses.find( item => item.default === true ) : null
    const defaultComment = comments?.length ? comments.find( item => item.default === true ) : null

    const deliveryDate = useSelector(state => state.cart.order.deliveryDate);
    const paymentType = useSelector(state => state.cart.order.paymentType);

    const itemsInShoppingCart = useSelector(state => state.cart.cart);

    // Save New Order
    const saveNewOrder = async (e) => {
        e.preventDefault()
        if(defaultContact && defaultAddress && deliveryDate && paymentType) {
            await addNewOrder(formatDataCartForDbSave(itemsInShoppingCart, defaultContact, defaultAddress, deliveryDate, paymentType, defaultComment))
        } else{
            // show error what is missing
        }
    }

    return  (
                <>
                    <div className={styles.agreement_block}>
                        <input
                            className={styles.agreement_checkbox}
                            type="checkbox"
                            name="agreement"
                            id="agreement"
                            onChange={handleChangeAgreement}
                        />
                        <label 
                            className={styles.agreement_label}
                            htmlFor="agreement"
                        >
                            Я подтверждаю свое согласие с политикой обработки персональных данных.
                        </label>
                    </div>
                    <button 
                        onClick={saveNewOrder}
                        className={styles.item_btn_blue}
                        disabled={ 
                                agreementAccepted 
                                    ? false : true
                                }
                    >
                        Оплатить
                    </button>
                </>
            )
}

export default PayButtonToServer