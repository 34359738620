import styles from './VProduction.module.css'
import VTitle from '../../common/VTitle'

// images
import step_1 from "../../assets/img/by_page/production/step_1.png"
import step_2 from "../../assets/img/by_page/production/step_2.png"
import step_3 from "../../assets/img/by_page/production/step_3.png"
import step_4 from "../../assets/img/by_page/production/step_4.png"

const BlockLeft = ({ title, content, image}) => {
  return(
      <div className={`${styles.wrapper} ${styles.left}`}>
              <div className={"card__wrapper " + styles.card__wrapper}>
                  <div className={"card__content " + styles.card__content}>
                      <h3 className="card__title">{title}</h3>
                      <p className={styles.content}>{content}</p>
                  </div>
                  <img className={styles.cont_img} src={image} alt='step 1' />
              </div>
        </div>
  )
}
const BlockRight = ({ title, content, image}) => {
  return(
      <div className={`${styles.wrapper} ${styles.right}`}>
              <div className={"card__wrapper " + styles.card__wrapper}>
                  <div className={"card__content " + styles.card__content}>
                      <h3 className="card__title">{title}</h3>
                      <p className={styles.content}>{content}</p>
                  </div>
                  <img className={styles.cont_img} src={image} alt='step 1' />
              </div>
        </div>
  )
}

const VProduction = () => {
  return (
    <>
      <VTitle title="Производство" subtitle="Расскажем о производстве качественного товара" />
      
      <BlockLeft
          title = "I. Процесс очистки воды"
          content = "Скважинная вода при температуре 10°C проходит через цеолитовый фильтр для удаления крупных частиц, затем два полипропиленовых фильтра очищают её от взвешенных частиц и избыточных солей. Наконец, мембранный фильтр очищает воду на молекулярном уровне перед отправкой на следующий этап."
          image = {step_1} 
      />
      <BlockRight 
           title = "II. Обработка воды озоном"
           content = "Очищенная вода поступает в промышленные резервуары общим объемом более 60 куб. м, где в автоматическом режиме проходит обработка воды озоном. Посредством озонирующей установки происходит расщепление под действием электрического разряда H2O до атомарного кислорода О3-озон, который обладает 100% бактерицидным действием. Экспозиция озоном происходит в течение 10 мин. Контроль за содержанием озона в воде проводится лабораторией заводе на протяжении рабочего дня."
           image = {step_2} 
      />
      <BlockLeft
          title = "III. Цех розлива"
          content = "На этапе после обработки воды она направляется в цехи розлива, проходя через дополнительные магистральные фильтры для очистки. Перед точкой розлива прилагаются УФ-установки для защиты от аварий и фильтры контрольной фильтра."
          image = {step_3} 
      />
      <BlockRight 
           title = "IV. Обеззараживание бутылей"
           content = "На четвертом этапе происходит обеззараживание поликарбонатных бутылей. Бутыли продали через автоматическую внешнюю мойку с крутящимися ершами и моющим раствором для очистки снаружи. Затем они проходят через тоннель внутренней мойки с 12 ступенями: первые две ступени обрабатываются моющим раствором при температуре 60-80°C, следующие две – специальным дезинфицирующим раствором, а остальные восемь – озонированной водой для удаления остатков растворов. Контроль за качеством и остатками растворов осуществляют специалисты лаборатории для рабочих смен, после чего в бутыли добавляют воду."
           image = {step_4} 
      />
    </>
  )
}

export default VProduction