import { Outlet, useNavigate } from "react-router-dom"
import { useEffect, useState } from 'react'
import { useRefreshMutation } from "./authApiSlice"
import { useSelector } from 'react-redux'
import { selectCurrentToken } from "./authSlice"

const PersistLogin = ({ifUnauthorisedRedirectTo}) => {
    const token = useSelector(selectCurrentToken)
    const navigate = useNavigate()

    const [trueSuccess, setTrueSuccess] = useState(false)

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation()


    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                //const response = 
                await refresh()
                //const { accessToken } = response.data
                setTrueSuccess(true)
            }
            catch (err) {
                console.error(err)
            }
        }

        if (!token) verifyRefreshToken()
        // eslint-disable-next-line
    }, [])

    useEffect( () =>{
        if(isError){
            navigate(ifUnauthorisedRedirectTo)
        }        
    }, [isError, error])

    let content
    if (isLoading) { //persist: yes, token: no
        content = <p>Loading...</p>
    } else if (isSuccess && trueSuccess) { //persist: yes, token: yes
        content = <Outlet />
    } else if (token && isUninitialized) { //persist: yes, token: yes
        content = <Outlet />
    }

    return content
}
export default PersistLogin