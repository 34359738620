import styles from './MainLayout.module.css'
import TechnicalMaintenance from './TechnicalMaintenance'
import { Outlet } from 'react-router-dom'

const MainLayout = ({technicalMaintenance}) => {

    return technicalMaintenance
        ? <TechnicalMaintenance />
        : <Outlet />
}

export default MainLayout