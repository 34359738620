
import VMainTitle from "../VMainTitle"
import styles from "./VMainBenefits.module.css"

// Icons
import icon_1 from "../../../assets/img/by_page/main/benefits/cog.svg"
import icon_2 from "../../../assets/img/by_page/main/benefits/health.svg"
import icon_3 from "../../../assets/img/by_page/main/benefits/trophy.svg"

const VMainBenefits = () => {
  return (
    <section className={styles.our_benefits}>
        <VMainTitle title='Наши преимущества' subtitle='Наши преимущества - Ваш успех!'/>
        <div className={styles.wrapper}>
            
            <div className={"card__wrapper " + styles.wrapper_around}>
                <div className={"card__content " + styles.card__content}>
                    <div className={styles.benefit_icon}>
                        <img src={icon_1} alt="benefit icon" />
                    </div>
                    <p>Первые в Казахстане</p>
                </div>
            </div>

            <div className={"card__wrapper " + styles.wrapper_around}>
                <div className={"card__content " + styles.card__content}>
                    <div className={styles.benefit_icon}>
                        <img src={icon_2} alt="benefit icon" />
                    </div>
                    <p>Первые в Казахстане</p>
                </div>
            </div>

            <div className={"card__wrapper " + styles.wrapper_around}>
                <div className={"card__content " + styles.card__content}>
                    <div className={styles.benefit_icon}>
                        <img src={icon_3} alt="benefit icon" />
                    </div>
                    <p>Первые в Казахстане</p>
                </div>
            </div>

            <div className={"card__wrapper " + styles.wrapper_around}>
                <div className={"card__content " + styles.card__content}>
                    <div className={styles.benefit_icon}>
                        <img src={icon_2} alt="benefit icon" />
                    </div>
                    <p>Первые в Казахстане</p>
                </div>
            </div>
            
        </div>
        
    </section>
  )
}

export default VMainBenefits