import styles from './EmptyCart.module.css'
// icons
import {ReactComponent as IconEmptyCart} from "../../../../assets/img/icons/empty_cart.svg"

const EmptyCart = () => {
    return(
        <div className={styles.empty_cart_block}>
            <IconEmptyCart />
            <p className={styles.text_empty_cart}>Ваша корзина пуста</p>
            <p className={styles.text_add_items}>Добавьте товар для оформления заказа</p>
        </div>
    )
}

export default EmptyCart