import styles from './VModal_2Common.module.css'
import * as RadioGroup from '@radix-ui/react-radio-group';
import { useEffect, useState } from 'react';
// Icons
import { ReactComponent as IconPlus } from "../../../../assets/img/icons/plus.svg"

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from '../../../../../../customHooks/useScrollBlock';
import PopupAppearLayout from '../../Layouts/PopupAppearLayout';
import Modal_2 from '../../Modal_2';
import VModal_2ContactsAddEdit from './VModal_2ContactsAddEdit';
// Helmet
import { setDefaultThemeColor } from '../../../../../../features/helmet/helmetSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultContact } from '../../../../../../features/cart/cartSlice';

const VModal_2ContactsSelect = () => {
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();

    const [selectedContact, setSelectedContact] = useState()
    const saveSelectedAddress = () => {
        dispatch(setDefaultContact(selectedContact))
        closeModal()
    }
    

    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())
    
    const contacts = useSelector(state => state.cart?.contacts);
    const defaultContactIndex = contacts?.length ? (contacts.findIndex( item => item.default === true )).toString() : null

    // fix to reset form to default values on close
    const [modelReset, setModelReset] = useState(false)
    useEffect( ()=> {
        setSelectedContact(defaultContactIndex)
    },[defaultContactIndex, modelReset])
 

    const ListContacts = () => contacts?.length ? contacts.map( (contact, k) => {
        return(
                <div key={k} className={styles.radioGroupBlock}>
                    <RadioGroup.Item className={styles.radioGroupItem} value={k.toString()} id={`contact_${k}`}>
                        <RadioGroup.Indicator className={styles.radioGroupIndicator} />
                    </RadioGroup.Item>
                    <label className={styles.label} htmlFor={`contact_${k}`}>

                        <p className={styles.content_text}>{contact.first_name} {contact.last_name}</p>
                        <p className={styles.content_text}>{contact.mobile}</p>
                        <div className={styles.btn_change_container}>
                            <button type='button' onClick={() => openAddEditContact({editContact: contact})} className={styles.btn_change_info}>изменить</button>
                        </div>
                    </label>
                </div>                     
        )
    }) : null


    const openAddEditContact = ({editContact}) => {
        // setTimeout(function(){}, 150);
        Modal_2.open(VModal_2ContactsAddEdit, {editContact: editContact});
    }

    const closeModal = () => {
        // setTimeout(function(){allowScroll()}, 150);
        defaultThemeColor()
        allowScroll()
        setModelReset(!modelReset)
        modal.close()
    }   

    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
                <div className={styles.header_container}>
                    <div className={styles.header_title}>
                        <p>Выбор контакта</p>
                    </div>
                    
                    <button
                        className={styles.popup_layout__close}
                        type="button"
                        onClick={closeModal}
                        >
                        &#9587;
                    </button>
                </div>

                                
                {/* SELECT FORM */}
                    {contacts.length
                        ?   null
                        :   <p className={styles.noSavedData}>У Вас нет сохраненных контактов. Добавьте новый контакт</p>
                    }
                    <form>
                        <RadioGroup.Root 
                            className="RadioGroupRoot" 
                            defaultValue={defaultContactIndex} 
                            aria-label="select item"
                            onValueChange={setSelectedContact}
                            value={selectedContact}
                        >
                            
                            <ListContacts />
                        
                        </RadioGroup.Root>
                    </form>
                
                    {/* BUTTONS */}
                    <div className={styles.btn_container}>
                        <button onClick={() => openAddEditContact({editContact: null})} className={styles.btn_add_address}>
                            <IconPlus className={styles.icon_plus} />
                            Добавить контакт
                        </button>
                        <div className={styles.btn_block}>
                            <button onClick={closeModal} className={styles.item_btn_blue_border} >
                                Отмена
                            </button>
                            {contacts.length
                                ?   <button onClick={saveSelectedAddress} className={styles.item_btn_blue_filled} >
                                        Выбрать
                                    </button>
                                :   null
                            }
                            
                        </div>
                    </div>
                    

                
        </PopupAppearLayout>
    )
}

export default VModal_2ContactsSelect