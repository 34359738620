import { Link } from "react-router-dom"

const CustomerWelcome = () => {
    const date = new Date()
    const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(date)

    return (
        <>
            <p>{today}</p>
            <div>Welcome</div>

            <p><Link to="/dash/users">Users</Link></p>
            <p><Link to="/dash/orders">Orders</Link></p>
        </>
       
    )
}

export default CustomerWelcome