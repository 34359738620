import styles from './VModalLogin.module.css'

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from "../../../../../customHooks/useScrollBlock";
import PopupAppearLayout from "../Layouts/PopupAppearLayout";

// Helmet
import { setDefaultThemeColor } from '../../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';
import UnderDevelopment from '../../../../other/UnderDevelopment';

const VModalLogin = () => {
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();

    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())

    const closeModal = () => {
      // setTimeout(function(){allowScroll()}, 150);
      defaultThemeColor()
      allowScroll()
      modal.close()
    }   
    
                 
    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
            <div className={styles.header_container}>
                <div className={styles.header_title}>
                    <p>Авторизация</p>
                </div>
                
                <button
                    className={styles.popup_layout__close}
                    type="button"
                    onClick={closeModal}
                    >
                    &#9587;
                </button>
            </div>

            <div className={styles.login__container}>
                
                <UnderDevelopment />
                <p className={styles.message}>Следите за новостями компании и обновлениями сайта</p>
                <button
                    className={styles.btn_return}
                    type="button"
                    onClick={closeModal}
                    >
                    Закрыть
                </button>
            </div>
        </PopupAppearLayout>
    );         
}

export default VModalLogin;
