import styles from './CartWithItems.module.css'
// Icons
import {ReactComponent as IconSublevel} from "../../../../assets/img/icons/sublevel.svg"
// CartComponent
import CartItem from './CartItem';

const CartWithItems = ({itemsInShoppingCart, isForCart}) => {

    const cartWithItemsAdded = Object.entries(itemsInShoppingCart).map( ([k,item]) => {
        return  (
                    <section key={k} className={styles.cart_block}>  
                        <CartItem item={item} sub={false} isForCart={isForCart} />
                        {
                            item.addedSubProduct
                                ?   <div className={styles.sub_product}>
                                        <IconSublevel className={styles.sublevel_svg} />
                                        <CartItem item={item.addedSubProduct} sub={true} isForCart={isForCart}/>
                                    </div>
                                :   null
                        }  
                    </section>
                    
                )
    })
    return cartWithItemsAdded
}

export default CartWithItems