export const truncateText = (content, maxLength) => {
  
    if (content.length > maxLength) {
      var truncatedText = content.substring(0, maxLength);
      // Find the last space before the maximum length
      var lastSpaceIndex = truncatedText.lastIndexOf(" ");
      // If there is a space before the maximum length, truncate at that space
      if (lastSpaceIndex !== -1) {
        truncatedText = truncatedText.substring(0, lastSpaceIndex) + " ...";
      } else {
        // If there is no space before the maximum length, just add ellipsis
        truncatedText += " ...";
      }
      return truncatedText;
    } else {
      return content
    }
  }