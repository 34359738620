import styles from './VModal_2Common.module.css'
import { v4 as uuidv4 } from 'uuid';

// Icons
import { ReactComponent as IconBin } from "../../../../assets/img/icons/bin.svg"
// Form
import { useForm } from "react-hook-form"

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from '../../../../../../customHooks/useScrollBlock';
import PopupAppearLayout from '../../Layouts/PopupAppearLayout';
// Helmet
import { setDefaultThemeColor } from '../../../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';
// Redux
import { addEditAddress } from '../../../../../../features/cart/cartSlice';
import { deleteAddress } from '../../../../../../features/cart/cartSlice';

const VModal_2AddressAddEdit = ({editAddress}) => {
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();
    
    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())

    const closeModal = () => {
        // setTimeout(function(){allowScroll()}, 150);
        defaultThemeColor()
        allowScroll()
        modal.close()
    }   

    const { register,
            formState: { errors },
            handleSubmit, 
    } = useForm()
    const onSubmit = (data) => {
        const output = {
            ...data,
            uuid: editAddress && editAddress.uuid ? editAddress.uuid : uuidv4(),
        }
        dispatch(addEditAddress(output))
        closeModal()
    }

    const deleteSelectedAddress = (data) => {
        dispatch(deleteAddress(data))
        closeModal()
    }

    const places = [
        "71 переезд",
        "Акжар",
        "Алатау",
        "Алматы",
        "Ашибулак",
        "Белбулак",
        "Бесагаш",
        "Боралдай",
        "Водник",
        "Гульдала",
        "Даму",
        "Даулет",
        "Жанатурмыс",
        "Жетыген",
        "Заречный",
        "Каскелен",
        "Коксай",
        "Комсомол",
        "Кошмамбет",
        "Кунаев",
        "Кыргауылды",
        "Мерей",
        "Панфилов",
        "Талгар",
        "Талдыбулак",
        "Таусамал",
        "Туздыбастау",
        "Ыргелы"
    ]

    const ListOfPlaces = () => places.map( (place, i) => {
        return <option key={place} value={place}>{place}</option>
    })
    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
                <div className={styles.header_container}>
                    <div className={styles.header_title}>
                        <p>{editAddress ? "Редактирование" : "Новый адрес"}</p>
                    </div>
                    
                    <button
                        className={styles.popup_layout__close}
                        type="button"
                        onClick={closeModal}
                        >
                        &#9587;
                    </button>
                </div>

                                
                {/* SELECT FORM */}
                {
                    editAddress
                        ?   <div onClick={ () => deleteSelectedAddress(editAddress)} className={styles.deleteBlock}>
                                <IconBin />
                                <p>Удалить адрес</p>
                            </div>
                        :   null
                }
                
                
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    

                    <label className={styles.addCityLabel} >Город*:</label>
                    <select defaultValue={editAddress ? editAddress.city : "Алматы"} className={styles.addCitySelect} {...register("city")}>
                        <ListOfPlaces />                        
                    </select>

                    <label className={styles.addCityLabel  + (errors.street ? " " + styles.error : "")} >Улица*:</label>
                    <input defaultValue={editAddress && editAddress.street.trim() !== "" ? editAddress.street : ''} className={styles.addStreet + (errors.street ? ' ' + styles.error : "")} {...register("street", { required: true })} />

                    <div className={styles.address_info_container}>
                        <div className={styles.address_info_block}>
                            <label className={styles.addCityLabel + (errors.building ?  " " + styles.error : "")} >Дом*:</label>
                            <input defaultValue={editAddress && editAddress.building.trim() !== "" ? editAddress.building : ''} className={errors.building ? styles.error : ""} {...register("building", { required: true })} />
                        </div>

                        <div className={styles.address_info_block}>
                            <label className={styles.addCityLabel} >Кв/офис:</label>
                            <input defaultValue={editAddress && editAddress.apt_office.trim() !== "" ? editAddress.apt_office : ''} {...register("apt_office")} />
                        </div>
                        
                    </div>
                    

                    <div className={styles.address_info_container}>
                        <div className={styles.address_info_block}>
                            <label className={styles.addCityLabel} >Подъезд:</label>
                            <input defaultValue={editAddress && editAddress.entrance.trim() !== "" ? editAddress.entrance : ''} {...register("entrance")} />
                        </div>

                        <div className={styles.address_info_block}>
                            <label className={styles.addCityLabel}>Этаж:</label>
                            <input defaultValue={editAddress && editAddress.floor.trim() !== "" ? editAddress.floor : ''} {...register("floor")} />
                        </div>
                        
                    </div>

                    <label className={styles.addCityLabel} >Комментарий:</label>
                    <textarea defaultValue={editAddress && editAddress.comment.trim() !== "" ? editAddress.comment : ''} className={styles.addCommentText} {...register("comment")} />

                    {/* BUTTONS */}
                    <div className={styles.btn_block}>
                        <button onClick={closeModal} className={styles.item_btn_blue_border} >
                            Отмена
                        </button>
                        <button type='submit' className={styles.item_btn_blue_filled} >
                            Сохранить
                        </button>
                    </div>
                    
                </form>
                    
                
                
                    

                
        </PopupAppearLayout>
    )
}

export default VModal_2AddressAddEdit