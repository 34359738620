import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const deliveryAdapter = createEntityAdapter({})

const initialState = deliveryAdapter.getInitialState()

export const deliveryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDatesExcluded: builder.query({
            query: () => '/delivery/dates_excluded',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            keepUnusedDataFor: 0,
        }),
    }),
})


export const {
    useGetDatesExcludedQuery
} = deliveryApiSlice