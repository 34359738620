import { createSlice } from "@reduxjs/toolkit";

const helmetSlice = createSlice({
    name: "helmet",
    initialState: {
        theme_color: {
            isDefault: true,
            defaultColor: '#edf3f8',
            newColor: ''
        }
    },
    reducers:   {
                    setNewThemeColor: (state, action) => {
                        
                        const {color} = {...action.payload};
                        state.theme_color.isDefault = false
                        state.theme_color.newColor = color
                    },
                    setDefaultThemeColor: (state, action) => {
                        state.theme_color.isDefault = true
                    },
                    
                },
});

export const { setNewThemeColor, setDefaultThemeColor} = helmetSlice.actions;

export default helmetSlice.reducer;