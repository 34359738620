import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const orderAdapter = createEntityAdapter({})

const initialState = orderAdapter.getInitialState()

export const orderApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // getCatalog: builder.query({
        //     query: () => '/catalog',
        //     validateStatus: (response, result) => {
        //         return response.status === 200 && !result.isError
        //     },
        //     providesTags: [{ type: 'Catalog', id: 'LIST'}]
        // }),
        createOrder: builder.mutation({
            query: initialUserData => ({
                url: '/orders',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            })
        }),
        checkOrderGetStatus: builder.mutation({
            query: initialUserData => ({
                url: '/orders/check',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            })
        }),
    }),
})


export const {
    useCreateOrderMutation, useCheckOrderGetStatusMutation
} = orderApiSlice