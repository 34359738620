import styles from './ADashboard.module.css'
import UnderDevelopment from '../../other/UnderDevelopment'

const ADashboard = () => {
  return (
    <div className={styles.dashboard}>
      <UnderDevelopment />
    </div>
  )
}

export default ADashboard