import PopupAppearLayout from "../../common/Modals/Layouts/PopupAppearLayout";
import styles from './VVacanciesModalApply.module.css'
import { useCallback, useEffect, useState } from "react";

// Redux
import { useUploadVacanciesMutation } from "./ApiSliceVacancies";
// Modal
import { useDispatch } from "react-redux";
import { useModalWindow } from "react-modal-global";
import useScrollBlock from "../../../../customHooks/useScrollBlock";
import { setDefaultThemeColor } from "../../../../features/helmet/helmetSlice";

// icons
import icon_close from '../../assets/img/icons/close.svg'
// Upload
import {useDropzone} from 'react-dropzone'

const VVacanciesModalApply = ({ data }) => {
    const bodyFormData = new FormData();
    const [errMsg, setErrMsg] = useState([])
    const [submitedTheApplication, setSubmitedTheApplication] = useState(false)
    const ACCEPTED_FILE_EXT = ['pdf', 'doc', 'docx']
    const ACCEPTED_FILE_MIME_TYPE = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

    
    //ON DROP
    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles)
    }, [])
    
     // onFileDialogOpen
     const onFileDialogOpen = () => {
        setFiles([])
        setErrMsg([])
    }
    // ACCEPTED
    const onDropAccepted = useCallback(rejected => {
        setErrMsg([])
    })
    // REJECTED
    const onDropRejected = useCallback(rejected => {
        setFiles([])
        setErrMsg([])
        if(rejected.length>1){
            setErrMsg(oldArray => [...oldArray, 'Максимальное число файлов - 1']);
        }
        rejected.forEach(item => {
            console.log(item.file)
            if(item.file.size > 5242880){
                setErrMsg(oldArray => [...oldArray, 'Файл не должен превышать 5мб']);
            }
            const extension = item.file.name.split('.').pop();
            const mime_type = item.file.type
            if(!ACCEPTED_FILE_EXT.includes(extension) || !ACCEPTED_FILE_MIME_TYPE.includes(mime_type)){
                setErrMsg(oldArray => [...oldArray, 'Не поддерживаемый формат.']);
            }
        });

        
    }, [])
    
    // DROPZONE
    const {acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        maxFiles: 1,
        onDropAccepted,
        onDropRejected,
        onFileDialogOpen,
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        }
    })

    const fileAcceptedItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path}
        </li>
    ));

    
    const fileRejectionItems = errMsg.map( (v,k) => (
        <p className={styles.msgError} key={k}>{v}</p>
    ));
 
    
    const [nameInput, setNameInput] = useState('');
    const [mobileInput, setMobileInput] = useState('');
    const [commentInput, setCommentInput] = useState('');
    const [files, setFiles] = useState([]);

    const handleNameChange = (event) => {
        setNameInput(event.target.value);
    }

    const handleMobileChange = (event) => {
        setMobileInput(event.target.value);
    }

    const handleCommentChange = (event) => {
        setCommentInput(event.target.value);
    }

    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();
    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())

    const closeModal = () => {
      // setTimeout(function(){allowScroll()}, 150);
      defaultThemeColor()
      allowScroll()
      modal.close()
    }   

    const [uploadVacancies, {
        data: vacancyResult,
        isLoading, 
        isSuccess, 
        isError, 
        error
    }] = useUploadVacanciesMutation()

    useEffect( () => {
        if(isSuccess){
            setNameInput('')
            setMobileInput('')
            setCommentInput('')
            setFiles([])

            setSubmitedTheApplication(true)
            setTimeout(() => {
                setSubmitedTheApplication(false);
            }, 3000);
        }
        
    }, [isSuccess])

    const handleSubmit = (e) => {
        e.preventDefault()
        
        bodyFormData.append('vacancy', data.title);
        bodyFormData.append('name', nameInput);
        bodyFormData.append('mobile', mobileInput);
        bodyFormData.append('comment', commentInput);
        for (let i = 0; i < files.length; i++) {
            bodyFormData.append("file", files[i]);
        }
        uploadVacancies(bodyFormData)
    }

    return (
            <PopupAppearLayout classNameLayout={styles.popup__layout}>
                <div className={styles.header_wrapper}>
                    <div className={styles.header_container}>
                        <p className={styles.header_title}>{data.title}</p>
                        
                        <button
                            className={styles.popup_layout__close}
                            type="button"
                            onClick={closeModal}
                            >
                            <img src={icon_close} alt="close button" />
                            </button>
                    </div>
                    <p>{data.category}</p>  
                </div>

                {
                submitedTheApplication
                    ?   <div>
                            <p>Благодарим, Ваша заявка принята.</p>
                        </div>
                    :   <div className={styles.catalog__container}>
                            

                            <form onSubmit={handleSubmit}>
                                <div className={styles.input__field}>
                                    <input type="text" id="name" required value={nameInput} onChange={handleNameChange}/>
                                    <label className={styles.label} htmlFor="name">Имя*:</label>
                                </div>

                                <div className={`${styles.input__field} + ${styles.input__field_mobile}`}>
                                    <input type="text" id="mobile" required value={mobileInput} onChange={handleMobileChange} />
                                    <label className={styles.label} htmlFor="mobile">Телефон*:</label>
                                </div>

                                <div className={styles.input__textarea}>
                                    <label className={styles.comment_label} htmlFor="comment">Комментарий:</label>
                                    <textarea className={styles.input_comment} type="text" id="comment" value={commentInput} onChange={handleCommentChange} />
                                </div>

                                <div className={styles.input__textarea}>
                                    <label className={styles.comment_label} htmlFor="comment">Файлы*:</label>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        
                                            
                                            <section className={styles.uploadBlock}>
                                                
                                                    
                                            {
                                                isDragActive 
                                                ? <span className={styles.attach_file_text}>Поместите файлы здесь</span>
                                                :   <>
                                                        <div className={styles.attach_file_block}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                                <path d="M18.5915 17.4226L7.54386 17.4849C6.19046 17.4926 4.85797 16.9634 3.83959 16.0137C2.82121 15.064 2.20034 13.7717 2.11353 12.4211C2.02673 11.0704 2.48116 9.77206 3.37683 8.81157C4.27243 7.85116 5.53595 7.30726 6.88936 7.29964L17.9371 7.23726C18.8393 7.23216 19.7276 7.58498 20.4066 8.21808C21.0855 8.85118 21.4994 9.71272 21.5573 10.6132C21.6151 11.5136 21.3122 12.3792 20.7151 13.0195C20.118 13.6598 19.2756 14.0224 18.3734 14.0275L7.31934 14.0838C6.86815 14.0864 6.424 13.91 6.08454 13.5935C5.74508 13.2769 5.53812 12.8461 5.50923 12.3959C5.48028 11.9457 5.63173 11.5129 5.93031 11.1927C6.22882 10.8726 6.65004 10.6913 7.10115 10.6887L17.3016 10.6371" stroke="#001A72" stroke-line-cap="round" stroke-line-join="round"/>
                                                            </svg>
                                                            <span className={styles.attach_file_text}>Прикрепить</span>
                                                        </div>
                                                        
                                                        {
                                                            errMsg.length === 0 && files.length === 0
                                                                ?   <>
                                                                        <p>Максимальный размер файла 5мб</p>
                                                                        <p>Форматы: pdf, doc, docx</p>
                                                                    </>
                                                                :   null
                                                        }
                                                        {
                                                            errMsg.length > 0
                                                                ?   <>
                                                                        {fileRejectionItems}
                                                                    </>
                                                                : null
                                                        }
                                                        {
                                                            files.length > 0
                                                                ?   <>
                                                                        {fileAcceptedItems}
                                                                    </>
                                                                : null
                                                        }
                                                    </>
                                            }
                                            </section>
                                            
                                        
                                    </div>
                                </div>

                                <div className={styles.btn_container}>
                                    <button
                                        className={styles.btn_close}
                                        type="submit"
                                        disabled={isLoading || nameInput === "" || mobileInput === "" || files.length===0
                                                    ? true 
                                                    : false}
                                    >
                                        Откликнуться
                                    </button>
                                </div>
                            </form>

                        
                            
                            
                        </div>
                }
            </PopupAppearLayout>
    )
}

export default VVacanciesModalApply;
