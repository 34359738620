import styles from './VMainCatalog.module.css'
import VMainTitle from "../VMainTitle"
// MODALS
import Modal_1 from '../../../common/Modals/Modal_1';
import VModalCatalog from '../../../common/Modals/Catalog/VModalCatalog';
import useScrollBlock from '../../../../../customHooks/useScrollBlock';
// Images
import imgCalipsik from '../../../assets/img/by_page/main/catalog/calipsik.png'
import imgCopacking from '../../../assets/img/by_page/main/catalog/copacking.png'
import imgService from '../../../assets/img/by_page/main/catalog/service.png'
import imgWater from '../../../assets/img/by_page/main/catalog/water.png'
import imgEquipment from '../../../assets/img/by_page/main/catalog/equipment.png'
import imgMineral from '../../../assets/img/by_page/main/catalog/mineral.png'
import { NavLink } from 'react-router-dom';

const Box = ({ title, img, url, modalOpenFnc }) => {
    return(
            <div className={styles.box}>
                <div className={"card__wrapper " + styles.card__wrapper}>
                    <h3 className={styles.card_title}>{title}</h3>
                    <div className={"card__content " + styles.card__content}>
                        <img className={styles.card_img} src={img} alt={title} />
                    </div>
                    <div className={styles.btn_block}>
                        {/* <button className={styles.card_btn}>Подробнее</button> */}
                        {
                            url
                                ? <NavLink className={styles.card_btn} to={url}>Заказать</NavLink>
                                : <button onClick={modalOpenFnc} className={styles.card_btn}>Заказать</button>
                        }
                        
                    </div>
                </div>
            </div>
    )
}

const VMainCatalog = () => {
    // Catalog Modal
    const [blockScroll, allowScroll] = useScrollBlock();
    const openCatalog = (catalogTab) => {
        // setTimeout(function(){}, 150);
        blockScroll()
        Modal_1.open(VModalCatalog, { 
                                        id: "modalCatalog", 
                                        closable: false, layer: 1, 
                                        defaultCartOpen: false,
                                        defaultOrderDetailsOpen: false,
                                        catalogTab: catalogTab 
                                    });
    }

    return (
        <>
            <section className={styles.catalog}>
            <VMainTitle title='Ассортимент' subtitle='Узнайте подробнее о линейке нашей продукции' fnc={() => openCatalog()} />
                <div className={styles.wrapper}>

                    <Box 
                        title="Вода" 
                        img={imgWater}
                        modalOpenFnc={() => openCatalog('0.5л-5л')} 
                    />
                    <Box 
                        title="Calips’ик для детей" 
                        img={imgCalipsik} 
                        modalOpenFnc={() => openCatalog("Calips'ик")}
                    />
                    <Box 
                        title="Co-Packing" 
                        img={imgCopacking} 
                        url='co-packing' />
                    <Box 
                        title="Сервисные услуги" 
                        img={imgService}
                        url='services'
                    />
                    <Box 
                        title="Минеральная вода" 
                        img={imgMineral} 
                        modalOpenFnc={() => openCatalog("Мин. вода")}
                    />
                    <Box 
                        title="Оборудование" 
                        img={imgEquipment} 
                        modalOpenFnc={() => openCatalog("Диспенсеры")}
                    />
                </div>
            </section>
        </>
    )
}

export default VMainCatalog