import { ModalController } from 'react-modal-global';

// Catalog
import VModal_3Rules18_9 from './Catalog/Rules18_9/VModal_3Rules18_9';

const Modal_3 = new ModalController({
  defaultParams: {},
  components: {   
    VModal_3Rules18_9,
              },
});

export default Modal_3;
