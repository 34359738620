import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuth from "../../customHooks/useAuth"

const RequireAuth = ({ allowedRoles, ifNotAllowedNavigateTo }) => {
    const location = useLocation()
    const { role } = useAuth()

    const content = (
        allowedRoles.includes(role)
            ? <Outlet />
            : <Navigate to={ifNotAllowedNavigateTo} state={{ from: location }} replace />
    )

    return content
}
export default RequireAuth