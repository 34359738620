import styles from './VModalCatalog.module.css'
import buttons from './../../Buttons.module.css'

import { useEffect, useRef, useState } from 'react';
// Images
import icon_error from './../../../assets/img/icons/error.svg'
// Modal
import PopupAppearLayout from "../Layouts/PopupAppearLayout";
import { useModalWindow } from 'react-modal-global';
// RTK Query
import { useGetCatalogMutation } from "../../../../../features/catalog/catalogApiSlice";
// Sort
import { createNestedObject } from './SortArray';
// use Media
import { useMedia } from '../../../../../customHooks/useMedia';
// functions
import { formatPrice } from '../../../../../customHooks/functions';
// Categories
import PartHeader from './PartHeader';
import PartCategory from './PartCategory';
import PartCatalog from './PartCatalog';
import PartCart from './PartCart';
import PartOrderDetails from './PartOrderDetails';
// Redux
import { useSelector } from 'react-redux';
import LoaderInline from '../../../../other/Loaders/LoaderInline';

const ModalCatalog = ({defaultCartOpen, defaultOrderDetailsOpen, catalogTab}) => {
    const [category, setCategory] = useState(catalogTab ? catalogTab : '0.5л-5л');
    const modal = useModalWindow();

    const anchor = useRef();

    function scrollToTop(){
        anchor.current?.scrollIntoView({ block:'nearest' });
    }
    
    // ORDER DETAILS
    const [orderDetailsShown, setOrderDetailsShown] = useState(defaultOrderDetailsOpen)
    useEffect(() => {
      if(!modal.closed){
        setOrderDetailsShown(defaultOrderDetailsOpen);
      }
    }, [modal.closed]);
    // Cart
    const [cartShown, setCartShown] = useState(defaultCartOpen)
    useEffect(() => {
      if(!modal.closed){
        setCartShown(defaultCartOpen);
      }
    }, [modal.closed]);

    const hideCartHideOrderDetails = () =>  {
                                              setCartShown(false)
                                              setOrderDetailsShown(false)
                                              scrollToTop()
                                            }
    const showCartHideOrderDetails = () =>  {
                                              setCartShown(true)
                                              setOrderDetailsShown(false)
                                              scrollToTop()
                                            }
    const hideCartShowOrderDetails = () =>  {
                                              setCartShown(false)
                                              setOrderDetailsShown(true)
                                              scrollToTop()
                                            }

    // const { data: dbUnsortedData, isLoading, isSuccess, isError, error } = useGetCatalogQuery()
    const [getCatalog, {
        data: dbUnsortedData,
        isLoading, 
        isSuccess, 
        isError, 
        error
    }] = useGetCatalogMutation()

    useEffect( () => {
      if(!modal.closed){
        getCatalog()
      }
    },[modal.closed])
  
    const isLarge850 = useMedia('(min-width: 850px)');

    // Redux
    const totalPriceShoppingCart = useSelector(state => state.cart.totalPrice);

    // Page content
    let content
    if(isError){
      content = <div className={styles.loading_block}>
                    <p className={styles.loading_title}>Ошибка</p>
                    <img className={styles.loading_error} src={icon_error} alt='error icon' />
                    <p className={styles.loading_text}>Не удалось загрузить каталог. Попробуйте еще раз</p>
                    <button className={`${buttons.btn_solid_blue} ${styles.loading_btnRefresh}`} onClick={() => getCatalog()}>Обновить</button>
                </div>
    }
    if(isLoading){
      content = <div className={styles.loading_block}>
                  <p className={styles.loading_title}>Загрузка...</p>
                  <LoaderInline sizeInPx="60px" borderWidth="10px"/>
                  <p className={styles.loading_text}>Пожалуйста подождите...</p>
                </div>
    }

    if(isSuccess){
        // process data from server
        const dbSortedData = createNestedObject(dbUnsortedData)
                        
        content = <>
                    {/* HEADER */}
                    <div ref={anchor}></div>
                    <PartHeader 
                        cartShown={cartShown} 
                        orderDetailsShown={orderDetailsShown}
                        isLarge850={isLarge850}
                        
                        showCartHideOrderDetails={showCartHideOrderDetails}
                        hideCartHideOrderDetails={hideCartHideOrderDetails}
                    />

                    <div className={`${styles.catalog__container} 
                                      ${!isLarge850 && cartShown && "cartShown"}
                                  `}>
                        
                                      {/* order details */}
                                      {
                                        orderDetailsShown
                                            ? <PartOrderDetails setOrderDetailsShown={setOrderDetailsShown} />
                                            : null
                                      }

                                      {/* catalog */}
                                      {
                                        ((!cartShown && !orderDetailsShown) || (isLarge850 && !orderDetailsShown))
                                            ? <div className={styles.left_middle_col_block}> 
                                                  {/* CATEGORIES */}
                                                  <div className={styles.catalog__left}>
                                                      <PartCategory 
                                                            dbSortedData={dbSortedData} 
                                                            category={category} 
                                                            setCategory={setCategory} 
                                                      />
                                                  </div>
                                                  {/* ITEMS */}
                                                  <div className={styles.catalog__middle}>
                                                      <PartCatalog dbUnsortedData={dbUnsortedData} dbSortedData={dbSortedData} category={category} />
                                                  </div>
                                              </div>
                                            : null
                                      }
                                      
                                      {/* cart */}
                                      {
                                        ((cartShown && !orderDetailsShown) || isLarge850)
                                            ? <div className={styles.catalog__right}>
                                                <PartCart 
                                                      orderDetailsShown={orderDetailsShown} 
                                                      setOrderDetailsShown={setOrderDetailsShown} 
                                                      isLarge850={isLarge850}
                                                />
                                              </div> 
                                            : null
                                      }
                                      {/* floating buttons */}
                                      {
                                        isLarge850
                                          ? null
                                          : <div className={styles.open_orderDetails_start}>
                                                {/* order details */}
                                                {
                                                  // no floatintg buttons on the screen
                                                }
                                                {/* catalog */}
                                                {
                                                  !cartShown && !orderDetailsShown && totalPriceShoppingCart
                                                      ? <button 
                                                          className={"selectDisable " + styles.item_btn_blue}
                                                          onClick={showCartHideOrderDetails}
                                                        >
                                                            Корзина ( {formatPrice(totalPriceShoppingCart, ' ')} тг )
                                                        </button>
                                                      : null
                                                }
                                                {/* cart */}
                                                {
                                                  cartShown && !orderDetailsShown && totalPriceShoppingCart
                                                      ? <button 
                                                            className={"selectDisable " + styles.item_btn_blue}
                                                            onClick={hideCartShowOrderDetails}
                                                        >
                                                            Оформить {formatPrice(totalPriceShoppingCart, ' ')} тг
                                                        </button>
                                                      : null
                                                  
                                                }
                                            </div>
                                        }
                        
                       
                        
                    </div>
                  </>
      }

      return(<PopupAppearLayout classNameLayout={styles.popup__layout}>{content}</PopupAppearLayout>)
}

export default ModalCatalog;
