import { Link } from "react-router-dom";

const DashHeader = () => {
  return (
    <>
        <div>DashHeader</div>
    </>
  )
}

export default DashHeader