import styles from './FilesTabs.module.css'
import { Link } from 'react-router-dom';
// Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// SVG
import {ReactComponent as ArrowRightSmall}  from '../../assets/img/icons/arrow_right_small.svg'
// ICONS
import download from '../../assets/img/icons/download.svg'
import share from '../../assets/img/icons/share.svg'


const FilesTabs = ({ dbArray }) => {
    return(
      <Tabs className={styles.tabs}>
        {/* LEFT */}
        <TabList className={styles.left}>
            {Object.entries(dbArray).map( ([index, file]) => {
                return(
                  <Tab key={index} className={styles.tab_item}>
                    <div className={styles.tab_content}>
                      <span>{file.title}</span>
                      <ArrowRightSmall />
                    </div>
                  </Tab>
                )
              } 
            )}
        </TabList>
  
        {/* RIGHT */}
        <div className={styles.right}>
            {Object.entries(dbArray).map( ([index, file]) => {
                return(
                  <TabPanel key={index}>
                    <h3 className={styles.certTitle}>{file.title}</h3>
  
                    <div className={styles.cert_block}>
                          { file.rus && 
                            file.kaz && 
                            file.rus.url_file.trim() !== '' && 
                            file.kaz.url_file.trim() !== ''
                            ?     <>
                                    <div className={styles.cert_content}>
                                          <img className={styles.cert_img} src={`/assets/files/${file.rus.thumbnail}`} alt='certificate rus' />
                                          <p className={styles.language}>Русский язык</p>
                                          <Link 
                                              className={styles.btn_blue}
                                              to={`/assets/files/${file.rus.url_file}`} 
                                              target="_blank" 
                                              download
                                          >
                                            <img src={download} alt='download icon' />
                                                скачать
                                          </Link>
                                          {/* <button className={styles.btn_blue}><img src={share} alt='share icon' />поделиться</button> */}
                                          {/* <img className={styles.qr} src={file.url_qr} alt='certificate rus' /> */}
                                    </div>
                                    <div className={styles.cert_content}>
                                          <img className={styles.cert_img} src={`/assets/files/${file.kaz.thumbnail}`} alt='certificate rus' />
                                          <p className={styles.language}>Қазақ тілі</p>
                                          <Link 
                                              className={styles.btn_blue}
                                              to={`/assets/files/${file.kaz.url_file}`} 
                                              target="_blank" 
                                              download
                                          >
                                            <img src={download} alt='download icon' />
                                                скачать
                                          </Link>
                                          {/* <button className={styles.btn_blue}><img src={share} alt='share icon' />поделиться</button> */}
                                          {/* <img className={styles.qr} src={file.url_qr} alt='certificate rus' /> */}
                                    </div>
                                  </>
                            :     <div className={styles.cert_content}>
                                        <img className={styles.cert_img} src={`/assets/files/${file.thumbnail}`} alt={file.title} />
                                        <p className={styles.language}>Русский язык / Қазақ тілі</p>
                                        <Link 
                                            className={styles.btn_blue}
                                            to={`/assets/files/${file.url_file}`} 
                                            target="_blank" 
                                            download
                                        >
                                          <img src={download} alt='download icon' />
                                              скачать
                                        </Link>
                                        {/* <button className={styles.btn_blue}><img src={share} alt='share icon' />поделиться</button> */}
                                        {/* <img className={styles.qr} src={file.url_qr} alt={file.title} /> */}
                                    </div>
                            }
                    </div>
                  </TabPanel>
                )
              } 
            )}
        </div>
        
      </Tabs>
    )
  }

export default FilesTabs