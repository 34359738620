import { Routes, Route } from "react-router-dom";
import VLayout from "./components/visitor/VLayout";
import DashLayout from "./components/customer/DashLayout";
import MainLayout from "./components/other/MainLayout";

// VISITOR ///////////////////
// Pages
import VMain from "./components/visitor/pages/main/VMain";
import Vnews from "./components/visitor/pages/news/VNews";
import VVacancies from "./components/visitor/pages/vacancies/VVacancies";
import VServices from "./components/visitor/pages/services/VServices";
import VCoPacking from "./components/visitor/pages/co-packing/VCoPacking";
import VAboutCompany from "./components/visitor/pages/about-company/VAboutCompany";
import VAboutWater from "./components/visitor/pages/about-water/VAboutWater";
import VProduction from "./components/visitor/pages/production/VProduction";
import VCertificates from "./components/visitor/pages/certificates/VCertificates";
import VPaymentRoboKassaSuccess from "./components/visitor/pages/payment/VPaymentRoboKassaSuccess";
import VPaymentRoboKassaFail from "./components/visitor/pages/payment/VPaymentRoboKassaFail";

// CUSTOMER ///////////////////
// DASHBOARD
import CustomerWelcome from "./components/customer/CustomerWelcome";
// ORDERS
import OrdersList from "./features/orders/OrdersList";
import NewOrder from "./features/orders/NewOrder";
import EditOrder from "./features/orders/EditOrder";

// ADMIN ///////////////////
import ALayout from "./components/admin/ALayout"
import { ROLES } from "./config/roles";
import AReport from "./components/admin/Report/AReport";

// users
import ALogin from "./components/admin/Login/ALogin";
import UsersList from "./features/users/UsersList";
import NewUserForm from "./features/users/NewUserForm";
import EditUser from "./features/users/EditUser";
import ADashboard from "./components/admin/Dashboard/ADashboard"
import PersistLogin from "./features/auth/PersistLogin"
import RequireAuth from "./features/auth/RequireAuth"
// MODAL
import { Suspense, useEffect, useState } from "react";
import { ModalContainer } from "react-modal-global";
import Modal_1 from "./components/visitor/common/Modals/Modal_1";
import Modal_2 from "./components/visitor/common/Modals/Modal_2";
import Modal_3 from "./components/visitor/common/Modals/Modal_3";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { resetCartToInitial } from "./features/cart/cartSlice";
import { useCheckWhatsAppAndGetIfNewMutation } from "./features/details/detailsApiSlice";
// Details
import { setWhatsAppIfNew, setAppVersion } from "./features/details/detailsSlice";
import PageNotFound404 from "./components/visitor/common/PageNotFound404";
import Preloader from "./components/other/Loaders/Preloader";


function App() {
    const dispatch = useDispatch();
    const localCartVersion = useSelector(state => state.cart.version);
    const details = useSelector(state => state.details.whatsapp);
    const savedWhatsApp = details.whatsapp ? details.whatsapp : "";
    const appVersion = useSelector(state => state.details.appVersion);

    const [checkWhatsAppAndGetIfNew, {
        data,
        isLoading, 
        isSuccess, 
        isUninitialized,
        isError, 
        error
    }] = useCheckWhatsAppAndGetIfNewMutation()
    
    // Reset Cart
    useEffect( () => { 
        // RESET CART REDUX STORE
        dispatch(resetCartToInitial(localCartVersion))
        
        // Details
        checkWhatsAppAndGetIfNew({currentWhatsapp: savedWhatsApp})

        // force reload window
        const handleVisibilityChange = () => {
            // console.log("Visibility changed");
            if (document.visibilityState === "visible") {
              checkWhatsAppAndGetIfNew({currentWhatsapp: savedWhatsApp})
              // console.log("APP resumed");
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [])


    // WhatsApp
    useEffect(() => {
      if (isSuccess && data) {
          // details
          if(data.appVersion !== appVersion){
              dispatch(setAppVersion(data.appVersion))
              window.location.reload();
          }
          // whatsapp
          dispatch(setWhatsAppIfNew(data.whatsapp))
          // showed preloader once
          if(!showedOnce){
            setShowedOnce(true)
          }
      }
    }, [isSuccess, data])

    const [minimumLoadTimePassed, setMinimumLoadTimePassed] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setMinimumLoadTimePassed(true);
        }, 1500); // Set minimum display time for preloader

        return () => clearTimeout(timer);
    }, []);

    const [showedOnce, setShowedOnce] = useState(false)
    if(isLoading || !minimumLoadTimePassed){
      if(!showedOnce || !minimumLoadTimePassed){
        return <Preloader />
      }
    }
    // 
    return (
      <>
            <Routes>
              <Route path="/" element={<MainLayout technicalMaintenance={data?.technicalMaintenance} />}>
                <Route element={<VLayout />} >
                    <Route index element={<VMain/>} />
                    <Route path="news" element={<Vnews />} />
                    <Route path="vacancies" element={<VVacancies/>} />
                    <Route path="services" element={<VServices/>} />
                    <Route path="co-packing" element={<VCoPacking/>} />
                    <Route path="about-company" element={<VAboutCompany/>} />
                    <Route path="about-water" element={<VAboutWater/>} />
                    <Route path="production" element={<VProduction/>} />
                    <Route path="certificates" element={<VCertificates/>} />
                    <Route path="payment/success" element={<VPaymentRoboKassaSuccess />} />
                    <Route path="payment/fail" element={<VPaymentRoboKassaFail />} />
                    <Route path="*" element={<PageNotFound404 />} />
                </Route>
              

                {/* customers */}
                <Route path="dashboard" element={<DashLayout/>} >
                    {/* DASHBOARD */}
                    <Route index element={<CustomerWelcome />} />
                    {/* ORDERS */}
                    <Route path="orders">
                      <Route index element={<OrdersList />} />
                      <Route path=":id" element={<EditOrder />} />
                      <Route path="new" element={<NewOrder />} />
                    </Route>
                </Route>

                {/* admin */}
                <Route path="admin">
                    {/* DASHBOARD */}
                    <Route element={<VLayout />}>
                        <Route index element={<ALogin />} />
                    </Route>
                    {/* Protected Routes */}
                    <Route element={<PersistLogin ifUnauthorisedRedirectTo='/admin' />}>
                        <Route element={<ALayout />} >
                            <Route element={<RequireAuth allowedRoles={[ ROLES.Admin, ROLES.SuperAdmin, ROLES.Owner]} ifNotAllowedNavigateTo='/admin' />}>
                                <Route path="dashboard" element={<ADashboard/>} />
                                <Route path="report" element={<AReport />} />
                            </Route>
                        </Route>
                    </Route>


                    {/* users */}
                    <Route path="users">
                      <Route index element={<UsersList />} />
                      <Route path=":id" element={<EditUser />} />
                      <Route path="new" element={<NewUserForm />} />
                    </Route>
                </Route>
                
              </Route>
            </Routes>
        <Suspense>
            <ModalContainer controller={Modal_1} />
            <ModalContainer controller={Modal_2} />
            <ModalContainer controller={Modal_3} />
        </Suspense>
      </>
    
  );
}

export default App;
