import styles from './CartItem.module.css'
import { useDispatch } from 'react-redux'
// functions
import { formatPrice } from '../../../../../../customHooks/functions';
// Redux
import { increaseQuantity, decreaseQuantity } from '../../../../../../features/cart/cartSlice';

const CartItem = ({item, sub, isForCart}) => {
    const dispatch = useDispatch()

    const increaseItem = (item) => { dispatch(increaseQuantity(item)) }
    const decreaseItem = (item) => { dispatch(decreaseQuantity(item)) }

    return (
            <>
                {/* CART LEFT */}
                <div className={sub ? styles.cart_img_title_code_block_sub : styles.cart_img_title_code_block}>
                    <div className={styles.cart__left}>
                        <img src={`/assets/img/catalog/${item.image_url}?v=1.01`} alt="item pic"></img>
                    </div>

                    {/* CART RIGHT */}
                    <div className={styles.cart__right}>
                        <p className={styles.cart__item_name}>{item.name}</p>
                        <p className={styles.cart__item_code}>Артикул:
                                    {
                                        isForCart
                                            ?   " " + item.item_code
                                            :   " " + item.sku
                                    }
                        </p>
                        {/* buttons */}
                        <div className={styles.cart__details}>
                            <div className={styles.cart__btn_block}>
                                {
                                    isForCart
                                        ? <button onClick={() => decreaseItem(item)} className={styles.cart__btn_quantity}>-</button>
                                        : null
                                }
                                
                                <span className={styles.cart_quantity_label}>{item.quantity} {item.details.type}</span>
                                {
                                    isForCart
                                        ? <button onClick={() => increaseItem(item)} className={styles.cart__btn_quantity}>+</button>
                                        : null
                                }
                                
                            
                            </div>
                            <p className={styles.cart__item_price}>
                                    {
                                        isForCart
                                            ?   formatPrice(item.details.price * item.quantity, ' ')
                                            :   formatPrice(item.subtotal, ' ')
                                    }
                                    <span> тг</span>
                            </p>
                        </div>
                    </div>
                </div>
            </>       
    )
}

export default CartItem