import styles from './Vlayout.module.css'

import { Link, Outlet } from "react-router-dom"
import VNav from "./common/VNav"
import VFooter from "./common/VFooter"
// Helmet
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';


const VLayout = () => {
    const isDefaultThemeColor = useSelector(state => state.helmet.theme_color.isDefault);
    const defaultThemeColor = useSelector(state => state.helmet.theme_color.defaultColor);
    const newThemeColor = useSelector(state => state.helmet.theme_color.newColor);

    const content = (
        <>
            {isDefaultThemeColor
                ?   <Helmet>
                    <meta name="theme-color" content={defaultThemeColor} />
                    </Helmet>
                : <Helmet>
                        <meta name="theme-color" content={newThemeColor} />
                    </Helmet>
            }
        
            <div className={styles.v_layout}>
                    <VNav />
                    <Outlet />
                    <VFooter />
                    <div className={styles.allRightsDevelopedBy}>
                        <p className={styles.allRights + ' mb-5'}>Все права защищены 1997-{new Date().getFullYear()}</p>
                        <p className={styles.developedBy}>Разработано <Link to='https://shakeshev.com'>www.shakeshev.com</Link></p>
                    </div>
            </div>
        </>
    )
    return content
}

export default VLayout