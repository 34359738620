import styles from './VPayment.module.css'

import { useSearchParams } from 'react-router-dom'
import { useCheckOrderGetStatusMutation } from '../../../../features/cart/cartApiSlice'
import { useEffect, memo } from 'react'

import { formatPrice } from '../../../../customHooks/functions'
import CartWithItems from '../../common/Modals/Catalog/CartComponents/CartWithItems'
import CartTotal from '../../common/Modals/Catalog/CartComponents/CartTotal'


const VPayment = (type) => {

  let [searchParams, setSearchParams] = useSearchParams()
  const InvId = searchParams.get('InvId');
  const OutSum = searchParams.get('OutSum');
  const SignatureValue = searchParams.get('SignatureValue');
 
  const [checkOrderGetStatus, {
    data,
    isLoading, 
    isSuccess, 
    isError, 
    error

}] = useCheckOrderGetStatusMutation()

  useEffect( () => {
    checkOrderGetStatus({
      InvId: InvId,
      OutSum: OutSum,
      SignatureValue: SignatureValue
    })
  }, [checkOrderGetStatus, InvId, OutSum, SignatureValue])
  
  // date format function
  const formatDate = (date) => {
      const givenDate = new Date(date);
      const months = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
      const month = months[givenDate.getMonth()];
      const day = String(givenDate.getDate()).padStart(2, '0');
      const year = givenDate.getFullYear();
      
      return `${day} ${month} ${year}`;
  };
  const formatTime = (date) => {
      const givenDate = new Date(date);
     
      const hours = String(givenDate.getHours()).padStart(2, '0');
      const minutes = String(givenDate.getMinutes()).padStart(2, '0');
      
      return `${hours}:${minutes}`;
  };
  // end of date format function

  var content
  if(isLoading){
    content = 'loading'
  }
  if(isError){
    content = "Ошибка"
  }
  if(isSuccess && data){
    content = <>
                  <div className={styles.mainContainer}>

                      <h3 className={styles.header}>Информация о заказе</h3>
                      
                      <div className={styles.rowContainer}>
                            {/* DETAILS */}
                            <div className={styles.orderDetailsContainer}>
                                    <div className={styles.block}>
                                        <div className={styles.row}>
                                            <p className={`${styles.text_left} ${styles.text_default}`}>Статус оплаты</p>
                                            
                                            { data.statusOrder === 'pending' ? <p className={`${styles.text_right} ${styles.text_pending}`}>Ожидает оплату</p> : '' }
                                            { data.statusOrder === 'completed' ? <p className={`${styles.text_right} ${styles.text_completed}`}>Оплачено</p> : '' }
                                                
                                        </div>
                                        <div className={styles.row}>
                                            <p className={`${styles.text_left} ${styles.text_default}`}>Доставка</p>
                                            <p className={`${styles.text_right} ${styles.text_delivery}`}>{formatDate(data.order.date_delivery)}</p>
                                        </div>

                                        {/* ORDER NUMBER */}
                                        <div className={styles.orderNumberBlock}>
                                              <p className={styles.orderNumberTitle}>{"1" + String(data.number).padStart(5, '0')}</p>
                                              
                                                { data.statusOrder === 'pending' ? <p className={styles.orderStatusPending}>Ожидает оплату</p> : '' }
                                                { data.statusOrder === 'completed' ? <p className={styles.orderStatusCompleted}>Оплачено</p> : '' }
                                              
                                        </div>

                                        {/*  */}
                                        <div className={styles.row}>
                                            <p className={`${styles.text_left} ${styles.text_default}`}>Тип оплаты</p>
                                            <p className={`${styles.text_right} ${styles.text_default}`}>{data.order.payment_type}</p>
                                        </div>
                                        <div className={styles.row}>
                                            <p className={`${styles.text_left} ${styles.text_default}`}>Создано</p>
                                            <p className={`${styles.text_right} ${styles.text_default}`}>{`${formatDate(data.date_created)} ${formatTime(data.date_created)}`}</p>
                                        </div>
                                        <div className={styles.row}>
                                            <p className={`${styles.text_left} ${styles.text_default}`}>Оплачено</p>
                                            <p className={`${styles.text_right} ${styles.text_default}`}>{`${formatDate(data.date_completed)} ${formatTime(data.date_completed)}`}</p>
                                        </div>
                                        <div className={styles.row}>
                                            <p className={`${styles.text_left} ${styles.text_default}`}>Итого</p>
                                            <p className={`${styles.text_right} ${styles.text_default}`}>{formatPrice(data.total, ' ')} ₸</p>
                                        </div>
                                    </div>
                                    
                                    {/* Contacts */}
                                    <div className={styles.block}>
                                      <div className={styles.inner_block}>
                                            <div className={styles.row}>
                                                <p className={`${styles.contact_left} ${styles.contact_label}`}>Имя</p>
                                                <p className={`${styles.contact_right} ${styles.contact_text}`}>{data.billing.first_name + ' ' + data.billing.last_name}</p>
                                            </div>
                                            <div className={styles.row}>
                                                <p className={`${styles.contact_left} ${styles.contact_label}`}>Телефон</p>
                                                <p className={`${styles.contact_right} ${styles.contact_text}`}>{data.billing.phone}</p>
                                            </div>
                                      </div>
                                        
                                    </div>

                                    {/* Address */}
                                    <div className={styles.block}>
                                        <div className={styles.inner_block}>
                                              <div className={styles.row_address}>
                                                  <p className={`${styles.contact_left} ${styles.address_label}`}>Адрес</p>
                                                  <p className={`${styles.contact_right} ${styles.address_text}`}>{data.billing.address_1}</p>
                                              </div>
                                              <div className={styles.row_address}>
                                                  <p className={`${styles.contact_left} ${styles.address_label}`}>Комментарий</p>
                                                  <p className={`${styles.contact_right} ${styles.address_text}`}>{data.order.customer_note}</p>
                                              </div>
                                        </div>
                                    </div>
                            </div>

                            {/* CART */}
                            <div className={styles.orderCartContainer}>
                                  <div className={styles.block}>
                                      <div className={styles.inner_block}>
                                            <div className={styles.cart_title}>
                                                <h5>Товары</h5>
                                            </div>
                                            <CartWithItems itemsInShoppingCart={data.line_items} isForCart={false} />
                                            <CartTotal totalPriceShoppingCart={data.total}/>
                                      </div>
                                  </div>
                                  
                            </div>
                      </div>


                  </div>
              </>
  }
  return content
}
export default memo(VPayment)