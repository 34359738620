import styles from './VFooter.module.css'
import Logo from '../assets/img/logo/logo.svg'
import menuItem from '../assets/img/icons/menuItem.png'

import {ReactComponent as IconPhone} from '../assets/img/icons/phone.svg'
import {ReactComponent as IconEmail} from '../assets/img/icons/email.svg'

import logoInstagram from '../assets/img/icons/companies/instagram.svg'
import logoVkontakte from '../assets/img/icons/companies/vkontakte.svg'
import logoFacebook from '../assets/img/icons/companies/facebook.svg'

import logoTelegram from '../assets/img/icons/companies/telegram.svg'
import logoWhatsapp from '../assets/img/icons/companies/whatsapp.svg'

import logo2gis from '../assets/img/icons/companies/2gis.svg'
import logoYandex from '../assets/img/icons/companies/yandex.svg'
import logoGoogle from '../assets/img/icons/companies/google.svg'

import { Link, NavLink } from 'react-router-dom'
// Redux
import { useUpdateWhatsAppCountMutation } from '../../../features/details/detailsApiSlice'
import { changeStatusWhatsAppToExisting } from '../../../features/details/detailsSlice'

import { useDispatch, useSelector } from 'react-redux'



const VFooter = () => {
    const dispatch = useDispatch()

    const [updatekWhatsAppCount, {
        data,
        isLoading, 
        isSuccess, 
        isError, 
        error
    }] = useUpdateWhatsAppCountMutation()

    const savedWhatsApp = useSelector(state => state.details.whatsapp);
    const handleWhatsApp = () => {
        // send whatsapp
        if(savedWhatsApp.status === 'new'){
            dispatch(changeStatusWhatsAppToExisting())
            updatekWhatsAppCount({currentWhatsapp: savedWhatsApp.number})
        }
    }

    return (
      <footer className={'card__wrapper ' + styles.footer}>
        <div className={'card__content ' + styles.card__content}>

          <Link to="/" className={`selectDisable ${styles.logo_container}`}>
              <img className={"selectDisable " + styles.logo} src={Logo} alt="Company Logo" />
          </Link>

            <div className={styles.footer__container}>
                
                {/* Order */}
                <div className={`${styles.footer_column} ${styles.footer_order}`}>
                    <h4>Для заказа</h4>
                    <div className={styles.address__link_container}>
                        <Link className='disabled' to='' onClick={ e => e.preventDefault() }>
                            <img className={styles.logo__btn} src={logoTelegram} alt='logo instagram' />
                            Telegram Бот
                        </Link>
                        <Link to={"https://wa.me/" + savedWhatsApp.number} onClick={handleWhatsApp}>
                            <img className={styles.logo__btn} src={logoWhatsapp} alt='logo instagram' />
                            WhatsApp
                        </Link>
                    </div>
                </div>
                
                {/* Social */}
                <div className={`${styles.footer_column} ${styles.footer_social}`}>
                    <h4>Мы в соц сетях</h4>
                    <Link to='https://www.instagram.com/calipso.water'>
                        <img className={styles.logo__social} src={logoInstagram} alt='logo instagram' />
                        {/* <img className={styles.logo__social} src={logoVkontakte} alt='logo vkontakte' />
                        <img className={styles.logo__social} src={logoFacebook} alt='logo facebook' /> */}
                    </Link>
                </div>

                {/* Contacts */}
                <div className={`${styles.footer_column} ${styles.footer_contacts}`}>
                    <h4>Контакты</h4>
                    <address>
                        <Link to='tel:+77719292288'>
                            <div className={styles.svg_container}>
                                <IconPhone />
                            </div>
                            +7 (771) 929-22-88 (офис)
                        </Link>
                        <Link to='mailto:office@calipso.kz'>
                            <div className={styles.svg_container}>
                                <IconEmail />
                            </div>
                            office@calipso.kz (офис)
                        </Link>
                        <Link to='mailto:sales@calipso.kz'>
                            <div className={styles.svg_container}>
                                <IconEmail />
                            </div>
                            sales@calipso.kz (для заказа)
                        </Link>
                    </address>
                </div>

                {/*Schedule  */}
                <div className={`${styles.footer_column} ${styles.footer_schedule}`}>
                    <h4>График работы</h4>
                    <p className={styles.middle__workingHours}>
                        <span className={styles.day_of_the_week_label}>пн-сб:</span> <span className={styles.day_of_the_week_text}>9:00 - 18:00</span> 
                    </p>
                    <p className={styles.middle__workingHours}>
                        <span className={styles.day_of_the_week_label}>вс:</span> <span className={styles.day_of_the_week_text}>выходные дни</span>
                    </p>
                </div>

                {/* Address */}
                <div className={`${styles.footer_column} ${styles.footer_address}`}>
                    <h4>Наш адрес</h4>
                    <address>
                    <p>050039, г. Алматы, ул. Наманганская, 43</p>
                    </address>
                    <div className={styles.address__link_container}>
                        <Link to='https://2gis.kz/almaty/geo/9429940000796982' target='_blank'>
                                <img className={`${styles.logo__btn} ${styles.logo__maps}`} src={logo2gis} alt='logo instagram' />
                                2ГИС
                        </Link>
                        <Link to='https://yandex.ru/maps/?whatshere%5Bzoom%5D=18&whatshere%5Bpoint%5D=76.984536,43.344389' target='_blank'>
                                <img className={`${styles.logo__btn} ${styles.logo__maps}`} src={logoYandex} alt='logo instagram' />
                                Яндекс
                        </Link>
                        <Link to='https://maps.app.goo.gl/xs79mVqP8DAv7Wrr8?g_st=iw' target='_blank'>
                                <img className={`${styles.logo__btn} ${styles.logo__maps}`} src={logoGoogle} alt='logo instagram' />
                                Google
                        </Link>
                    </div>
                </div>
                
                

                 
            
            </div>

            <div className={styles.footer_docs_block}>
                <Link className={styles.policy_link} to='./assets/files/contracts/policy.docx' target="_blank" download>Политика конфиденциальности</Link>
                <Link className={styles.policy_link} to='./assets/files/contracts/oferta.docx'target="_blank" download>Договор оферты</Link>
            </div>
          

        </div>
      </footer>
  )
}

export default VFooter