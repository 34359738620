import styles from './LoaderInline.module.css'

const LoaderInline = ({sizeInPx, borderWidth}) => {
  return (
            <div 
                style={{
                    borderWidth: borderWidth,
                    width: sizeInPx,
                    height: sizeInPx
                }}
                className={styles.loader_spinner}>    
            </div>
  )
}

export default LoaderInline