import { ModalController } from 'react-modal-global';

import VModalCatalog from './Catalog/VModalCatalog';
// Contacts
import VModalContacts from './Contacts/VModalContacts';
// Service Page
import VServicesModalDetails from '../../pages/services/VServicesModalDetails';
import VServicesModalOrder from '../../pages/services/VServicesModalOrder';
// Login
import VModalLogin from './Login/VModalLogin';
// News Page
import VNewsModal from '../../pages/news/VNewsModal';
// Vacancies
import VVacanciesModalDetails from '../../pages/vacancies/VVacanciesModalDetails';
import VVacanciesModalApply from '../../pages/vacancies/VVacanciesModalApply';


const Modal_1 = new ModalController({
  defaultParams: {},
  components: {
                VModalContacts,
                VModalCatalog,
                VServicesModalDetails,
                VServicesModalOrder,
                VModalLogin,
                VNewsModal,
                VVacanciesModalDetails,
                VVacanciesModalApply
              },
});

export default Modal_1;
