import styles from './PartOrderDetails.module.css'
// react hook form
import { useForm } from "react-hook-form"

import { useMedia } from '../../../../../customHooks/useMedia'

// Parts
import PartAddressContacts from './OrderDetails/PartAddressContacts';
import PartDeliveryDate from './OrderDetails/PartDeliveryDate/PartDeliveryDate';
import PartComment from './OrderDetails/PartComment';
import PartPaymentMethod from './OrderDetails/PartPaymentMethod';
import PayButtonToServer from './PayButtonToServer';
import { useSelector } from 'react-redux';

const PartOrderDetails = () => {
    // react hook form
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    const onSubmit = (data) => console.log(data)
    const isLarge850 = useMedia('(min-width: 850px)');
    const itemsInShoppingCart = useSelector(state => state.cart.cart);

    return (
        <section className={styles.section_container}>
            <div className={styles.inner_container}>
                <h3 className={styles.title_page}>Оформление заказа</h3>
                
                <form>
                    
                    <PartAddressContacts />
                    <PartComment />
                    <PartDeliveryDate />
                    <div id="root-portal"></div>
                    <PartPaymentMethod />
                    {isLarge850 || !itemsInShoppingCart.length
                        ? null
                        : <PayButtonToServer />
                    }
                    {/* <button className={`${styles.btn_add}`}>
                        <IconPlus />
                        Добавить адрес
                    </button> */}       
                        
                </form>
            </div>
        </section>
    )
}



export default PartOrderDetails