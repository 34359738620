import styles from './VMainPartners.module.css'
import VMainTitle from "../VMainTitle"

// LOGOS
import LogoBeeline from '../../../assets/img/logo/partners/beeline.png'
import LogoDanone from '../../../assets/img/logo/partners/danone.png'
import LogoEurasian from '../../../assets/img/logo/partners/eurasian.png'
import LogoForte from '../../../assets/img/logo/partners/forte.png'
import LogoDhl from '../../../assets/img/logo/partners/dhl.png'
import LogoKbtu from '../../../assets/img/logo/partners/kbtu.png'
import LogoSokol from '../../../assets/img/logo/partners/sokol.png'

const Box = ({ title }) => {
    return(
                <div className={"card__wrapper " + styles.card__wrapper}>
                    <div className={"card__content " + styles.card}>
                        <h3>{title}</h3>
                        <button>Подробнее</button>
                        <button>В корзину</button>
                    </div>
                </div>
    )
}


const VMainPartners = () => {
  return (
    <>
        <section className={styles.partners}>
        <VMainTitle title='Компании которые доверяют нам' subtitle='Завод “Calipso” на рынке с 1997, и за это время заслужило доверие компаний:'/>
            <div className={styles.partners_wrapper}>

                

            <div className={styles.slider}>
                <div className={styles.slide__track}>
                    <div className={styles.slide}><img src={LogoBeeline} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoDanone} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoEurasian} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoForte} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoDhl} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoKbtu} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoSokol} alt="Bottle" /></div>

                    <div className={styles.slide}><img src={LogoBeeline} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoDanone} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoEurasian} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoForte} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoDhl} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoKbtu} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoSokol} alt="Bottle" /></div>


                </div>
            </div>

            <div className={styles.slider}>
                <div className={`${styles.slide__track} ${styles.reverse}`}>
                <div className={styles.slide}><img src={LogoBeeline} alt="Bottle" /></div>

                    <div className={styles.slide}><img src={LogoBeeline} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoDanone} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoEurasian} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoForte} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoDhl} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoKbtu} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoSokol} alt="Bottle" /></div>

                    <div className={styles.slide}><img src={LogoBeeline} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoDanone} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoEurasian} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoForte} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoDhl} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoKbtu} alt="Bottle" /></div>
                    <div className={styles.slide}><img src={LogoSokol} alt="Bottle" /></div>
                    
                </div>
            </div>

             


                
            </div>
        </section>
    </>
  )
}

export default VMainPartners