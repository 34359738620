import styles from './VModal_3Rules18_9.module.css'
import buttons from '../../../../common/Buttons.module.css'

import PopupAppearLayout from '../../Layouts/PopupAppearLayout'
import { useModalWindow } from "react-modal-global";
import useScrollBlock from '../../../../../../customHooks/useScrollBlock';
// Images
import image_rules from "../../../../assets/img/by_page/rules_18_9/rules.png"

const VModal_3Rules18_9 = () => {
  const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();

    const closeModal = () => {
      // setTimeout(function(){allowScroll()}, 150);
      // defaultThemeColor()
      allowScroll()
      modal.close()
    } 

    return (
      <PopupAppearLayout classNameLayout={styles.popup__layout}>
        
            <h2 className={styles.title}>Условия обмена/возврата тары</h2>
            <p className={styles.main_text}>На дне бутылей есть обозначения, указывающие, какая компания произвела бутыль, в каком году и из какого пластика. От других поставщиков принимаем тару только от производителя “Aqua Well”</p>
            <p>Состояние тары:</p>
            <ul className={styles.list}>
              <li>Без мусора</li>
              <li>Без физический повреждений и деформаций</li>
              <li>Без трещин, проколов, порезов, вмятин, следов потертостей, без помутнения или изменения цвета</li>
              <li>Без следов химического воздействия</li>
              <li>Без пятен краски, технического масла</li>
              <li>Без признаков застоя воды, бактериального и грибкового роста, без образования зеленого налета на стенках бутыли</li>
              <li>Без слизи бурого и черного цвета</li>
              <li>без плесени</li>
              <li>Без запахов</li>
              <li>Без гравироквки, логотипа других производителей на пластике боковой стенки бубытлки</li>
            </ul>
            <div className={styles.image_container}>
                <img className={styles.image_rule} src={image_rules} alt='bottle bottom' />
            </div>
            <div className={styles.btn_container}>
                <button onClick={closeModal} className={buttons.btn_solid_blue}>закрыть</button>
            </div>
          

      </PopupAppearLayout>

    )
}

export default VModal_3Rules18_9