import styles from './VModal_2Common.module.css'
import { v4 as uuidv4 } from 'uuid';

// Icons
import { ReactComponent as IconBin } from "../../../../assets/img/icons/bin.svg"
// Form
import { useForm } from "react-hook-form"

// Modal
import { useModalWindow } from "react-modal-global";
import useScrollBlock from '../../../../../../customHooks/useScrollBlock';
import PopupAppearLayout from '../../Layouts/PopupAppearLayout';
// Helmet
import { setDefaultThemeColor } from '../../../../../../features/helmet/helmetSlice';
import { useDispatch } from 'react-redux';
// Redux
import { addEditContact } from '../../../../../../features/cart/cartSlice';
import { deleteContact } from '../../../../../../features/cart/cartSlice';

const VModal_2ContactsAddEdit = ({editContact}) => {
    const modal = useModalWindow();
    const [blockScroll, allowScroll] = useScrollBlock();
    
    const dispatch = useDispatch()
    const defaultThemeColor = () => dispatch(setDefaultThemeColor())

    const closeModal = () => {
        // setTimeout(function(){allowScroll()}, 150);
        defaultThemeColor()
        allowScroll()
        modal.close()
    }   

    const { register,
            formState: { errors },
            handleSubmit, 
    } = useForm()
    const onSubmit = (data) => {
        const output = {
            ...data,
            uuid: editContact && editContact.uuid ? editContact.uuid : uuidv4(),
        }
        dispatch(addEditContact(output))
        closeModal()
    }

    const deleteSelectedAddress = (data) => {
        dispatch(deleteContact(data))
        closeModal()
    }

    return (
        <PopupAppearLayout classNameLayout={styles.popup__layout}>
                <div className={styles.header_container}>
                    <div className={styles.header_title}>
                        <p>{editContact ? "Редактирование" : "Новый адрес"}</p>
                    </div>
                    
                    <button
                        className={styles.popup_layout__close}
                        type="button"
                        onClick={closeModal}
                        >
                        &#9587;
                    </button>
                </div>

                                
                {/* SELECT FORM */}
                {
                    editContact
                        ?   <div onClick={ () => deleteSelectedAddress(editContact)} className={styles.deleteBlock}>
                                <IconBin />
                                <p>Удалить контакт</p>
                            </div>
                        :   null
                }
                
                
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    

                    <label className={styles.addCityLabel  + (errors.first_name ? " " + styles.error : "")} >Имя*:</label>
                    <input 
                        defaultValue={editContact && editContact.first_name.trim() !== "" ? editContact.first_name : ''}
                        className={styles.addStreet + (errors.first_name ? ' ' + styles.error : "")} 
                        {...register("first_name", { required: true })} 
                    />

                    <label className={styles.addCityLabel} >Фамилия:</label>
                    <input 
                        defaultValue={editContact && editContact.last_name.trim() !== "" ? editContact.last_name : ''} 
                        className={styles.addStreet} 
                        {...register("last_name")} 
                    />

                    <label className={styles.addCityLabel  + (errors.mobile ? " " + styles.error : "")} >Мобильный*:</label>
                    <input 
                        defaultValue={editContact && editContact.mobile.trim() !== "" ? editContact.mobile : ''} 
                        type='tel'
                        className={styles.addStreet + (errors.mobile ? ' ' + styles.error : "")} 
                        {...register("mobile", { required: true })} 
                    />

                    {/* BUTTONS */}
                    <div className={styles.btn_block}>
                        <button onClick={closeModal} className={styles.item_btn_blue_border} >
                            Отмена
                        </button>
                        <button type='submit' className={styles.item_btn_blue_filled} >
                            Сохранить
                        </button>
                    </div>
                    
                </form>
                    
                
                
                    

                
        </PopupAppearLayout>
    )
}

export default VModal_2ContactsAddEdit