import styles from './VAboutCompany.module.css'
import VTitle from '../../common/VTitle'

import pic_1 from "../../assets/img/by_page/about_company/pic_1.png"

const VAboutCompany = () => {
  return (
    <>
      <VTitle title="О компании" subtitle='Расскажем о нас' />
      <div className={`${styles.wrapper} ${styles.left}`}>
              <div className={"card__wrapper " + styles.card__wrapper}>
                  <div className={"card__content " + styles.aboutCard}>
                      <h3 className="card__title">История</h3>
                      <div className={styles.content_block}>
                          <img src={pic_1} alt='Quality' />
                          <p>ТОО «Комета» является первым казахстанским производителем экологически чистой озонированной питьевой воды. Изучив рынок питьевой воды и спрос на нее в республике, наша компания начала свою деятельность на казахстанском рынке и наладила производство экологически чистой питьевой воды «Calipso» с апреля 1997 года. </p>
                          
                          <h3 className="card__title">Наш продукт</h3>
                          <p>Вода «Calipso» — вода натурального происхождения из артезианской скважины №5036, глубиной 500 м проходит цикл многоступенчатой очистки (цеолитовый фильтр, полипропиленовый фильтр, система обратного осмоса, озонирование) с применением высокоэффективной технологии и оборудования, что позволяет получить воду высочайшей очистки с сохранением природных вкусовых качеств. Используемая технология позволяет решить проблему питьевой воды грамотно, надежно. В начале 2007 года построены новые производственные помещения, проведено полное техническое перевооружение.</p>
                      </div>
                  </div>
              </div>
        </div>
    </>
  )
}

export default VAboutCompany