import styles from './VMainAboutCompany.module.css'
import VMainTitle from '../VMainTitle'

// Images
import about_1 from '../../../assets/img/by_page/main/about/about_1.png'
import about_2 from '../../../assets/img/by_page/main/about/about_2.png'

const VMainAboutCompany = () => {
  return (
    <>
        <section className={styles.about_company}>
            <VMainTitle title='О компании' subtitle='Расскажем о нас' url='about-company' />
            
            <div className={`card__wrapper ${styles.card__wrapper}`}>
                <div className={styles.img_block}>
                    <img className={styles.about_img} src={about_1} alt='bottle in hands' />
                    <img className={styles.about_img} src={about_2} alt='bottle on rocks' />
                </div>
                <div className={"card__content " + styles.aboutCard}>
                    <p>ТОО «Комета» является первым казахстанским производителем экологически чистой озонированной питьевой воды. Изучив рынок питьевой воды и спрос на нее в республике, наша компания начала свою деятельность на казахстанском рынке и наладила производство экологически чистой питьевой воды «Calipso» с апреля 1997 года.</p>     
                </div>
            </div>
        </section>
    </>
  )
}

export default VMainAboutCompany