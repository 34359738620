import styles from './PartDeliveryDate.module.css'
import buttons from './../../../../Buttons.module.css'

import { useEffect } from 'react';

// Redux
import { useGetDatesExcludedQuery } from '../../../../../../../features/delivery/deliveryApiSlice';
// Components
import DateBlock from './DateBlock';
import LoaderInline from '../../../../../../other/Loaders/LoaderInline'
// Modal
import { useModalWindow } from 'react-modal-global';
// Images
import icon_error from './../../../../../assets/img/icons/error.svg'


const PartDeliveryDate = () => {
    const modal = useModalWindow();
    const { data, isLoading, isSuccess, isFetching, isError, error, refetch } = useGetDatesExcludedQuery()

    
    useEffect( () => {
        if(!modal.closed){
            refetch()
        }
    },[modal.closed])
    
    useEffect( () => { 
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
              refetch()
            }
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [])

    const handleRetry = (e) => {
        e.preventDefault()
        refetch()
    }
    let content
    if(isLoading || isFetching){
        content =   <div className={styles.loading_block}>
                        <LoaderInline sizeInPx="20px" borderWidth="4px"/>
                        <p className={styles.loading_text}>Загрузка...</p>
                    </div>
    }
    if(isError){
        content =   <div className={styles.loading_block_error}>
                        <img className={styles.loading_error} src={icon_error} alt='icon error' />
                        <p className={styles.loading_text}>Ошибка</p>
                        <button onClick={handleRetry} className={buttons.btn_solid_blue}>обновить</button>
                    </div>
    }
    if(isSuccess && data && !isFetching){
        content = <DateBlock arrayDeliveryData={data} />
    }

    

    return(
        <div className={"card__wrapper " + styles.card_wrapper}>
            <section className={styles.section_block}>
                <h4 className={styles.title_block}>Доставка</h4>
                {content}
            </section>
        </div>
    )
}

export default PartDeliveryDate